/** @format */

import JwtAuthService from "../apiConfig/ApiConfig";
// import LoginApiCAll from "../apiConfig/ApiConfig";
import {
  ADD_ALLCHECKLIST_ERROR,
  ADD_ALLCHECKLIST_REQUEST,
  ADD_ALLCHECKLIST_SUCCESS,
  ALLCHECKLIST_LIST_ERROR,
  ALLCHECKLIST_LIST_REQUEST,
  ALLCHECKLIST_LIST_SUCCESS,
  ALLFORM_CHECKLIST_ERROR,
  ALLFORM_CHECKLIST_REQUEST,
  ALLFORM_CHECKLIST_SUCCESS,
  CATEGORY_DATA_REQUEST,
  CATEGORY_DATA_SUCCESS,
  CATEGORY_DATA_ERROR,
  CATEGORY_LIST_ERROR,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CREATE_ALLFORM_ERROR,
  CREATE_ALLFORM_REQUEST,
  CREATE_ALLFORM_SUCCESS,
  DELETE_ALLFORM_ERROR,
  DELETE_ALLFORM_REQUEST,
  DELETE_ALLFORM_SUCCESS,
  DEPARTMENT_LIST_ERROR,
  DEPARTMENT_LIST_REQUEST,
  DEPARTMENT_LIST_SUCCESS,
  EDIT_ALLFORM_CHECKLIST_ERROR,
  EDIT_ALLFORM_CHECKLIST_REQUEST,
  EDIT_ALLFORM_CHECKLIST_SUCCESS,
  EDIT_ALL_FORM_ERROR,
  EDIT_ALL_FORM_REQUEST,
  EDIT_ALL_FORM_SUCCESS,
  OVERVIEW_LIST_ERROR,
  OVERVIEW_LIST_REQUEST,
  OVERVIEW_LIST_SUCCESS,
  RULES_ADDED_ERROR,
  RULES_ADDED_REQUEST,
  RULES_ADDED_SUCCESS,
  STORE_CHECKLIST_LIST_ERROR,
  STORE_CHECKLIST_LIST_REQUEST,
  STORE_CHECKLIST_LIST_SUCCESS,
  STORE_LIST_ERROR,
  STORE_LIST_REQUEST,
  STORE_LIST_SUCCESS,
  SUBMISSION_LIST_ERROR,
  SUBMISSION_LIST_REQUEST,
  SUBMISSION_LIST_SUCCESS,
  SUBMISSION_VIEW_ERROR,
  SUBMISSION_VIEW_REQUEST,
  SUBMISSION_VIEW_SUCCESS,
  USER_CHECKLIST_LIST_ERROR,
  USER_CHECKLIST_LIST_REQUEST,
  USER_CHECKLIST_LIST_SUCCESS,
  VIEW_ALLFORM_CHECKLIST_ERROR,
  VIEW_ALLFORM_CHECKLIST_REQUEST,
  VIEW_ALLFORM_CHECKLIST_SUCCESS,
  SUBMISSION_VIEW_CHART_REQUEST,
  SUBMISSION_VIEW_CHART_SUCCESS,
  SUBMISSION_VIEW_CHART_ERROR,
  OVERVIEW_CHART_REQUEST,
  OVERVIEW_CHART_SUCCESS,
  OVERVIEW_CHART_ERROR,
  UPDATE_ALLCHECKLIS_REQUEST,
  UPDATE_ALLCHECKLIS_SUCCESS,
  UPDATE_ALLCHECKLIS_ERROR,
  SWITCH_ALLCHECKLIST_REQUEST,
  SWITCH_ALLCHECKLIST_SUCCESS,
  SWITCH_ALLCHECKLIST_ERROR,
  SUBMISSION_SELECT_SEARCH_REQUEST,
  SUBMISSION_SELECT_SEARCH_SUCCESS,
  SUBMISSION_SELECT_SEARCH_ERROR,
  ALLCHECKLIST_ARCHIVED_REQUEST,
  ALLCHECKLIST_ARCHIVED_SUCCESS,
  ALLCHECKLIST_ARCHIVED_ERROR,
  FORM_RULES_UPDATE_ADDED_REQUEST,
  FORM_RULES_UPDATE_ADDED_SUCCESS,
  FORM_RULES_UPDATE_ADDED_ERROR,
  USER_ARCHIVED_REQUEST,
  USER_ARCHIVED_SUCCESS,
  USER_ARCHIVED_ERROR,
  FORMLIST_ARCHIVED_REQUEST,
  FORMLIST_ARCHIVED_SUCCESS,
  FORMLIST_ARCHIVED_ERROR,
  CHECKLIST_IMAGE_REQUEST,
  CHECKLIST_IMAGE_SUCCESS,
  CHECKLIST_IMAGE_ERROR,
  ADD_CHECKLIST_IMAGE_REQUEST,
  ADD_CHECKLIST_IMAGE_SUCCESS,
  ADD_CHECKLIST_IMAGE_ERROR,
  RATING_REQUEST,
  RATING_SUCCESS,
  RATING_ERROR,
  CREATE_IMAGELOG_REQUEST,
  CREATE_IMAGELOG_SUCCESS,
  CREATE_IMAGELOG_ERROR,
  C_R_LEFT_GRAPH_REQUEST,
  C_R_LEFT_GRAPH_SUCCESS,
  C_R_LEFT_GRAPH_ERROR,
  C_R_GRAPH_REQUEST,
  C_R_GRAPH_SUCCESS,
  C_R_GRAPH_ERROR,
  UPC_REQUEST,
  UPC_SUCCESS,
  UPC_ERROR,
  CSC_REQUEST,
  CSC_SUCCESS,
  CSC_ERROR,
  S_W_U_C_R_ERROR,
  S_W_U_C_R_SUCCESS,
  S_W_U_C_R_REQUEST,
  S_W_S_U_C_R_REQUEST,
  S_W_S_U_C_R_SUCCESS,
  S_W_S_U_C_R_ERROR,
  S_W_U_C_G_C_REQUEST,
  S_W_U_C_G_C_SUCCESS,
  S_W_U_C_G_C_ERROR,
  S_W_S_U_G_C_R_REQUEST,
  S_W_S_U_G_C_R_SUCCESS,
  S_W_S_U_G_C_R_ERROR,
  GET_STOREWISECHECKLISTREPORT_REQUEST,
  GET_STOREWISECHECKLISTREPORT_SUCCESS,
  GET_STOREWISECHECKLISTREPORT_ERROR,
  ALLACTIVECHECKLIST_LIST_REQUEST,
  ALLACTIVECHECKLIST_LIST_SUCCESS,
  ALLACTIVECHECKLIST_LIST_ERROR,
  GENERATE_AUDIT_REQUEST,
  GENERATE_AUDIT_SUCCESS,
  GENERATE_AUDIT_ERROR,
  UPLOAD_IMG_ADD_QUES_REQUEST,
  UPLOAD_IMG_ADD_QUES_SUCCESS,
  UPLOAD_IMG_ADD_QUES_ERROR,
  UPLOAD_IMG_EDIT_QUES_REQUEST,
  UPLOAD_IMG_EDIT_QUES_SUCCESS,
  UPLOAD_IMG_EDIT_QUES_ERROR,
  GENERATE_PDF_REQUEST,
  GENERATE_PDF_SUCCESS,
  GENERATE_PDF_ERROR,
  DELATE_ALLCHECKLIST_REQUEST,
  DELATE_ALLCHECKLIST_SUCCESS,
  DELATE_ALLCHECKLIST_ERROR,
  RECENT_STORE_LIST_REQUEST,
  RECENT_STORE_LIST_SUCCESS,
  RECENT_STORE_LIST_ERROR,
  ACTIVE_ALLCHECKLIST_REQUEST,
  ACTIVE_ALLCHECKLIST_SUCCESS,
  ACTIVE_ALLCHECKLIST_ERROR,
  PENDING_ALLCHECKLIST_REQUEST,
  PENDING_ALLCHECKLIST_SUCCESS,
  PENDING_ALLCHECKLIST_ERROR,
  UPCOMING_REQUEST,
  UPCOMING_SUCCESS,
  UPCOMING_ERROR,
  REMAINING_REQUEST,
  REMAINING_SUCCESS,
  REMAINING_ERROR,
  COMPLETED_REQUEST,
  MISSED_REQUEST,
  MISSED_SUCCESS,
  MISSED_ERROR,
  QUEUE_REQUEST,
  QUEUE_SUCCESS,
  QUEUE_ERROR,
  CHECKLIST_HOME_REQUEST,
  CHECKLIST_HOME_SUCCESS,
  CHECKLIST_HOME_ERROR,
  AUDITOR_CHECKLIST_SUBMISSION_REQUEST,
  AUDITOR_CHECKLIST_SUBMISSION_SUCCESS,
  AUDITOR_CHECKLIST_SUBMISSION_ERROR,
  UPLOAD_MEDIA_REQUEST,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_ERROR,
  CHECKLIST_D_APPROVEL_REQUEST,
  CHECKLIST_D_APPROVEL_SUCCESS,
  CHECKLIST_D_APPROVEL_ERROR,
  DISAPPROVED_FORM_REQUEST,
  DISAPPROVED_FORM_SUCCESS,
  DISAPPROVED_FORM_ERROR,
  SUBMISSION_VERSION_REQUEST,
  SUBMISSION_VERSION_SUCCESS,
  SUBMISSION_VERSION_ERROR,
  SIGNED_URL_REQUEST,
  SIGNED_URL_SUCCESS,
  SIGNED_URL_ERROR,
} from "../types/Types";
import { toast } from "react-toastify";

export const AllChecklistListRequest = () => ({
  type: ALLCHECKLIST_LIST_REQUEST,
});
export const AllChecklistListSuccess = (list) => ({
  type: ALLCHECKLIST_LIST_SUCCESS,
  payload: list,
});
export const AllChecklistListFailed = (error) => ({
  type: ALLCHECKLIST_LIST_ERROR,
  payload: error,
});

export const AllChecklistListApi = (data) => (dispatch) => {
  try {
    dispatch(AllChecklistListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(AllChecklistListSuccess(response));
      } else {
        dispatch(AllChecklistListFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AllChecklistListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const AllChecklistListArchivedRequest = () => ({
  type: ALLCHECKLIST_ARCHIVED_REQUEST,
});
export const AllChecklistListArchivedSuccess = (list) => ({
  type: ALLCHECKLIST_ARCHIVED_SUCCESS,
  payload: list,
});
export const AllChecklistListArchivedFailed = (error) => ({
  type: ALLCHECKLIST_ARCHIVED_ERROR,
  payload: error,
});

export const AllChecklistListArchivedApi = (data) => (dispatch) => {
  try {
    dispatch(AllChecklistListArchivedRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(AllChecklistListArchivedSuccess(response));
      } else {
        dispatch(AllChecklistListArchivedFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AllChecklistListArchivedFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const UsertListArchivedRequest = () => ({
  type: USER_ARCHIVED_REQUEST,
});
export const UserListArchivedSuccess = (list) => ({
  type: USER_ARCHIVED_SUCCESS,
  payload: list,
});
export const UserListArchivedFailed = (error) => ({
  type: USER_ARCHIVED_ERROR,
  payload: error,
});

export const UserListArchivedApi = (data) => (dispatch) => {
  try {
    dispatch(UsertListArchivedRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(UserListArchivedSuccess(response));
      } else {
        dispatch(UserListArchivedFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(UserListArchivedFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const AllFormListRequest = () => ({
  type: ALLFORM_CHECKLIST_REQUEST,
});
export const AllFormListSuccess = (list) => ({
  type: ALLFORM_CHECKLIST_SUCCESS,
  payload: list,
});
export const AllFormListFailed = (error) => ({
  type: ALLFORM_CHECKLIST_ERROR,
  payload: error,
});

export const AllFormListApi = (data) => (dispatch) => {
  try {
    dispatch(AllFormListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(AllFormListSuccess(response));
      } else {
        dispatch(AllFormListFailed(`${response?.data?.message}`));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AllFormListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const AllFormArchivedListRequest = () => ({
  type: FORMLIST_ARCHIVED_REQUEST,
});
export const AllFormArchivedListSuccess = (list) => ({
  type: FORMLIST_ARCHIVED_SUCCESS,
  payload: list,
});
export const AllFormArchivedListFailed = (error) => ({
  type: FORMLIST_ARCHIVED_ERROR,
  payload: error,
});

export const AllFormArchivedListApi = (data) => (dispatch) => {
  try {
    dispatch(AllFormArchivedListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(AllFormArchivedListSuccess(response));
      } else {
        dispatch(AllFormArchivedListFailed(`${response?.data?.message}`));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AllFormListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

// create image log
export const CreateImageLogRequest = () => ({
  type: CREATE_IMAGELOG_REQUEST,
});
export const CreateImageLogSuccess = (list) => ({
  type: CREATE_IMAGELOG_SUCCESS,
  payload: list,
});
export const CreateImageLogFailed = (error) => ({
  type: CREATE_IMAGELOG_ERROR,
  payload: error,
});

export const CreateImageLogApi = (data) => (dispatch) => {
  try {
    dispatch(CreateImageLogRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(CreateImageLogSuccess(response));
        toast.success(`comment updated`);
      } else {
        dispatch(CreateImageLogFailed(`${response?.data?.message}`));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(CreateImageLogFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const ChecklistImageRequest = () => ({
  type: CHECKLIST_IMAGE_REQUEST,
});
export const ChecklistImageListSuccess = (list) => ({
  type: CHECKLIST_IMAGE_SUCCESS,
  payload: list,
});
export const ChecklistImageListFailed = (error) => ({
  type: CHECKLIST_IMAGE_ERROR,
  payload: error,
});

export const ChecklistImageApi = (data) => (dispatch) => {
  try {
    dispatch(ChecklistImageRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(ChecklistImageListSuccess(response));
      } else {
        dispatch(ChecklistImageListFailed(`${response?.data?.message}`));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AllFormListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const AddChecklistImageRequest = () => ({
  type: ADD_CHECKLIST_IMAGE_REQUEST,
});
export const AddChecklistImageListSuccess = (list) => ({
  type: ADD_CHECKLIST_IMAGE_SUCCESS,
  payload: list,
});
export const AddChecklistImageListFailed = (error) => ({
  type: ADD_CHECKLIST_IMAGE_ERROR,
  payload: error,
});

export const AddChecklistImageAnswerApi =
  (data, imageCreateLogData) => (dispatch) => {
    try {
      dispatch(AddChecklistImageRequest(data));
      JwtAuthService.PostApiService(data).then((response) => {
        const data = response;

        if (response?.status == 200) {
          imageCreateLogData.body.editImageKey = data?.data?.data?.key;

          JwtAuthService.PostApiService(imageCreateLogData).then((res) => {
            toast.success(`${data?.data?.message}`);
            // dispatch(
            //   AddChecklistImageListSuccess(data.data.data[0])
            // );
          });
        } else {
          dispatch(AddChecklistImageListFailed(response?.response?.statusText));
          toast.error(`${response?.response?.data?.message}`);
        }
      });
    } catch (error) {
      dispatch(AddAssessmentImageFailed(error));
    }
  };

export const ViewAllFormListRequest = () => ({
  type: VIEW_ALLFORM_CHECKLIST_REQUEST,
});
export const ViewAllFormListSuccess = (list) => ({
  type: VIEW_ALLFORM_CHECKLIST_SUCCESS,
  payload: list,
});
export const ViewAllFormListFailed = (error) => ({
  type: VIEW_ALLFORM_CHECKLIST_ERROR,
  payload: error,
});

export const ViewAllFormListApi = (data) => (dispatch) => {
  try {
    dispatch(ViewAllFormListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(ViewAllFormListSuccess(response));
      } else {
        dispatch(ViewAllFormListFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ViewAllFormListFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const EditAllFormListRequest = () => ({
  type: EDIT_ALLFORM_CHECKLIST_REQUEST,
});
export const EditAllFormListSuccess = (list) => ({
  type: EDIT_ALLFORM_CHECKLIST_SUCCESS,
  payload: list,
});
export const EditAllFormListFailed = (error) => ({
  type: EDIT_ALLFORM_CHECKLIST_ERROR,
  payload: error,
});

export const EditAllFormListApi = (data) => (dispatch) => {
  try {
    dispatch(EditAllFormListRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(EditAllFormListSuccess(response));
      } else {
        dispatch(EditAllFormListFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(EditAllFormListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};
// Rating Action

export const RatingRequest = () => ({
  type: RATING_REQUEST,
});
export const RatingSuccess = (list) => ({
  type: RATING_SUCCESS,
  payload: list,
});
export const RatingFailed = (error) => ({
  type: RATING_ERROR,
  payload: error,
});

export const RatingtApi = (data, type) => (dispatch) => {
  try {
    dispatch(RatingRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(RatingSuccess(response));
        toast.success(`${type} Updated`);
      } else {
        dispatch(RatingFailed(`${response?.data?.message}`));
        toast.error(`${response.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(RatingFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const EditAllForm = (data) => ({
  type: EDIT_ALL_FORM_REQUEST,
  payload: data,
});
export const EditAllFormSuccess = (data) => ({
  type: EDIT_ALL_FORM_SUCCESS,
  payload: data,
});
export const EditAllFormFailed = (error) => ({
  type: EDIT_ALL_FORM_ERROR,
  payload: error,
});

export const EditAllFormActionHandler = (data) => {
  return (dispatch) => {
    try {
      dispatch(EditAllFormSuccess(data));
    } catch (err) {
      dispatch(EditAllFormFailed(err));
    }
  };
};

export const DeleteFormListRequest = () => ({
  type: DELETE_ALLFORM_REQUEST,
});
export const DeleteFormListSuccess = (list) => ({
  type: DELETE_ALLFORM_SUCCESS,
  payload: list,
});
export const DeleteFormListFailed = (error) => ({
  type: DELETE_ALLFORM_ERROR,
  payload: error,
});

export const DeleteFormListApi = (data) => (dispatch) => {
  try {
    dispatch(DeleteFormListRequest(data));
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(DeleteFormListSuccess(response));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(DeleteFormListFailed(response?.data?.message));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(DeleteFormListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const DeleteAllChecklistRequest = () => ({
  type: DELATE_ALLCHECKLIST_REQUEST,
});
export const DeleteAllChecklistSuccess = (list) => ({
  type: DELATE_ALLCHECKLIST_SUCCESS,
  payload: list,
});
export const DeleteAllChecklistFailed = (error) => ({
  type: DELATE_ALLCHECKLIST_ERROR,
  payload: error,
});

export const DeleteAllChecklistNewApi = (data, callBack) => (dispatch) => {
  try {
    dispatch(DeleteAllChecklistRequest(data));
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status === 200) {
        callBack();
        dispatch(DeleteAllChecklistSuccess(response));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(DeleteAllChecklistFailed(response?.data?.message));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(DeleteAllChecklistFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const CreateFormRequest = () => ({
  type: CREATE_ALLFORM_REQUEST,
});
export const CreateFormSuccess = (list) => ({
  type: CREATE_ALLFORM_SUCCESS,
  payload: list,
});
export const CreateFormFailed = (error) => {
  return {
    type: CREATE_ALLFORM_ERROR,
    payload: error,
  };
};

export const CreateFormApi = (data, navigate) => (dispatch) => {
  try {
    dispatch(CreateFormRequest());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(CreateFormSuccess(response));

        navigate(response?.status);
        toast.success("Created Successfully");
      } else {
        dispatch(CreateFormFailed(response?.message));
        toast.error(response?.response?.data?.message);
      }
    });
  } catch (error) {
    dispatch(CreateFormFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

// //  Submission list started
// export const SubmissionListRequest = () =>({
//     type: SUBMISSION_LIST_REQUEST
// })
// export const SubmissionListSuccess = (list) =>({
//     type: SUBMISSION_LIST_SUCCESS,
//     payload: list
// })
// export const SubmissionListFailed = (error) =>({
//     type: SUBMISSION_LIST_ERROR,
//     payload: error
// })

// export const SubmissionListApi = (data) => dispatch => {
//     try {
//         dispatch(SubmissionListRequest(data));
//        JwtAuthService.PostApiService(data).then(response =>{
//             if (response?.status === 200) {
//                 dispatch(SubmissionListSuccess(response));
//             }
//             else{
//                 dispatch(SubmissionListFailed(response?.data?.message));
//                 toast.error(`${response?.data?.message}`);
//             }
//         })
//       } catch (error) {
//         dispatch(SubmissionListFailed(error))
//         console.error('Something went Wrong',error);
//         toast.error(error);
//       }

//  };

export const StoreListRequest = () => ({
  type: STORE_LIST_REQUEST,
});
export const StoreListSuccess = (list) => ({
  type: STORE_LIST_SUCCESS,
  payload: list,
});
export const StoreListFailed = (error) => ({
  type: STORE_LIST_ERROR,
  payload: error,
});

export const StoreListApi = (data) => (dispatch) => {
  try {
    dispatch(StoreListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(StoreListSuccess(response));
      } else {
        dispatch(StoreListFailed(response?.data?.message));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(StoreListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const DepartmentListRequest = () => ({
  type: DEPARTMENT_LIST_REQUEST,
});
export const DepartmentListSuccess = (list) => ({
  type: DEPARTMENT_LIST_SUCCESS,
  payload: list,
});
export const DepartmentListFailed = (error) => ({
  type: DEPARTMENT_LIST_ERROR,
  payload: error,
});

export const DepartmentListApi = (data) => (dispatch) => {
  try {
    dispatch(DepartmentListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(DepartmentListSuccess(response));
      } else {
        dispatch(DepartmentListFailed(response?.data?.message));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(DepartmentListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const CategoryListRequest = () => ({
  type: CATEGORY_LIST_REQUEST,
});
export const CategoryListSuccess = (list) => ({
  type: CATEGORY_LIST_SUCCESS,
  payload: list,
});
export const CategoryListFailed = (error) => ({
  type: CATEGORY_LIST_ERROR,
  payload: error,
});

export const CategoryListApi = (data) => (dispatch) => {
  try {
    dispatch(CategoryListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(CategoryListSuccess(response));
      } else {
        dispatch(CategoryListFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(CategoryListFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

//  Submission list started
export const SubmissionListRequest = () => ({
  type: SUBMISSION_LIST_REQUEST,
});
export const SubmissionListSuccess = (list) => ({
  type: SUBMISSION_LIST_SUCCESS,
  payload: list,
});
export const SubmissionListFailed = (error) => ({
  type: SUBMISSION_LIST_ERROR,
  payload: error,
});

export const SubmissionListApi = (data) => (dispatch) => {
  try {
    dispatch(SubmissionListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(SubmissionListSuccess(response));
      } else {
        dispatch(SubmissionListFailed(response?.data?.message));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(SubmissionListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};
// Submission list ended

// Submission view started
export const SubmissionViewRequest = () => ({
  type: SUBMISSION_VIEW_REQUEST,
});
export const SubmissionViewSuccess = (list) => ({
  type: SUBMISSION_VIEW_SUCCESS,
  payload: list,
});
export const SubmissionViewFailed = (error) => ({
  type: SUBMISSION_VIEW_ERROR,
  payload: error,
});

export const SubmissionViewApi = (data) => (dispatch) => {
  try {
    dispatch(SubmissionViewRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(SubmissionViewSuccess(response));
      } else {
        dispatch(SubmissionListFailed(`${response?.data?.message}`));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(SubmissionListFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};
// Submission view ended y

export const disapprovedFormRequest = () => ({
  type: DISAPPROVED_FORM_REQUEST,
});
export const disapprovedFormSuccess = (list) => ({
  type: DISAPPROVED_FORM_SUCCESS,
  payload: list,
});
export const disapprovedFormFailed = (error) => ({
  type: DISAPPROVED_FORM_ERROR,
  payload: error,
});

export const disapprovedFormApi = (data) => (dispatch) => {
  try {
    dispatch(disapprovedFormRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(disapprovedFormSuccess(response));
      } else {
        dispatch(disapprovedFormFailed(`${response?.data?.message}`));
      }
    });
  } catch (error) {
    dispatch(disapprovedFormFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const GeneratePdfRequest = () => ({
  type: GENERATE_PDF_REQUEST,
});
export const GeneratePdfSuccess = (list) => ({
  type: GENERATE_PDF_SUCCESS,
  payload: list,
});
export const GeneratePdfFailed = (error) => ({
  type: GENERATE_PDF_ERROR,
  payload: error,
});

export const GeneratePdfApi = (data) => (dispatch) => {
  try {
    dispatch(GeneratePdfRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(GeneratePdfSuccess(response));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(GeneratePdfFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(GeneratePdfFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

// Submission view started
export const RulesAddedRequest = () => ({
  type: RULES_ADDED_REQUEST,
});
export const RulesAddedSuccess = (list) => ({
  type: RULES_ADDED_SUCCESS,
  payload: list,
});
export const RulesAddedFailed = (error) => ({
  type: RULES_ADDED_ERROR,
  payload: error,
});

export const FormRulesUpdateRequest = () => ({
  type: FORM_RULES_UPDATE_ADDED_REQUEST,
});
export const FormRulesUpdateSuccess = (list) => ({
  type: FORM_RULES_UPDATE_ADDED_SUCCESS,
  payload: list,
});
export const FormRulesUpdateFailed = (error) => ({
  type: FORM_RULES_UPDATE_ADDED_ERROR,
  payload: error,
});

export const addFormRulesUpdateData = (data) => {
  return (dispatch) => {
    dispatch(FormRulesUpdateRequest());
    if (data) {
      const addGroup = data;
      dispatch(FormRulesUpdateSuccess(addGroup));
    }
  };
};

export const RulesAddedApi = (data) => (dispatch) => {
  try {
    dispatch(RulesAddedRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(RulesAddedSuccess(response));
      } else {
        dispatch(RulesAddedFailed(`${response?.data?.message}`));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(RulesAddedFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const OverviewListRequest = () => ({
  type: OVERVIEW_LIST_REQUEST,
});
export const OverviewListSuccess = (list) => ({
  type: OVERVIEW_LIST_SUCCESS,
  payload: list,
});
export const OverviewListFailed = (error) => ({
  type: OVERVIEW_LIST_ERROR,
  payload: error,
});

export const OverviewListApi = (data) => (dispatch) => {
  try {
    dispatch(OverviewListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(OverviewListSuccess(response));
      } else {
        dispatch(OverviewListFailed(`${response?.data?.message}`));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(OverviewListFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const recentStoreListRequest = () => ({
  type: RECENT_STORE_LIST_REQUEST,
});
export const recentStoreListSuccess = (list) => ({
  type: RECENT_STORE_LIST_SUCCESS,
  payload: list,
});
export const recentStoreListFailed = (error) => ({
  type: RECENT_STORE_LIST_ERROR,
  payload: error,
});

export const recentStoreListApi = (data) => (dispatch) => {
  try {
    dispatch(recentStoreListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(recentStoreListSuccess(response));
      } else {
        dispatch(recentStoreListFailed(`${response?.data?.message}`));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(OverviewListFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};
// Submission view ended

// Store checklist started
export const LisStoreCheckListRequest = () => ({
  type: STORE_CHECKLIST_LIST_REQUEST,
});
export const ListStoreChecklistSuccess = (list) => ({
  type: STORE_CHECKLIST_LIST_SUCCESS,
  payload: list,
});
export const ListStoreChecklistFailed = (error) => ({
  type: STORE_CHECKLIST_LIST_ERROR,
  payload: error,
});

export const ListStoreChecklistApi = (data) => (dispatch) => {
  try {
    dispatch(LisStoreCheckListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(ListStoreChecklistSuccess(data));
      } else {
        dispatch(ListStoreChecklistFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListStoreChecklistFailed(error));
    console.error("Something went Wrong", error);
  }
};
// Store checklist ended

// User checklist started
export const LisUserCheckListRequest = () => ({
  type: USER_CHECKLIST_LIST_REQUEST,
});
export const ListUserChecklistSuccess = (list) => ({
  type: USER_CHECKLIST_LIST_SUCCESS,
  payload: list,
});
export const ListUserChecklistFailed = (error) => ({
  type: USER_CHECKLIST_LIST_ERROR,
  payload: error,
});

export const ListUserChecklistApi = (data) => (dispatch) => {
  try {
    dispatch(LisUserCheckListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(ListUserChecklistSuccess(data));
      } else {
        dispatch(ListUserChecklistFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListUserChecklistFailed(error));
    console.error("Something went Wrong", error);
  }
};
// User checklist ended
// Allchecklist add form started
export const CreateAllChecklistRequest = () => ({
  type: ADD_ALLCHECKLIST_REQUEST,
});
export const CreateAllChecklistSuccess = (list) => ({
  type: ADD_ALLCHECKLIST_SUCCESS,
  payload: list,
});
export const CreateAllChecklistFailed = (error) => ({
  type: ADD_ALLCHECKLIST_ERROR,
  payload: error,
});

export const CreateAllChecklistApi = (data) => (dispatch) => {
  try {
    dispatch(CreateAllChecklistRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(CreateAllChecklistSuccess(response));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(CreateAllChecklistFailed(`${response?.data?.message}`));
        toast.error(`${response.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(CreateAllChecklistFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};
// Allchecklist add form ended

// Allchecklist add form started
export const UpdateAllchecklistRequest = () => ({
  type: UPDATE_ALLCHECKLIS_REQUEST,
});
export const UpdateAllchecklistSuccess = (list) => ({
  type: UPDATE_ALLCHECKLIS_SUCCESS,
  payload: list,
});
export const UpdateAllchecklistFailed = (error) => ({
  type: UPDATE_ALLCHECKLIS_ERROR,
  payload: error,
});

export const UpdateAllchecklistApi = (data) => (dispatch) => {
  try {
    dispatch(UpdateAllchecklistRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(UpdateAllchecklistSuccess(response));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(UpdateAllchecklistFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(UpdateAllchecklistFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};
// Allchecklist add form ended
// Overview action
export const CategoryData = (companyid) => ({
  type: CATEGORY_DATA_REQUEST,
  payload: companyid,
});

export const CategoryDataSuccess = (companyid) => ({
  type: CATEGORY_DATA_SUCCESS,
  payload: companyid,
});

export const CategoryDataFailed = (error) => ({
  type: CATEGORY_DATA_ERROR,
  payload: error,
});

export const CategoryDataApi = (data) => (dispatch) => {
  try {
    dispatch(CategoryData());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(CategoryDataSuccess(response));
      } else {
        dispatch(CategoryDataFailed(`${response?.data?.message}`));
      }
    });
  } catch (error) {
    dispatch(CategoryDataFailed(error));
    console.error("Something went Wrong", error);
  }
};

//CHART DTAA
export const ChecklistChart = (companyid) => ({
  type: OVERVIEW_CHART_REQUEST,
  payload: companyid,
});

export const ChecklistChartSuccess = (companyid) => ({
  type: OVERVIEW_CHART_SUCCESS,
  payload: companyid,
});

export const ChecklistChartFailed = (error) => ({
  type: OVERVIEW_CHART_ERROR,
  payload: error,
});

export const ChecklistChartApi = (data) => (dispatch) => {
  try {
    dispatch(ChecklistChart());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(ChecklistChartSuccess(response));
      } else {
        dispatch(ChecklistChartFailed(`${response?.data?.message}`));
      }
    });
  } catch (error) {
    dispatch(ChecklistChartFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const SwitchAllchecklistRequest = (companyid) => ({
  type: SWITCH_ALLCHECKLIST_REQUEST,
  payload: companyid,
});

export const SwitchAllchecklistSuccess = (companyid) => ({
  type: SWITCH_ALLCHECKLIST_SUCCESS,
  payload: companyid,
});

export const SwitchAllchecklistFailed = (error) => ({
  type: SWITCH_ALLCHECKLIST_ERROR,
  payload: error,
});

export const SwitchAllchecklistApi = (data) => (dispatch) => {
  try {
    dispatch(SwitchAllchecklistRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(SwitchAllchecklistSuccess(response));
      } else {
        dispatch(SwitchAllchecklistFailed(`${response?.data?.message}`));
      }
    });
  } catch (error) {
    dispatch(SwitchAllchecklistFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const SearchSelectRequest = (companyid) => ({
  type: SUBMISSION_SELECT_SEARCH_REQUEST,
  payload: companyid,
});

export const SearchSelectSuccess = (companyid) => ({
  type: SUBMISSION_SELECT_SEARCH_SUCCESS,
  payload: companyid,
});

export const SearchSelectFailed = (error) => ({
  type: SUBMISSION_SELECT_SEARCH_ERROR,
  payload: error,
});

export const SearchSelectApi = (data) => (dispatch) => {
  try {
    dispatch(SearchSelectRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(SearchSelectSuccess(response));
      } else {
        dispatch(SearchSelectFailed(`${response?.data?.message}`));
      }
    });
  } catch (error) {
    dispatch(SearchSelectFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const CR_LeftGraph_Request = (companyid) => ({
  type: C_R_LEFT_GRAPH_REQUEST,
  payload: companyid,
});

export const CR_LeftGraph_Success = (companyid) => ({
  type: C_R_LEFT_GRAPH_SUCCESS,
  payload: companyid,
});

export const CR_LeftGraph_Failed = (error) => ({
  type: C_R_LEFT_GRAPH_ERROR,
  payload: error,
});

export const CR_LeftGraph_Api = (data) => (dispatch) => {
  try {
    dispatch(CR_LeftGraph_Request(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(CR_LeftGraph_Success(response));
      } else {
        dispatch(CR_LeftGraph_Failed(`${response?.data?.message}`));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(CR_LeftGraph_Failed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const CR_Graph_Request = (companyid) => ({
  type: C_R_GRAPH_REQUEST,
  payload: companyid,
});

export const CR_Graph_Success = (companyid) => ({
  type: C_R_GRAPH_SUCCESS,
  payload: companyid,
});

export const CR_Graph_Failed = (error) => ({
  type: C_R_GRAPH_ERROR,
  payload: error,
});

export const CR_Graph_Api = (data) => (dispatch) => {
  try {
    dispatch(CR_Graph_Request(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(CR_Graph_Success(response));
      } else {
        dispatch(CR_Graph_Failed(`${response?.data?.message}`));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(CR_Graph_Failed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const UserPerformanceCountRequest = (companyid) => ({
  type: UPC_REQUEST,
  payload: companyid,
});

export const UserPerformanceCountSuccess = (companyid) => ({
  type: UPC_SUCCESS,
  payload: companyid,
});

export const UserPerformanceCountFailed = (error) => ({
  type: UPC_ERROR,
  payload: error,
});

export const UserPerformanceCountAction = (data) => (dispatch) => {
  try {
    dispatch(UserPerformanceCountRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(UserPerformanceCountSuccess(response));
      } else {
        dispatch(UserPerformanceCountFailed(`${response?.data?.message}`));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(UserPerformanceCountFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const StorePerformanceCountRequest = (companyid) => ({
  type: CSC_REQUEST,
  payload: companyid,
});

export const StorePerformanceCountSuccess = (companyid) => ({
  type: CSC_SUCCESS,
  payload: companyid,
});

export const StorePerformanceCountFailed = (error) => ({
  type: CSC_ERROR,
  payload: error,
});

export const StorePerformanceCountAction = (data) => (dispatch) => {
  try {
    dispatch(StorePerformanceCountRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(StorePerformanceCountSuccess(response));
      } else {
        dispatch(StorePerformanceCountFailed(`${response?.data?.message}`));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(StorePerformanceCountFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const StorewiseUserChecklistReportRequest = (companyid) => ({
  type: S_W_U_C_R_REQUEST,
  payload: companyid,
});

export const StorewiseUserChecklistReportSuccess = (companyid) => ({
  type: S_W_U_C_R_SUCCESS,
  payload: companyid,
});

export const StorewiseUserChecklistReportFailed = (error) => ({
  type: S_W_U_C_R_ERROR,
  payload: error,
});

export const StorewiseUserChecklistReportAction = (data) => (dispatch) => {
  try {
    dispatch(StorewiseUserChecklistReportRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(StorewiseUserChecklistReportSuccess(response));
      } else {
        dispatch(
          StorewiseUserChecklistReportFailed(
            `${response.response?.data?.message}`
          )
        );
        toast.error(`${response.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(StorewiseUserChecklistReportFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const StorewiseSingleUserChecklistReportRequest = (companyid) => ({
  type: S_W_S_U_C_R_REQUEST,
  payload: companyid,
});

export const StorewiseSingleUserChecklistReportSuccess = (companyid) => ({
  type: S_W_S_U_C_R_SUCCESS,
  payload: companyid,
});

export const StorewiseSingleUserChecklistReportFailed = (error) => ({
  type: S_W_S_U_C_R_ERROR,
  payload: error,
});

export const StorewiseSingleUserChecklistReportAction =
  (data) => (dispatch) => {
    try {
      dispatch(StorewiseSingleUserChecklistReportRequest(data));
      JwtAuthService.GetApiService(data).then((response) => {
        if (response?.status === 200) {
          dispatch(StorewiseSingleUserChecklistReportSuccess(response));
        } else {
          dispatch(
            StorewiseSingleUserChecklistReportFailed(
              `${response.response?.data?.message}`
            )
          );
          toast.error(`${response.response?.data?.message}`);
        }
      });
    } catch (error) {
      dispatch(StorewiseSingleUserChecklistReportFailed(error));
      console.error("Something went Wrong", error);
      // toast.error(error);
    }
  };

export const StorewiseUserChecklistGraphCountRequest = (companyid) => ({
  type: S_W_U_C_G_C_REQUEST,
  payload: companyid,
});

export const StorewiseUserChecklistGraphCountSuccess = (companyid) => ({
  type: S_W_U_C_G_C_SUCCESS,
  payload: companyid,
});

export const StorewiseUserChecklistGraphCountFailed = (error) => ({
  type: S_W_U_C_G_C_ERROR,
  payload: error,
});

export const StorewiseUserChecklistGraphCountAction = (data) => (dispatch) => {
  try {
    dispatch(StorewiseUserChecklistGraphCountRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(StorewiseUserChecklistGraphCountSuccess(response));
      } else {
        dispatch(
          StorewiseUserChecklistGraphCountFailed(
            `${response.response?.data?.message}`
          )
        );
        toast.error(`${response.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(StorewiseUserChecklistGraphCountFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const StoreWiseSingleUserChecklistGraphCountRequest = (companyid) => ({
  type: S_W_S_U_G_C_R_REQUEST,
  payload: companyid,
});

export const StoreWiseSingleUserChecklistGraphCountSuccess = (companyid) => ({
  type: S_W_S_U_G_C_R_SUCCESS,
  payload: companyid,
});

export const StoreWiseSingleUserChecklistGraphCountFailed = (error) => ({
  type: S_W_S_U_G_C_R_ERROR,
  payload: error,
});

export const StoreWiseSingleUserChecklistGraphCountAction =
  (data) => (dispatch) => {
    try {
      dispatch(StoreWiseSingleUserChecklistGraphCountRequest(data));
      JwtAuthService.GetApiService(data).then((response) => {
        if (response?.status === 200) {
          dispatch(StoreWiseSingleUserChecklistGraphCountSuccess(response));
        } else {
          dispatch(
            StoreWiseSingleUserChecklistGraphCountFailed(
              `${response.response?.data?.message}`
            )
          );
          toast.error(`${response.response?.data?.message}`);
        }
      });
    } catch (error) {
      dispatch(StoreWiseSingleUserChecklistGraphCountFailed(error));
      console.error("Something went Wrong", error);
      // toast.error(error);
    }
  };

//////////////////////////////////////////////////

export const StorewiseChecklistReportRequest = () => ({
  type: GET_STOREWISECHECKLISTREPORT_REQUEST,
});
export const StorewiseChecklistReportSuccess = (list) => ({
  type: GET_STOREWISECHECKLISTREPORT_SUCCESS,
  payload: list,
});
export const StorewiseChecklistReportFailed = (error) => ({
  type: GET_STOREWISECHECKLISTREPORT_ERROR,
  payload: error,
});

export const StorewiseChecklistReportApi = (data) => (dispatch) => {
  try {
    dispatch(StorewiseChecklistReportRequest(data));
    JwtAuthService.StorewiseChecklistReportApi(data).then((response) => {
      if (response?.status === 200) {
        dispatch(StorewiseChecklistReportSuccess(response));
      } else {
        dispatch(StorewiseChecklistReportFailed(`${response?.data?.message}`));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(StorewiseChecklistReportFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const AllActiveChecklistListRequest = () => ({
  type: ALLACTIVECHECKLIST_LIST_REQUEST,
});
export const AllActiveChecklistListSuccess = (list) => ({
  type: ALLACTIVECHECKLIST_LIST_SUCCESS,
  payload: list,
});
export const AllAciveChecklistListFailed = (error) => ({
  type: ALLACTIVECHECKLIST_LIST_ERROR,
  payload: error,
});

export const AllActiveChecklistListApi = (data) => (dispatch) => {
  try {
    dispatch(AllActiveChecklistListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(AllActiveChecklistListSuccess(response));
      } else {
        dispatch(AllAciveChecklistListFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AllAciveChecklistListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const GenerateAuditPdfRequest = () => ({
  type: GENERATE_AUDIT_REQUEST,
});
export const GenerateAuditPdfSuccess = (list) => ({
  type: GENERATE_AUDIT_SUCCESS,
  payload: list,
});
export const GenerateAuditPdfFailed = (error) => ({
  type: GENERATE_AUDIT_ERROR,
  payload: error,
});

export const GenerateAuditPdfApi = (data) => (dispatch) => {
  try {
    dispatch(GenerateAuditPdfRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(GenerateAuditPdfSuccess(response));
      } else {
        dispatch(GenerateAuditPdfFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(GenerateAuditPdfFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

// Upload Image Add Ques
export const UploadImgAddQ = (companyid) => ({
  type: UPLOAD_IMG_ADD_QUES_REQUEST,
  payload: companyid,
});

export const UploadImgAddQSuccess = (companyid) => ({
  type: UPLOAD_IMG_ADD_QUES_SUCCESS,
  payload: companyid,
});

export const UploadImgAddQFailed = (error) => ({
  type: UPLOAD_IMG_ADD_QUES_ERROR,
  payload: error,
});

export const UploadImageAddQuesApi = (data) => (dispatch) => {
  try {
    dispatch(UploadImgAddQ(data));
    JwtAuthService.BinaryImageApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(UploadImgAddQSuccess(data));
        toast.success(`${data?.data?.message}`);
      } else {
        dispatch(UploadImgAddQFailed(response?.response?.statusText));
        toast.error(`${data?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(UploadImgAddQFailed(error));
  }
};

// Upload Image Edit Ques
export const UploadImgEditQ = (companyid) => ({
  type: UPLOAD_IMG_EDIT_QUES_REQUEST,
  payload: companyid,
});

export const UploadImgEditQSuccess = (companyid) => ({
  type: UPLOAD_IMG_EDIT_QUES_SUCCESS,
  payload: companyid,
});

export const UploadImgEditQFailed = (error) => ({
  type: UPLOAD_IMG_EDIT_QUES_ERROR,
  payload: error,
});

export const UploadImageEditQuesApi = (data) => (dispatch) => {
  try {
    dispatch(UploadImgEditQ(data));
    JwtAuthService.BinaryImageApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(UploadImgEditQSuccess(data));
        toast.success(`${response?.data?.message}`, { autoClose: 3000 });
      } else {
        dispatch(UploadImgEditQFailed(response?.statusText));
        toast.error(
          `${
            response.response?.data?.message?.message
              ? response.response?.data?.message?.message
              : response.response?.data?.message
          }`,
          { autoClose: 3000 }
        );
      }
    });
  } catch (error) {
    dispatch(UploadImgEditQFailed(error));
  }
};

export const ActiveChecklistListRequest = () => ({
  type: ACTIVE_ALLCHECKLIST_REQUEST,
});
export const ActiveChecklistListSuccess = (list) => ({
  type: ACTIVE_ALLCHECKLIST_SUCCESS,
  payload: list,
});
export const AciveChecklistListFailed = (error) => ({
  type: ACTIVE_ALLCHECKLIST_ERROR,
  payload: error,
});

export const ActiveChecklistListApi = (data) => (dispatch) => {
  try {
    dispatch(ActiveChecklistListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(ActiveChecklistListSuccess(response));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(AciveChecklistListFailed(`${response?.data?.message}`));
        console.log("response?.data", response?.data);
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AciveChecklistListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const PendingChecklistListRequest = () => ({
  type: PENDING_ALLCHECKLIST_REQUEST,
});
export const PendingChecklistListSuccess = (list) => ({
  type: PENDING_ALLCHECKLIST_SUCCESS,
  payload: list,
});
export const PendingChecklistListFailed = (error) => ({
  type: PENDING_ALLCHECKLIST_ERROR,
  payload: error,
});

export const PendingChecklistListApi = (data) => (dispatch) => {
  try {
    dispatch(PendingChecklistListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(PendingChecklistListSuccess(response));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(PendingChecklistListFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(PendingChecklistListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const UpcomingChecklistRequest = (companyid) => ({
  type: UPCOMING_REQUEST,
  payload: companyid,
});

export const UpcomingChecklistSuccess = (companyid) => ({
  type: UPCOMING_SUCCESS,
  payload: companyid,
});

export const UpcomingChecklistFailed = (error) => ({
  type: UPCOMING_ERROR,
  payload: error,
});

export const UpcomingChecklistAction = (data) => (dispatch) => {
  try {
    dispatch(UpcomingChecklistRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(UpcomingChecklistSuccess(response));
      } else {
        dispatch(
          UpcomingChecklistFailed(`${response.response?.data?.message}`)
        );
        toast.error(`${response.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(UpcomingChecklistFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const RemainingChecklistRequest = (companyid) => ({
  type: REMAINING_REQUEST,
  payload: companyid,
});

export const RemainingChecklistSuccess = (companyid) => ({
  type: REMAINING_SUCCESS,
  payload: companyid,
});

export const RemainingChecklistFailed = (error) => ({
  type: REMAINING_ERROR,
  payload: error,
});

export const RemainingChecklistAction = (data) => (dispatch) => {
  try {
    dispatch(RemainingChecklistRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(RemainingChecklistSuccess(response));
      } else {
        dispatch(
          RemainingChecklistFailed(`${response.response?.data?.message}`)
        );
        toast.error(`${response.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(RemainingChecklistFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const CompletedChecklistRequest = (companyid) => ({
  type: COMPLETED_REQUEST,
  payload: companyid,
});

export const CompletedChecklistSuccess = (companyid) => ({
  type: COMPLETED_REQUEST,
  payload: companyid,
});

export const CompletedChecklistFailed = (error) => ({
  type: COMPLETED_REQUEST,
  payload: error,
});

export const CompletedChecklistAction = (data) => (dispatch) => {
  try {
    dispatch(CompletedChecklistRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(CompletedChecklistSuccess(response));
      } else {
        dispatch(
          CompletedChecklistFailed(`${response.response?.data?.message}`)
        );
        toast.error(`${response.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(CompletedChecklistFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};
export const MissedChecklistRequest = (companyid) => ({
  type: MISSED_REQUEST,
  payload: companyid,
});

export const MissedChecklistSuccess = (companyid) => ({
  type: MISSED_SUCCESS,
  payload: companyid,
});

export const MissedChecklistFailed = (error) => ({
  type: MISSED_ERROR,
  payload: error,
});

export const ChecklistDataFilterOnDateAction = (data) => (dispatch) => {
  try {
    dispatch(MissedChecklistRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(MissedChecklistSuccess(response));
      } else {
        dispatch(MissedChecklistFailed(`${response.response?.data?.message}`));
        toast.error(`${response.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(MissedChecklistFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

// Used for pdf generation
export const QueueChecklistRequest = (companyid) => ({
  type: QUEUE_REQUEST,
  payload: companyid,
});

export const QueueChecklistSuccess = (companyid) => ({
  type: QUEUE_SUCCESS,
  payload: companyid,
});

export const QueueChecklistFailed = (error) => ({
  type: QUEUE_ERROR,
  payload: error,
});

export const ChecklistDateFilterAction = (data) => (dispatch) => {
  try {
    dispatch(QueueChecklistRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(QueueChecklistSuccess(response));
      } else {
        dispatch(QueueChecklistFailed(`${response.response?.data?.message}`));
        toast.error(`${response.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(QueueChecklistFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const ChecklistHomeRequest = (companyid) => ({
  type: CHECKLIST_HOME_REQUEST,
  payload: companyid,
});

export const ChecklistHomeSuccess = (companyid) => ({
  type: CHECKLIST_HOME_SUCCESS,
  payload: companyid,
});

export const ChecklistHomeFailed = (error) => ({
  type: CHECKLIST_HOME_ERROR,
  payload: error,
});

export const ChecklistHomeAction = (data) => (dispatch) => {
  try {
    dispatch(ChecklistHomeRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(ChecklistHomeSuccess(response));
      } else {
        dispatch(ChecklistHomeFailed(`${response.response?.data?.message}`));
        toast.error(`${response.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ChecklistHomeFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const AuditorChecklistSubmissionDataRequest = (companyid) => ({
  type: AUDITOR_CHECKLIST_SUBMISSION_REQUEST,
  payload: companyid,
});

export const AuditorChecklistSubmissionDataSuccess = (companyid) => {
  console.log("comapy", companyid);
  return {
    type: AUDITOR_CHECKLIST_SUBMISSION_SUCCESS,
    payload: companyid,
  };
};

export const AuditorChecklistSubmissionDataFailed = (error) => ({
  type: AUDITOR_CHECKLIST_SUBMISSION_ERROR,
  payload: error,
});

export const SubmitChecklistAction = (data, pdfData) => (dispatch) => {
  try {
    dispatch(AuditorChecklistSubmissionDataRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        toast.success(`${response?.data?.message}`);
        dispatch(AuditorChecklistSubmissionDataSuccess(response));

        const receivedSubmissionID = response.data.data._id;
        const receivedImageStatus = response.data.data.imageStatus;
        pdfData.url = `${pdfData.url}&submissionId=${receivedSubmissionID}&imageStatus=${receivedImageStatus}`;
        JwtAuthService.GetApiService(pdfData).then((response) => {
          if (response?.status === 200) {
            dispatch(QueueChecklistSuccess(response));
          } else {
            dispatch(
              QueueChecklistFailed(`${response.response?.data?.message}`)
            );
            toast.error(`${response.response?.data?.message}`);
          }
        });
      } else {
        dispatch(
          AuditorChecklistSubmissionDataFailed(
            `${response.response?.data?.message}`
          )
        );
        toast.error(`${response.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AuditorChecklistSubmissionDataFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};
// Upload Image Add Ques
export const UploadMediaApiRequest = (companyid) => ({
  type: UPLOAD_MEDIA_REQUEST,
  payload: companyid,
});

export const UploadMediaApiSuccess = (companyid) => ({
  type: UPLOAD_MEDIA_SUCCESS,
  payload: companyid,
});

export const UploadMediaApiFailed = (error) => ({
  type: UPLOAD_MEDIA_ERROR,
  payload: error,
});

export const UploadMediaApi = (data, questionIndex) => (dispatch) => {
  try {
    dispatch(UploadMediaApiRequest());
    // const uploadedMedia = localhost.getItem("uploadedMedia");
    // const media = JSON.parse(uploadedMedia || "")

    JwtAuthService.BinaryImageApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(
          UploadMediaApiSuccess({ data: response.data.data, id: questionIndex })
        );
        // console.log("response", response);
        toast.success(`${data?.data?.message}`);
        // localhost.setItem("uploadedMedia", JSON.stringify([]));
      } else {
        dispatch(UploadMediaApiFailed(`${data?.data?.message}`));
        toast.error(`${data?.data?.message}`);
      }
    });
  } catch (error) {
    // dispatch(UploadImgAddQFailed(error));
  }
};

export const ChecklkistApprovelRequest = () => ({
  type: CHECKLIST_D_APPROVEL_REQUEST,
});
export const ChecklkistApprovelSuccess = (list) => ({
  type: CHECKLIST_D_APPROVEL_SUCCESS,
  payload: list,
});
export const ChecklkistApprovelFailed = (error) => ({
  type: CHECKLIST_D_APPROVEL_ERROR,
  payload: error,
});

export const ChecklkistApprovelApi = (data, callBack) => (dispatch) => {
  try {
    dispatch(ChecklkistApprovelRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(ChecklkistApprovelSuccess(response));
        callBack && callBack();
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(
          ChecklkistApprovelFailed(`${response?.response?.data?.message}`)
        );
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ChecklkistApprovelFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const SubmissionVersionRequest = (companyid) => ({
  type: SUBMISSION_VERSION_REQUEST,
  payload: companyid,
});

export const SubmissionVersionSuccess = (companyid) => ({
  type: SUBMISSION_VERSION_SUCCESS,
  payload: companyid,
});

export const SubmissionVersionFailed = (error) => ({
  type: SUBMISSION_VERSION_ERROR,
  payload: error,
});

export const SubmissionVersionAPI = (data) => (dispatch) => {
  try {
    dispatch(SubmissionVersionRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(SubmissionVersionSuccess(response));
      } else {
        dispatch(
          SubmissionVersionFailed(`${response.response?.data?.message}`)
        );
        toast.error(`${response.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(SubmissionVersionFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};


export const GenerateSignedUrlRequest = () => ({
  type: SIGNED_URL_REQUEST,
});
export const GenerateSignedUrlSuccess = (list) => ({
  type: SIGNED_URL_SUCCESS,
  payload: list,
});
export const GenerateSignedUrlFailed = (error) => ({
  type: SIGNED_URL_ERROR,
  payload: error,
});

export const GenerateSignedUrlApi = (data) => (dispatch) => {
  try {
    dispatch(GenerateSignedUrlRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(GenerateSignedUrlSuccess(response));
      } else {
        dispatch(GenerateSignedUrlFailed(response?.data?.message));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(GenerateSignedUrlFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

