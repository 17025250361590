import ApartmentIcon from '@mui/icons-material/Apartment';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';

const companies = [
  {
    id: 1,
    title: 'Companies',
    icon: <ApartmentIcon />,
    path: '/company-list',
  },
  {
    id: 22,
    title: "Analytical Dashboard",
    analyticalDashboard: true,
    icon: <AnalyticsOutlinedIcon />,
    path: "/analytical-dashboard",
  }
]

export default companies;

