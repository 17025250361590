/** @format */

import {
  ALLCHECKLIST_LIST_ERROR,
  ALLCHECKLIST_LIST_REQUEST,
  ALLCHECKLIST_LIST_SUCCESS,
  ALLFORM_CHECKLIST_ERROR,
  ALLFORM_CHECKLIST_REQUEST,
  ALLFORM_CHECKLIST_SUCCESS,
  CREATE_ALLFORM_ERROR,
  CREATE_ALLFORM_REQUEST,
  CREATE_ALLFORM_SUCCESS,
  DELETE_ALLFORM_ERROR,
  DELETE_ALLFORM_REQUEST,
  DELETE_ALLFORM_SUCCESS,
  SUBMISSION_LIST_ERROR,
  SUBMISSION_LIST_REQUEST,
  SUBMISSION_LIST_SUCCESS,
  SUBMISSION_VIEW_ERROR,
  SUBMISSION_VIEW_REQUEST,
  SUBMISSION_VIEW_SUCCESS,
  DEPARTMENT_LIST_ERROR,
  DEPARTMENT_LIST_REQUEST,
  DEPARTMENT_LIST_SUCCESS,
  STORE_LIST_ERROR,
  STORE_LIST_REQUEST,
  STORE_LIST_SUCCESS,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_ERROR,
  RULES_ADDED_REQUEST,
  RULES_ADDED_SUCCESS,
  RULES_ADDED_ERROR,
  VIEW_ALLFORM_CHECKLIST_REQUEST,
  VIEW_ALLFORM_CHECKLIST_SUCCESS,
  VIEW_ALLFORM_CHECKLIST_ERROR,
  EDIT_ALL_FORM_SUCCESS,
  EDIT_ALL_FORM_ERROR,
  EDIT_ALL_FORM_REQUEST,
  ADD_ALLCHECKLIST_REQUEST,
  ADD_ALLCHECKLIST_SUCCESS,
  ADD_ALLCHECKLIST_ERROR,
  USER_CHECKLIST_LIST_REQUEST,
  USER_CHECKLIST_LIST_SUCCESS,
  USER_CHECKLIST_LIST_ERROR,
  STORE_CHECKLIST_LIST_REQUEST,
  STORE_CHECKLIST_LIST_SUCCESS,
  STORE_CHECKLIST_LIST_ERROR,
  OVERVIEW_LIST_REQUEST,
  OVERVIEW_LIST_SUCCESS,
  OVERVIEW_LIST_ERROR,
  CATEGORY_DATA_REQUEST,
  CATEGORY_DATA_SUCCESS,
  CATEGORY_DATA_ERROR,
  OVERVIEW_CHART_REQUEST,
  OVERVIEW_CHART_SUCCESS,
  OVERVIEW_CHART_ERROR,
  UPDATE_ALLCHECKLIS_REQUEST,
  UPDATE_ALLCHECKLIS_ERROR,
  UPDATE_ALLCHECKLIS_SUCCESS,
  SWITCH_ALLCHECKLIST_ERROR,
  SWITCH_ALLCHECKLIST_REQUEST,
  SWITCH_ALLCHECKLIST_SUCCESS,
  SUBMISSION_SELECT_SEARCH_REQUEST,
  SUBMISSION_SELECT_SEARCH_SUCCESS,
  SUBMISSION_SELECT_SEARCH_ERROR,
  ALLCHECKLIST_ARCHIVED_REQUEST,
  ALLCHECKLIST_ARCHIVED_SUCCESS,
  ALLCHECKLIST_ARCHIVED_ERROR,
  FORM_RULES_UPDATE_ADDED_REQUEST,
  FORM_RULES_UPDATE_ADDED_SUCCESS,
  FORM_RULES_UPDATE_ADDED_ERROR,
  USER_ARCHIVED_REQUEST,
  USER_ARCHIVED_SUCCESS,
  USER_ARCHIVED_ERROR,
  FORMLIST_ARCHIVED_REQUEST,
  FORMLIST_ARCHIVED_SUCCESS,
  FORMLIST_ARCHIVED_ERROR,
  CHECKLIST_IMAGE_REQUEST,
  CHECKLIST_IMAGE_SUCCESS,
  CHECKLIST_IMAGE_ERROR,
  RATING_REQUEST,
  RATING_SUCCESS,
  RATING_ERROR,
  CREATE_IMAGELOG_REQUEST,
  CREATE_IMAGELOG_SUCCESS,
  CREATE_IMAGELOG_ERROR,
  C_R_LEFT_GRAPH_REQUEST,
  C_R_LEFT_GRAPH_SUCCESS,
  C_R_LEFT_GRAPH_ERROR,
  C_R_GRAPH_REQUEST,
  C_R_GRAPH_SUCCESS,
  C_R_GRAPH_ERROR,
  UPC_REQUEST,
  UPC_SUCCESS,
  UPC_ERROR,
  CSC_REQUEST,
  CSC_SUCCESS,
  CSC_ERROR,
  S_W_U_C_R_REQUEST,
  S_W_U_C_R_SUCCESS,
  S_W_U_C_R_ERROR,
  S_W_S_U_C_R_REQUEST,
  S_W_S_U_C_R_SUCCESS,
  S_W_S_U_C_R_ERROR,
  S_W_U_C_G_C_REQUEST,
  S_W_U_C_G_C_SUCCESS,
  S_W_U_C_G_C_ERROR,
  S_W_S_U_G_C_R_REQUEST,
  S_W_S_U_G_C_R_SUCCESS,
  S_W_S_U_G_C_R_ERROR,
  GET_STOREWISECHECKLISTREPORT_REQUEST,
  GET_STOREWISECHECKLISTREPORT_SUCCESS,
  GET_STOREWISECHECKLISTREPORT_ERROR,
  ALLACTIVECHECKLIST_LIST_REQUEST,
  ALLACTIVECHECKLIST_LIST_SUCCESS,
  ALLACTIVECHECKLIST_LIST_ERROR,
  GENERATE_AUDIT_REQUEST,
  GENERATE_AUDIT_SUCCESS,
  GENERATE_AUDIT_ERROR,
  UPLOAD_IMG_ADD_QUES_REQUEST,
  UPLOAD_IMG_ADD_QUES_SUCCESS,
  UPLOAD_IMG_ADD_QUES_ERROR,
  UPLOAD_IMG_EDIT_QUES_REQUEST,
  UPLOAD_IMG_EDIT_QUES_SUCCESS,
  UPLOAD_IMG_EDIT_QUES_ERROR,
  GENERATE_PDF_REQUEST,
  GENERATE_PDF_SUCCESS,
  GENERATE_PDF_ERROR,
  DELATE_ALLCHECKLIST_REQUEST,
  DELATE_ALLCHECKLIST_SUCCESS,
  DELATE_ALLCHECKLIST_ERROR,
  RECENT_STORE_LIST_REQUEST,
  RECENT_STORE_LIST_SUCCESS,
  RECENT_STORE_LIST_ERROR,
  ACTIVE_ALLCHECKLIST_REQUEST,
  ACTIVE_ALLCHECKLIST_SUCCESS,
  ACTIVE_ALLCHECKLIST_ERROR,
  PENDING_ALLCHECKLIST_REQUEST,
  PENDING_ALLCHECKLIST_SUCCESS,
  PENDING_ALLCHECKLIST_ERROR,
  UPCOMING_REQUEST,
  UPCOMING_SUCCESS,
  UPCOMING_ERROR,
  REMAINING_REQUEST,
  REMAINING_SUCCESS,
  REMAINING_ERROR,
  COMPLETED_REQUEST,
  COMPLETED_SUCCESS,
  COMPLETED_ERROR,
  MISSED_REQUEST,
  MISSED_SUCCESS,
  MISSED_ERROR,
  QUEUE_REQUEST,
  QUEUE_SUCCESS,
  QUEUE_ERROR,
  CHECKLIST_HOME_REQUEST,
  CHECKLIST_HOME_SUCCESS,
  CHECKLIST_HOME_ERROR,
  AUDITOR_CHECKLIST_SUBMISSION_REQUEST,
  AUDITOR_CHECKLIST_SUBMISSION_SUCCESS,
  AUDITOR_CHECKLIST_SUBMISSION_ERROR,
  UPLOAD_MEDIA_REQUEST,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_ERROR,
  CHECKLIST_D_APPROVEL_REQUEST,
  CHECKLIST_D_APPROVEL_SUCCESS,
  CHECKLIST_D_APPROVEL_ERROR,
  DISAPPROVED_FORM_ERROR,
  DISAPPROVED_FORM_SUCCESS,
  DISAPPROVED_FORM_REQUEST,
  SUBMISSION_VERSION_REQUEST,
  SUBMISSION_VERSION_SUCCESS,
  SUBMISSION_VERSION_ERROR,
  SIGNED_URL_REQUEST,
  SIGNED_URL_SUCCESS,
  SIGNED_URL_ERROR,
} from "../types/Types";

const initialState = {
  loading: false,
  grapLoading: false,
  urcLoading: false,
  cscLoading: false,
  loadingOverList: false,
  loadingRecentStoreSubmission: false,
  error: "",
  allchecklist: "",
  disapprovedFormsError: "",
  disapprovedFormsList: [],
  allactivechecklist: [],
  activeChecklist: [],
  pendingChecklist: [],
  allform: "",
  addquestion: "",
  submission: "",
  department: "",
  store: "",
  category: "",
  submissionList: "",
  ruleslist: "",
  viewFormList: [],
  editData: "",
  overList: "",
  recentStoreSubmission: [],
  allChecklistPost: "",
  userCheckList: [],
  storeCheckList: "",
  categoryData: [],
  checklistChart: [],
  assignChecklist: [],
  editChecklist: [],
  deleteChecklistRes: "",
  deleteChecklistResNew: "",
  updateResponse: "",
  switchToggle: "",
  selectSearch: [],
  archivedAllCheckList: "",
  userListArchuved: "",
  formListArchived: "",
  FormRulesUpdate: [],
  checkListImageData: "",
  ratingData: "",
  createImageLog: "",
  checkRepLeftGraph: [],
  checkRepGraph: [],
  checkRepUserPerfCount: [],
  checkRepStorePerfCount: [],
  storeWiseUserChecklistReportResp: [],
  StorewiseSingleUserChecklistReportResp: [],
  StorewiseUserChecklistGraphCountResp: [],
  StoreWiseSingleUserChecklistGraphCountResp: [],
  StorewiseChecklistReportResp: "",
  downloadPdf: [],
  uploadImgAddQ: [],
  uploadImgEditQ: [],
  generatePdf: "",
  upcoming: [],
  remaining: [],
  completed: [],
  filteredData: [],
  queue: [],
  checklistHome: [],
  auditorChecklistSubmissionData: [],
  uploadSubmissionMedia: [],
  checklistApprovelRes: [],
  historyRes: [],
  signedUrlRes: []
};
const checklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALLCHECKLIST_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ALLCHECKLIST_LIST_SUCCESS: {
      return {
        ...state,
        allchecklist: action?.payload,
        loading: false,
      };
    }
    case ALLCHECKLIST_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case CREATE_IMAGELOG_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CREATE_IMAGELOG_SUCCESS: {
      return {
        ...state,
        createImageLog: action?.payload,
        loading: false,
      };
    }
    case CREATE_IMAGELOG_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case ALLCHECKLIST_ARCHIVED_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ALLCHECKLIST_ARCHIVED_SUCCESS: {
      return {
        ...state,
        archivedAllCheckList: action?.payload,
        loading: false,
      };
    }
    case ALLCHECKLIST_ARCHIVED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case FORMLIST_ARCHIVED_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case CHECKLIST_IMAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CHECKLIST_IMAGE_SUCCESS: {
      return {
        ...state,
        checkListImageData: action?.payload,
        loading: false,
      };
    }
    case ALLCHECKLIST_ARCHIVED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CHECKLIST_IMAGE_ERROR: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case FORMLIST_ARCHIVED_SUCCESS: {
      return {
        ...state,
        formListArchived: action?.payload,
        loading: false,
      };
    }
    case FORMLIST_ARCHIVED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case USER_ARCHIVED_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case USER_ARCHIVED_SUCCESS: {
      return {
        ...state,
        userListArchuved: action?.payload,
        loading: false,
      };
    }
    case USER_ARCHIVED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ALLFORM_CHECKLIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ALLFORM_CHECKLIST_SUCCESS: {
      return {
        ...state,
        allform: action?.payload,
        loading: false,
      };
    }
    case ALLFORM_CHECKLIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case VIEW_ALLFORM_CHECKLIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case VIEW_ALLFORM_CHECKLIST_SUCCESS: {
      return {
        ...state,
        viewFormList: action?.payload,
        loading: false,
      };
    }
    case VIEW_ALLFORM_CHECKLIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case RATING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case RATING_SUCCESS: {
      return {
        ...state,
        ratingData: action?.payload,
      };
    }
    case RATING_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case EDIT_ALL_FORM_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case EDIT_ALL_FORM_SUCCESS: {
      return {
        ...state,
        editData: action?.payload,
        loading: false,
      };
    }
    case EDIT_ALL_FORM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case CREATE_ALLFORM_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CREATE_ALLFORM_SUCCESS: {
      return {
        ...state,
        addquestion: action?.payload,
        loading: false,
      };
    }
    case CREATE_ALLFORM_ERROR: {
      return {
        ...state,
        loading: false,
        error: " action.error",
      };
    }
    // submission list
    case SUBMISSION_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case SUBMISSION_LIST_SUCCESS: {
      return {
        ...state,
        submissionList: action?.payload,
        loading: false,
      };
    }
    case SUBMISSION_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    // User Checklist
    case USER_CHECKLIST_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case USER_CHECKLIST_LIST_SUCCESS: {
      return {
        ...state,
        userCheckList: action?.payload,
        loading: false,
      };
    }
    case USER_CHECKLIST_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    // User Checklist
    case STORE_CHECKLIST_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case STORE_CHECKLIST_LIST_SUCCESS: {
      return {
        ...state,
        storeCheckList: action?.payload,
        loading: false,
      };
    }
    case STORE_CHECKLIST_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case DEPARTMENT_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case DEPARTMENT_LIST_SUCCESS: {
      return {
        ...state,
        department: action?.payload,
        loading: false,
      };
    }
    case DEPARTMENT_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case GENERATE_PDF_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case GENERATE_PDF_SUCCESS: {
      return {
        ...state,
        generatePdf: action?.payload,
        loading: false,
      };
    }
    case GENERATE_PDF_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case STORE_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case STORE_LIST_SUCCESS: {
      return {
        ...state,
        store: action?.payload,
        loading: false,
      };
    }
    case STORE_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CATEGORY_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        category: action?.payload,
        loading: false,
      };
    }
    case CATEGORY_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    // submission view
    case SUBMISSION_VIEW_REQUEST: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case SUBMISSION_VIEW_SUCCESS: {
      return {
        ...state,
        submissionView: action?.payload,
        loading: false,
      };
    }
    case SUBMISSION_VIEW_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case DISAPPROVED_FORM_REQUEST: {
      return {
        ...state,
        loading: false,
        disapprovedFormsList: [],
        disapprovedFormsError: "",
      };
    }
    case DISAPPROVED_FORM_SUCCESS: {
      return {
        ...state,
        disapprovedFormsList: action?.payload,
        loading: false,
      };
    }
    case DISAPPROVED_FORM_ERROR: {
      return {
        ...state,
        loading: false,
        disapprovedFormsList: [],
        disapprovedFormsError: action.error,
      };
    }
    case RULES_ADDED_REQUEST: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case RULES_ADDED_SUCCESS: {
      return {
        ...state,
        ruleslist: action?.payload,
        loading: false,
      };
    }
    case RULES_ADDED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case OVERVIEW_LIST_REQUEST: {
      return {
        ...state,
        loadingOverList: true,
        error: "",
      };
    }
    case OVERVIEW_LIST_SUCCESS: {
      return {
        ...state,
        overList: action?.payload,
        loadingOverList: false,
      };
    }
    case OVERVIEW_LIST_ERROR: {
      return {
        ...state,
        loadingOverList: false,
        error: action.error,
      };
    }
    case RECENT_STORE_LIST_REQUEST: {
      return {
        ...state,
        loadingRecentStoreSubmission: true,
        error: "",
      };
    }
    case RECENT_STORE_LIST_SUCCESS: {
      return {
        ...state,
        recentStoreSubmission: action?.payload,
        loadingRecentStoreSubmission: false,
      };
    }
    case RECENT_STORE_LIST_ERROR: {
      return {
        ...state,
        loadingRecentStoreSubmission: false,
        error: action.error,
      };
    }
    case CATEGORY_DATA_REQUEST: {
      return {
        ...state,
        categoryLoading: true,
        error: "",
      };
    }
    case CATEGORY_DATA_SUCCESS: {
      return {
        ...state,
        categoryLoading: false,
        categoryData: action?.payload,
      };
    }
    case CATEGORY_DATA_ERROR: {
      return {
        ...state,
        categoryLoading: false,
        error: action.error,
      };
    }
    case OVERVIEW_CHART_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case OVERVIEW_CHART_SUCCESS: {
      return {
        ...state,
        loading: false,
        checklistChart: action?.payload,
      };
    }
    case OVERVIEW_CHART_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ADD_ALLCHECKLIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_ALLCHECKLIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        assignChecklist: action?.payload,
      };
    }
    case ADD_ALLCHECKLIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case UPDATE_ALLCHECKLIS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case UPDATE_ALLCHECKLIS_REQUEST: {
      return {
        ...state,
        loading: false,
        editChecklist: action?.payload,
      };
    }
    case UPDATE_ALLCHECKLIS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case DELETE_ALLFORM_REQUEST: {
      return {
        ...state,
        error: "",
      };
    }
    case DELETE_ALLFORM_SUCCESS: {
      return {
        ...state,
        deleteChecklistRes: action?.payload,
      };
    }
    case DELETE_ALLFORM_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case DELATE_ALLCHECKLIST_REQUEST: {
      return {
        ...state,
        error: "",
      };
    }
    case DELATE_ALLCHECKLIST_SUCCESS: {
      return {
        ...state,
        deleteChecklistResNew: action?.payload,
      };
    }
    case DELATE_ALLCHECKLIST_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case UPDATE_ALLCHECKLIS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case UPDATE_ALLCHECKLIS_SUCCESS: {
      return {
        ...state,
        loading: false,
        updateResponse: action?.payload,
      };
    }
    case UPDATE_ALLCHECKLIS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case SWITCH_ALLCHECKLIST_REQUEST: {
      return {
        ...state,
        error: "",
      };
    }
    case SWITCH_ALLCHECKLIST_SUCCESS: {
      return {
        ...state,
        switchToggle: action?.payload,
      };
    }
    case SWITCH_ALLCHECKLIST_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case SUBMISSION_SELECT_SEARCH_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case SUBMISSION_SELECT_SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        selectSearch: action?.payload,
      };
    }
    case SUBMISSION_SELECT_SEARCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case FORM_RULES_UPDATE_ADDED_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case FORM_RULES_UPDATE_ADDED_SUCCESS: {
      return {
        ...state,
        loading: false,
        FormRulesUpdate: action.payload,
      };
    }
    case FORM_RULES_UPDATE_ADDED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case C_R_LEFT_GRAPH_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case C_R_LEFT_GRAPH_SUCCESS: {
      return {
        ...state,
        loading: false,
        checkRepLeftGraph: action.payload,
      };
    }
    case C_R_LEFT_GRAPH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case C_R_GRAPH_REQUEST: {
      return {
        ...state,
        grapLoading: true,
        error: "",
      };
    }
    case C_R_GRAPH_SUCCESS: {
      return {
        ...state,
        grapLoading: false,
        checkRepGraph: action.payload,
      };
    }
    case C_R_GRAPH_ERROR: {
      return {
        ...state,
        grapLoading: false,
        error: action.error,
      };
    }

    case UPC_REQUEST: {
      return {
        ...state,
        urcLoading: true,
        error: "",
      };
    }
    case UPC_SUCCESS: {
      return {
        ...state,
        urcLoading: false,
        checkRepUserPerfCount: action.payload,
      };
    }
    case UPC_ERROR: {
      return {
        ...state,
        urcLoading: false,
        error: action.error,
      };
    }

    case CSC_REQUEST: {
      return {
        ...state,
        cscLoading: true,
        error: "",
      };
    }
    case CSC_SUCCESS: {
      return {
        ...state,
        cscLoading: false,
        checkRepStorePerfCount: action.payload,
      };
    }
    case CSC_ERROR: {
      return {
        ...state,
        cscLoading: false,
        error: action.error,
      };
    }

    case S_W_U_C_R_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case S_W_U_C_R_SUCCESS: {
      return {
        ...state,
        loading: false,
        storeWiseUserChecklistReportResp: action.payload,
      };
    }
    case S_W_U_C_R_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case S_W_S_U_C_R_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case S_W_S_U_C_R_SUCCESS: {
      return {
        ...state,
        loading: false,
        StorewiseSingleUserChecklistReportResp: action.payload,
      };
    }
    case S_W_S_U_C_R_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case S_W_U_C_G_C_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case S_W_U_C_G_C_SUCCESS: {
      return {
        ...state,
        loading: false,
        StorewiseUserChecklistGraphCountResp: action.payload,
      };
    }
    case S_W_U_C_G_C_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case S_W_S_U_G_C_R_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case S_W_S_U_G_C_R_SUCCESS: {
      return {
        ...state,
        loading: false,
        StoreWiseSingleUserChecklistGraphCountResp: action.payload,
      };
    }
    case S_W_S_U_G_C_R_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //////////////////////////////////

    case GET_STOREWISECHECKLISTREPORT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case GET_STOREWISECHECKLISTREPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        StorewiseChecklistReportResp: action.payload,
      };
    }
    case GET_STOREWISECHECKLISTREPORT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case ALLACTIVECHECKLIST_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ALLACTIVECHECKLIST_LIST_SUCCESS: {
      return {
        ...state,
        allactivechecklist: action?.payload,
        loading: false,
      };
    }
    case ALLACTIVECHECKLIST_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case GENERATE_AUDIT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case GENERATE_AUDIT_SUCCESS: {
      return {
        ...state,
        downloadPdf: action?.payload,
        loading: false,
      };
    }
    case GENERATE_AUDIT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    // Upload Image Add Ques
    case UPLOAD_IMG_ADD_QUES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case UPLOAD_IMG_ADD_QUES_SUCCESS: {
      return {
        ...state,
        uploadImgAddQ: action?.payload,
        loading: false,
      };
    }
    case UPLOAD_IMG_ADD_QUES_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    // Upload Imag Edit Ques
    case UPLOAD_IMG_EDIT_QUES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case UPLOAD_IMG_EDIT_QUES_SUCCESS: {
      return {
        ...state,
        uploadImgEditQ: action?.payload,
        loading: false,
      };
    }
    case UPLOAD_IMG_EDIT_QUES_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case ACTIVE_ALLCHECKLIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ACTIVE_ALLCHECKLIST_SUCCESS: {
      return {
        ...state,
        activeChecklist: action?.payload,
        loading: false,
      };
    }
    case ACTIVE_ALLCHECKLIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case PENDING_ALLCHECKLIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case PENDING_ALLCHECKLIST_SUCCESS: {
      return {
        ...state,
        pendingChecklist: action?.payload,
        loading: false,
      };
    }
    case PENDING_ALLCHECKLIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case UPCOMING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case UPCOMING_SUCCESS: {
      return {
        ...state,
        upcoming: action?.payload,
        loading: false,
      };
    }
    case UPCOMING_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case REMAINING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case REMAINING_SUCCESS: {
      return {
        ...state,
        remaining: action?.payload,
        loading: false,
      };
    }
    case REMAINING_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case COMPLETED_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case COMPLETED_SUCCESS: {
      return {
        ...state,
        completed: action?.payload,
        loading: false,
      };
    }
    case COMPLETED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case MISSED_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case MISSED_SUCCESS: {
      return {
        ...state,
        filteredData: action?.payload,
        loading: false,
      };
    }
    case MISSED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case QUEUE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case QUEUE_SUCCESS: {
      return {
        ...state,
        queue: action?.payload,
        loading: false,
      };
    }
    case QUEUE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case CHECKLIST_HOME_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CHECKLIST_HOME_SUCCESS: {
      return {
        ...state,
        checklistHome: action?.payload,
        loading: false,
      };
    }
    case CHECKLIST_HOME_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case AUDITOR_CHECKLIST_SUBMISSION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case AUDITOR_CHECKLIST_SUBMISSION_SUCCESS: {
      return {
        ...state,
        auditorChecklistSubmissionData: action?.payload,
        loading: false,
      };
    }
    case AUDITOR_CHECKLIST_SUBMISSION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case CHECKLIST_D_APPROVEL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CHECKLIST_D_APPROVEL_SUCCESS: {
      return {
        ...state,
        checklistApprovelRes: action?.payload,
        loading: false,
      };
    }
    case CHECKLIST_D_APPROVEL_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case SUBMISSION_VERSION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case SUBMISSION_VERSION_SUCCESS: {
      return {
        ...state,
        historyRes: action?.payload,
        loading: false,
      };
    }
    case SUBMISSION_VERSION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case SIGNED_URL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case SIGNED_URL_SUCCESS: {
      return {
        ...state,
        signedUrlRes: action?.payload,
        loading: false,
      };
    }
    case SIGNED_URL_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }


    default: {
      return state;
    }
  }
};

export default checklistReducer;
