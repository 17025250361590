/** @format */

import React, { useEffect } from "react";
import "./App.css";
import "./component/style.css";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Dashboard from "./component/dashboard";
import AllCheckList from "./component/checklist/AllCheckList";
import Overview from "./component/checklist/Overview";
import SideBar from "./component/navigationBar";
import Company from "./component/company/Company";
import CreateCompany from "./component/company/CreateCompany";
import CompanyList from "./component/company/CompanyList";
import AddForm from "./component/checklist/allCheckList/AddForm";
import ViewForm from "./component/checklist/allCheckList/ViewForm";
import Submission from "./component/checklist/Submission";
import OverviewLMS from "./component/lms/overview";
import AllTrainingReport from "./component/lms/allTrainingReport";
import DepartmentChecklist from "./component/checklist/DepartmentChecklist";
import StoreChecklist from "./component/checklist/StoreChecklist";
import UserChecklist from "./component/checklist/UserChecklist";
import AllForm from "./component/checklist/AllForm";
import ViewDetailsSubmission from "./component/checklist/submissionTabs/ViewDetails";
import CompanyModal from "./component/company/CompanyModal";
import AddAllForm from "./component/checklist/allForm/AddAllForm";
import ViewAllForm from "./component/checklist/allForm/viewFormTabs/ViewForm";
import IssuesOverview from "./component/issues/overview/Index";
import IssuesList from "./component/issues/issuesList/Index";
import AddTraining from "./component/lms/allTrainingReport/pages/AddTraining";
import AddChapter from "./component/lms/allTrainingReport/pages/AddChapter";
import IndividualTraining from "./component/lms/allTrainingReport/pages/IndividualTraining";
import NewIssueCreate from "./component/issues/issuesList/pages/NewIssue";
import EditIssue from "./component/issues/issuesList/pages/EditIssue";
import EditTraining from "./component/lms/allTrainingReport/pages/EditTraining";
import StoreTraining from "./component/lms/storeTraining";
import IndividualStoreTraining from "./component/lms/storeTraining/pages/IndividualTraining";
import StoreUserTraining from "./component/lms/storeTraining/pages/StoreUserTraining";
import AddStoreTraining from "./component/lms/storeTraining/pages/AddTraining";
import UserTraining from "./component/lms/userTraining";
import ViewDetails from "./component/issues/issuesList/pages/ViewDetails";
import ObservationList from "./component/observationList/Index";
import ViewDetailsObsevation from "./component/observationList/pages/ViewDetails";
import BroadcastMessage from "./component/BroadcastMessage/Index";
import LoginForm from "./component/Authentication/LogIn/LoginForm";
import AssessmentList from "./component/lms/assessmentList";
import AddAssessment from "./component/lms/assessmentList/pages/AddAssessment";
import UpdateAssessment from "./component/lms/assessmentList/pages/UpdateAssessment";
import AssessmentQuestion from "./component/lms/assessmentList/pages/AssessmentQuestion";
import CertificateList from "./component/lms/certificateList";
import NewCertificate from "./component/lms/certificateList/pages/NewCertificate";
import EditCertificate from "./component/lms/certificateList/pages/EditCertificate";
import EditAssessment from "./component/lms/assessmentList/pages/EditAssessment";
import EditAddAllForm from "./component/checklist/allForm/EditAllForm";
import { Box } from "@mui/material";
import SignUpForm from "./component/Authentication/SighUp/SignUpForm";
// import EditAssessment from "./component/lms/assessmentList/EditAssessment";
import ForgotPassword from "./component/Authentication/ForgetPassword/ForgotPassword";
import AuditOverview from "./component/audit/overview";
import AllAssignment from "./component/audit/allAssignment";
import AddAssignment from "./component/audit/allAssignment/pages/AddAssignment";
import AllSurvey from "./component/audit/allSurvey";
import SurveyDetails from "./component/audit/allSurvey/pages/SurveyDetails";
import AddStoreChapter from "./component/lms/storeTraining/pages/AddChapter";
import WasteManagement from "./component/wasteManagement";
import WasteLogDetails from "./component/wasteManagement/pages/ViewDetails";
import StoreList from "./component/store";
import AddStore from "./component/store/pages/AddStore";
import EditStore from "./component/store/pages/EditStore";
import Roles from "./component/roles/Roles";
import Schedular from "./component/Schedular/Schedular";
import AddQuestionEditForm from "./component/checklist/allForm/editForm/AddQuestionEditForm";
import { assessmentQustionEditData } from "./component/redux/actions/AllformStore";
import AssignUser from "./component/store/pages/AssignUser";
import StoreDetails from "./component/store/pages/StoreDetails";
import UserOverview from "./component/user/overview";
import UserList from "./component/user/userList";
import AddUser from "./component/user/userList/pages/AddUser";
import UserDetails from "./component/user/userList/pages/UserDetails";
import Customer from "./component/customer";
import CustomerDetails from "./component/customer/pages/CustomerDetails";
import DataList from "./component/dataManager/dataList";
import AddList from "./component/dataManager/dataList/pages/AddList";
import ListDetails from "./component/dataManager/dataList/pages/ListDetails";
import EditList from "./component/dataManager/dataList/pages/EditList";
import CompanyCategories from "./component/dataManager/companyCategories";
import Questions from "./component/dataManager/questions";
import AddQuestion from "./component/dataManager/questions/pages/AddQuestion";
import EditQuestion from "./component/dataManager/questions/pages/EditQuestion";
import AddGroup from "./component/dataManager/questions/pages/AddGroup";
import AllFormQuestion from "./component/checklist/allForm/createFormTabs/AddQuestion";
import Department from "./component/department";
import AddDepartment from "./component/department/pages/AddDepartment";
import DepartmentDetails from "./component/department/pages/DepartmentDetails";
import EditDepartment from "./component/department/pages/EditDepartment";
import ChangePassword from "./component/user/userList/pages/ChangePassword";
import EditUser from "./component/user/userList/pages/EditUser";
import EditCompany from "./component/company/CompanyEdit";
import EditQuestionAllform from "./component/checklist/allForm/createFormTabs/EditQuestion";
import EditForm from "./component/checklist/allCheckList/EditForm";
import EditAllForm from "./component/checklist/allForm/editForm/EditAllForm";
import AddQustionEdit from "./component/checklist/allForm/createFormTabs/AddQustionEdit";
import CustomerLogs from "./component/CustomerLogs";
import CustomerLogView from "./component/CustomerLogs/pages/CustomerLogView";
import TrainingReport from "./component/lms/training";
import EditChapter from "./component/lms/allTrainingReport/pages/EditChapter";
import ProfileDetails from "./component/navigationBar/Common/UserProfile/ProfileDetails";
import AllCheckListImagesGallery from "./component/checklist/ChecklistGallery";
import MobileAccess from "./component/mobileAccess/MobileAccess";
import PerformanceOverview from "./component/checklist/ChecklistReports/PerformanceOverview";
import { default as LMSPerformanceOverview } from "./component/lms/LMSReports/PerformanceOverview";
import UserPerformanceView from "./component/checklist/ChecklistReports/ExpandedUser/UserPerformanceView";
import ViewAllStoreList from "./component/checklist/ChecklistReports/ExpandedStore/ViewAllStoreList";
import ViewAllUserList from "./component/checklist/ChecklistReports/ExpandedUser/ViewAllUserList";
import OPTable from "./component/checklist/ChecklistReports/Pages/OPTable";
import SingleStoreViewPage from "./component/checklist/ChecklistReports/ExpandedStore/SingleStoreViewPage";
import UserPermission from "./component/user/userList/pages/UserPermission";
import Products from "./component/wasteManagement/products/Index";
import BMView from "./component/BroadcastMessage/pages/BMView";
import AddMessage from "./component/BroadcastMessage/pages/AddMessage";
import DownloadList from "./component/checklist/DownloadList";
import AuditorHome from "./component/auditorLogin/AuditorHome";
import CertificateIndex from "./component/lms/auditorModules/MyCertificates/CertificateIndex";
import MyTrainingIndex from "./component/lms/auditorModules/MyTrainings/MyTrainingIndex";
import MyAssessmentsIndex from "./component/lms/auditorModules/MyAssessments/MyAssessmentsIndex";
import MyTrainingView from "./component/lms/auditorModules/MyTrainings/MyTrainingView";
import EnrollNow from "./component/lms/auditorModules/MyTrainings/EnrollNow";
import TextStepComponent from "./component/lms/auditorModules/MyTrainings/NewTrainings/TextStepComponent";
import AudioStepComponent from "./component/lms/auditorModules/MyTrainings/NewTrainings/AudioStepComponent";
import ImageStepComponent from "./component/lms/auditorModules/MyTrainings/NewTrainings/ImageStepComponent";
import PDFStepComponent from "./component/lms/auditorModules/MyTrainings/NewTrainings/PDFStepComponent";
import URLStepComponent from "./component/lms/auditorModules/MyTrainings/NewTrainings/URLStepComponent";
import SubmitAssessment from "./component/lms/auditorModules/MyAssessments/SubmitAssessment";
import VideoStepComponent from "./component/lms/auditorModules/MyTrainings/NewTrainings/VideoStepComponent";
import Disclaimer from "./component/lms/auditorModules/MyAssessments/Disclaimer";
import LMSReportView from "./component/lms/LMSReports/Pages/LMSReportView";
import TPDetails from "./component/lms/allTrainingReport/pages/TPDetails";
import { CompanyListApi } from "./component/redux/actions/CreateCompany";
import { useSelector } from "react-redux";
import { BASE_URL } from "./component/redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import { useState } from "react";
import AuditorChecklistHome from "./component/auditorLogin/AuditorChecklistHome";
import Upcoming from "./component/lms/auditorModules/Checklists/Upcoming";
import LMSAuditorHome from "./component/auditorLogin/LMSAuditorHome";
import SubmitChecklist from "./component/lms/auditorModules/Checklists/SubmitChecklist";
import ChecklistTypes from "./component/lms/auditorModules/Checklists/ChecklistTypes";
import ChecklistForms from "./component/lms/auditorModules/Checklists/ChecklistForms";
import ReviewDialog from "./component/lms/auditorModules/Checklists/ReviewDialog";
import Payment from "./component/Payment/Payment";
import PaymentHistory from "./component/Payment/PaymentHistory";
import AccountDetails from "./component/Account/AccountDetails";
import EditAccountDetails from "./component/Account/EditAccountDetails";
import { ProfileMeGetApiAction } from "./component/redux/actions/MyProfileDetailsAction";
import PaymentDetails from "./component/Payment/PaymentDetails";
import SubmittedChecklistView from "./component/lms/auditorModules/Checklists/SubmittedChecklistView";
import Chat from "./component/chats/Chat";
import ChecklistInspection from "./component/checklist/ChecklistInspection";
import LogEntries from "./component/LogEntries/LogEntries";
import AddNewLogEntry from "./component/LogEntries/AddNewLogEntry";
import AddLogQuestion from "./component/LogEntries/AddLogQuestion";
import ViewLogEntries from "./component/LogEntries/ViewLogEntries";
import ViewLogEntrySubmission from "./component/LogEntries/ViewLogEntrySubmission";
import EditLogEntrySubmission from "./component/LogEntries/EditLogEntrySubmission";
import AttendanceLog from "./component/Attendance/AttendanceLog";
import Config from "./component/Attendance/pages/Config";
import AttendanceSubmission from "./component/Attendance/pages/AttendanceSubmission";
import NewAttendance from "./component/Attendance/pages/NewAttendance";
import EditConfig from "./component/Attendance/pages/EditConfig";
import SchecularView from "./component/Schedular/pages/SchedularView";
import AnalyticalCompanyList from "./component/AnalyticalDashboard/pages/AnnalyticalCompanyList";
import AnalyticalDashboard from "./component/AnalyticalDashboard";
import AnalyticalUserList from "./component/AnalyticalDashboard/pages/AnalyticalUserList";

function App() {
  const location = useLocation();
  window.onbeforeunload = function () {
    localStorage.removeItem("assestsQustions");
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [compId, setCompId] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState(null);

  const companyList = useSelector(
    (state) =>
      state.company &&
      state.company.companylist &&
      state.company.companylist.data &&
      state.company.companylist.data.data
  );

  const profileMeGetRecords = useSelector(
    (state) =>
      state?.MyProfileDetails &&
      state?.MyProfileDetails?.progileMeGetApiRes &&
      state?.MyProfileDetails?.progileMeGetApiRes?.data &&
      state?.MyProfileDetails?.progileMeGetApiRes?.data?.data
  );

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    setCompId(companyId);
    ListCompany();
    ProfileMeGetAPI();
  }, []);

  useEffect(() => {
    if (companyList && companyList.length > 0 && compId) {
      const matchedCompany = companyList.find(
        (company) => company._id === compId
      );
      if (matchedCompany) {
        setSelectedCompany({
          company: matchedCompany,
          companyName: matchedCompany.name,
        });
      } else {
        setSelectedCompany(null);
      }
    }
  }, [companyList, compId]);

  useEffect(() => {
    if (selectedCompany) {
      setSelectedCompanyName(selectedCompany?.company?.name);
    }
  }, [selectedCompany]);

  const ListCompany = () => {
    const data = { url: BASE_URL + `profile/me/companies` };
    dispatch(CompanyListApi(data));
  };

  const ProfileMeGetAPI = () => {
    const data = { url: BASE_URL + `profile/me` };
    dispatch(ProfileMeGetApiAction(data));
  };

  return (
    <div className="App">
      {location?.pathname === "/" ||
      location?.pathname === "/signUp" ||
      location?.pathname === "/forgotPassword" ? (
        ""
      ) : (
        <SideBar />
      )}
      <Box
        className={
          location?.pathname === "/" ||
          location?.pathname === "/signUp" ||
          location?.pathname === "/forgotPassword"
            ? ""
            : "dasboad-main-content"
        }
      >
        <Routes>
          <Route
            exact
            path="/signUp"
            element={<SignUpForm title="Popprobe | Signup" />}
          />
          <Route
            exact
            path="/forgotPassword"
            element={<ForgotPassword title="Popprobe | Forgot" />}
          />
          <Route
            exact
            path="/"
            element={<LoginForm title="Popprobe | Login" />}
          />
          <Route
            exact
            path="/company"
            element={<Company title="Popprobe | Company" />}
          />
          <Route
            exact
            path="/company/create"
            element={<CreateCompany title="Popprobe | Company" />}
          />
          <Route
            exact
            path="/company-list"
            element={<CompanyList title="Popprobe | Company" />}
          />

          <Route
            exact
            path="/checklist-home"
            element={<AuditorChecklistHome title="Popprobe | Company" />}
          />
          <Route
            exact
            path="/checklist-home/upcoming"
            element={<Upcoming title="Popprobe | Company" />}
          />
          <Route
            exact
            path="/checklist-home/checklist-types"
            element={<ChecklistTypes title="Popprobe | Company" />}
          />
          <Route
            exact
            path="/checklist-home/checklist-types/view"
            element={<SubmittedChecklistView title="Popprobe | Company" />}
          />
          <Route
            exact
            path="/checklist-home/checklist-types/checklist-forms"
            element={<ChecklistForms title="Popprobe | Company" />}
          />
          <Route
            exact
            path="/checklist-home/checklist-types/checklist-forms/submit"
            element={<SubmitChecklist title="Popprobe | Company" />}
          />
          <Route
            exact
            path="/checklist-home/checklist-types/checklist-forms/submit/review"
            element={<ReviewDialog title="Popprobe | Company" />}
          />
          <Route
            exact
            path="/lms-home"
            element={<LMSAuditorHome title="Popprobe | Company" />}
          />
          <Route
            exact
            path="/auditor-home"
            element={<AuditorHome title="Popprobe | Company" />}
          />
          <Route
            exact
            path="/lms-submision"
            element={<AuditorHome title="Popprobe | Company" />}
          />
          <Route
            exact
            path="/company-edit"
            element={<EditCompany title="Popprobe | Company" />}
          />
          <Route
            exact
            path="/dashboard"
            element={<Dashboard title="Popprobe | dashboard" />}
          />
          <Route
            exact
            path="/analytical-dashboard"
            element={<AnalyticalDashboard title="Popprobe | Analytical dashboard" />}
          />
          <Route
            exact
            path="/analytical-company-list"
            element={<AnalyticalCompanyList title="Popprobe | Analytical company list" />}
          />
          <Route
            exact
            path="/analytical-user-list"
            element={<AnalyticalUserList title="Popprobe | Analytical user list" />}
          />
          <Route
            exact
            path="/checklist"
            element={<Overview title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/all-checklist"
            element={<AllCheckList title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/all-checklist/add"
            element={<AddForm title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/all-checklist/edit"
            element={<EditForm title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/all-checklist/view"
            element={<ViewForm title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/submission"
            element={<Submission title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/submission/view-form"
            element={<ViewDetailsSubmission title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/all-checklist/images"
            element={<AllCheckListImagesGallery title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/all-checklist/imagesInspection"
            element={<ChecklistInspection title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/department"
            element={<DepartmentChecklist title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/store-checklist"
            element={<StoreChecklist title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/user-checklist"
            element={<UserChecklist title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/all-form"
            element={<AllForm title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/all-form/edit-forms"
            element={<EditAddAllForm title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/all-form/add-form"
            element={<AddAllForm title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/all-form/add-form/add-question"
            element={<AllFormQuestion title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/all-form/add-form/edit-addquestion"
            element={<AddQuestionEditForm title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/all-form/add-form/edit-question"
            element={<EditQuestionAllform title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/all-form/view-form"
            element={<ViewAllForm title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/all-form/edit-form"
            element={<EditAllForm title="Popprobe | Checklist" />}
          />
          {/* checklist report  */}
          <Route
            exact
            path="/checklist/checklist-report"
            element={<PerformanceOverview title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/checklist-report/overall-performance-table"
            element={<OPTable title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/checklist-report/store-performance-view"
            element={<SingleStoreViewPage title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/checklist-report/view-all-store-list/store-performance-view"
            element={<SingleStoreViewPage title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/checklist-report/store-performance-view/user-performance-view"
            element={<UserPerformanceView title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/checklist-report/user-performance-view"
            element={<UserPerformanceView title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/checklist-report/view-all-store-list"
            element={<ViewAllStoreList title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/checklist-report/view-all-user-list"
            element={<ViewAllUserList title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/checklist-report/view-all-user-list/user-performance-view"
            element={<UserPerformanceView title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/checklist/checklist-report/user-performance-view"
            element={<UserPerformanceView title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/Overview"
            element={<OverviewLMS title="Popprobe | LMS" />}
          />
          <Route
            exact
            path="/training"
            element={<TrainingReport title="Popprobe | LMS" />}
          />
          <Route
            exact
            path="alltrainingreport"
            element={<AllTrainingReport title="Popprobe | LMS" />}
          />
          <Route
            exact
            path="/alltraining-report"
            element={<AllTrainingReport title="Popprobe | LMS" />}
          />

          {/* new lms works started  */}
          <Route
            exact
            path="/my-certificates"
            element={<CertificateIndex title="Popprobe | LMS" />}
          />
          <Route
            exact
            path="/auditor-home/my-trainings"
            element={<MyTrainingIndex title="Popprobe | LMS" />}
          />
          <Route
            exact
            path="/auditor-home/my-trainings/my-trainings-view"
            element={<MyTrainingView title="Popprobe | LMS" />}
          />

          {/* <Route
            exact
            path="/auditor-home/my-trainings/my-trainings-view/enroll-now"
            element={<EnrollNow title="Popprobe | LMS" />}
          /> */}

          <Route
            exact
            path="/auditor-home/my-trainings/my-trainings-view/newTraining-text-type"
            element={<TextStepComponent title="Popprobe | LMS" />}
          />
          <Route
            exact
            path="/auditor-home/my-trainings/my-trainings-view/newTraining-audio-type"
            element={<AudioStepComponent title="Popprobe | LMS" />}
          />
          <Route
            exact
            path="/auditor-home/my-trainings/my-trainings-view/newTraining-video-type"
            element={<VideoStepComponent title="Popprobe | LMS" />}
          />
          <Route
            exact
            path="/auditor-home/my-trainings/my-trainings-view/newTraining-pdf-type"
            element={<PDFStepComponent title="Popprobe | LMS" />}
          />
          <Route
            exact
            path="/auditor-home/my-trainings/my-trainings-view/newTraining-url-type"
            element={<URLStepComponent title="Popprobe | LMS" />}
          />
          <Route
            exact
            path="/auditor-home/my-trainings/my-trainings-view/newTraining-image-type"
            element={<ImageStepComponent title="Popprobe | LMS" />}
          />
          {/* ///////////////////MY ASSESSMENTS //////////////////////////////// */}
          <Route
            exact
            path="/auditor-home/my-assessments"
            element={<MyAssessmentsIndex title="Popprobe | LMS" />}
          />
          <Route
            exact
            // path="/auditor-home/my-assessments/submitAssessmentAuditor"
            path="/auditor-home/my-assessments/disclaimer-page/submitAssessmentAuditor"
            element={<SubmitAssessment title="Popprobe | LMS" />}
          />
          <Route
            exact
            path="/auditor-home/my-assessments/disclaimer-page"
            element={<Disclaimer title="Popprobe | LMS" />}
          />
          <Route
            exact
            path="/lms/lms-report"
            element={<LMSPerformanceOverview title="Popprobe | LMS" />}
          />
          <Route
            exact
            path="/lms/lms-report/lms-report-view"
            element={<LMSReportView title="Popprobe | LMS" />}
          />
          <Route
            exact
            path="/log-entry"
            element={<LogEntries title="Popprobe | Log Entries" />}
          />
          <Route
            exact
            path="/log-entry/view-log-entries-submissions"
            element={<ViewLogEntries title="Popprobe | View Log Entries Submissions" />}
          />
          <Route
            exact
            path="/log-entry/view-log-entry-submission"
            element={<ViewLogEntrySubmission title="Popprobe | View Log Entry Submission" />}
          />
          <Route
            exact
            path="/log-entry/edit-log-entry-submission"
            element={<EditLogEntrySubmission title="Popprobe | Edit Log Entry Submission" />}
          />
          <Route
            exact
            path="/log-entry/create"
            element={<AddNewLogEntry title="Popprobe | Log Entries" />}
          />
          <Route
            exact
            path="/log-entry/create/add-log-question"
            element={<AddLogQuestion title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/log-entry/add/edit-log-question"
            element={<AddLogQuestion title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/log-entry/edit"
            element={<AddNewLogEntry title="Popprobe | Checklist" />}
          />
          <Route
            exact
            path="/issues"
            element={<IssuesOverview title="Popprobe | Issues" />}
          />
          <Route
            exact
            path="/issues/list"
            element={<IssuesList title="Popprobe | Issues" />}
          />
          <Route
            exact
            path="/issues/list/create"
            element={<NewIssueCreate title="Popprobe | Issues" />}
          />
          <Route
            exact
            path="/issues/list/edit"
            element={<EditIssue title="Popprobe | Issues" />}
          />
          <Route
            exact
            path="/issues/list/view-details"
            element={<ViewDetails title="Popprobe | Issues" />}
          />
          <Route
            exact
            path="/observation"
            element={<ObservationList title="Popprobe | Observation" />}
          />
          <Route
            exact
            path="/observation/details"
            element={<ViewDetailsObsevation title="Popprobe | Observation" />}
          />
          <Route
            exact
            path="/broadcastMessage"
            element={<BroadcastMessage title="Popprobe | Broadcast Message" />}
          />
          <Route exact path="/chat" element={<Chat />} />
          <Route
            exact
            path="/broadcastMessage/broadcastMessageView"
            element={<BMView title="Popprobe | Broadcast Message" />}
          />
          <Route
            exact
            path="/broadcastMessage/add-message"
            element={<AddMessage title="Popprobe | Broadcast Message" />}
          />

          <Route exact path="/editdetails" element={<CompanyModal />} />
          <Route
            exact
            path="/training/add-training"
            element={<AddTraining />}
          />
          <Route
            exact
            path="/training/add-training/add-chapter"
            element={<AddChapter />}
          />
          <Route
            exact
            path="/training/add-training/edit-chapter"
            element={<EditChapter />}
          />
          <Route
            exact
            path="/alltraining-report/individual-training"
            element={<IndividualTraining />}
          />
          <Route
            exact
            path="/alltraining-report/training-report-details"
            element={<TPDetails />}
          />
          <Route
            exact
            path="/training/edit-training"
            element={<EditTraining />}
          />
          <Route
            exact
            path="/store-training"
            element={<StoreTraining title="Popprobe | Store" />}
          />
          <Route
            exact
            path="/store-training/add-store-training"
            element={<AddStoreTraining title="Popprobe | Store" />}
          />
          <Route
            exact
            path="/store-training/add-store-training/add-chapter"
            element={<AddStoreChapter title="Popprobe | Store" />}
          />
          <Route
            exact
            path="/store-training/view-details"
            element={<StoreUserTraining title="Popprobe | Store" />}
          />
          <Route
            exact
            path="/store-training/view-details/single-details"
            element={<IndividualStoreTraining title="Popprobe | Store" />}
          />
          <Route exact path="/user-training" element={<UserTraining />} />
          <Route exact path="/assessment" element={<AssessmentList />} />
          <Route
            exact
            path="/assessment/create-assessment"
            element={<AddAssessment />}
          />
          <Route exact path="/assessment/edit" element={<UpdateAssessment />} />
          <Route
            exact
            path="/assessment/edit-assessment"
            element={<EditAssessment />}
          />
          <Route
            exact
            path="/assessment/create-assessment/assessment-question"
            element={<AssessmentQuestion />}
          />
          <Route
            exact
            path="/certificate"
            element={<CertificateList title="Popprobe | Certificate" />}
          />
          <Route
            exact
            path="/certificate/add-certificate"
            element={<NewCertificate title="Popprobe | Certificate" />}
          />
          <Route
            exact
            path="/certificate/edit-certificate"
            element={<EditCertificate title="Popprobe | Certificate" />}
          />
          <Route
            exact
            path="/audit-overview"
            element={<AuditOverview title="Popprobe | Audit" />}
          />
          <Route
            exact
            path="/all-assignment"
            element={<AllAssignment title="Popprobe | Audit" />}
          />
          <Route
            exact
            path="/all-assignment/add-assignment"
            element={<AddAssignment title="Popprobe | Audit" />}
          />
          <Route
            exact
            path="/all-survey"
            element={<AllSurvey title="Popprobe | Audit" />}
          />
          <Route
            exact
            path="/all-survey/survey-details"
            element={<SurveyDetails title="Popprobe | Audit" />}
          />
          <Route
            exact
            path="/waste-management"
            element={<WasteManagement title="Popprobe | Waste Management" />}
          />
          <Route
            exact
            path="/waste-management/view-details"
            element={<WasteLogDetails title="Popprobe | Waste Management" />}
          />
          <Route
            exact
            path="/product"
            element={<Products title="Popprobe | Product" />}
          />
          <Route
            exact
            path="/store"
            element={<StoreList title="Popprobe | Store" />}
          />
          <Route
            exact
            path="/store/add-store"
            element={<AddStore title="Popprobe | Store" />}
          />
          <Route
            exact
            path="/store/edit-store"
            element={<EditStore title="Popprobe | Store" />}
          />
          <Route
            exact
            path="/store/store-details"
            element={<StoreDetails title="Popprobe | Store" />}
          />
          <Route
            exact
            path="/store/assign-user"
            element={<AssignUser title="Popprobe | Store" />}
          />
          <Route
            exact
            path="/roles"
            element={<Roles title="Popprobe | Roles" />}
          />
          <Route exact path="/user-overview" element={<UserOverview />} />
          <Route exact path="/user-list" element={<UserList />} />
          <Route exact path="/user-list/add-user" element={<AddUser />} />
          <Route
            exact
            path="/user-list/user-details"
            element={<UserDetails />}
          />
          <Route exact path="/user-list/edit-user" element={<EditUser />} />
          <Route
            exact
            path="/user-list/user-permission"
            element={<UserPermission />}
          />
          <Route
            exact
            path="/user-list/change-password"
            element={<ChangePassword />}
          />
          <Route exact path="/customer" element={<Customer />} />
          <Route
            exact
            path="/customer/customer-details"
            element={<CustomerDetails />}
          />
          <Route exact path="/data-list" element={<DataList />} />
          <Route exact path="/data-list/add-list" element={<AddList />} />
          <Route
            exact
            path="/data-list/list-details"
            element={<ListDetails />}
          />
          <Route exact path="/data-list/edit-list" element={<EditList />} />
          <Route exact path="/questions" element={<Questions />} />
          <Route
            exact
            path="/questions/add-question"
            element={<AddQuestion />}
          />
          <Route
            exact
            path="/questions/add-question-editform"
            element={<AddQustionEdit />}
          />
          <Route
            exact
            path="/questions/edit-question"
            element={<EditQuestion />}
          />
          <Route exact path="/questions/add-group" element={<AddGroup />} />
          <Route
            exact
            path="/company-category"
            element={<CompanyCategories />}
          />
          <Route exact path="/department" element={<Department />} />
          <Route exact path="/new-department" element={<AddDepartment />} />
          <Route
            exact
            path="/department/department-details"
            element={<DepartmentDetails />}
          />
          <Route
            exact
            path="/department/edit-department"
            element={<EditDepartment />}
          />
          <Route
            exact
            path="/customer-Log"
            element={<CustomerLogs title="Popprobe | CustomerLogs" />}
          />
          <Route
            exact
            path="/customer-Log/customer-Log-View"
            element={<CustomerLogView title="Popprobe | CustomerLogs" />}
          />
          <Route
            exact
            path="/my-profile-details"
            element={<ProfileDetails title="Popprobe | Company" />}
          />
          <Route
            exact
            path="/mobile-Access"
            element={<MobileAccess title="Popprobe | Signup" />}
          />
          <Route
            exact
            path="/download-list"
            element={<DownloadList title="Popprobe | Download-list" />}
          />
          <Route
            exact
            path="/scheduler"
            element={<Schedular title="Popprobe | Scheduler" />}
          />
          <Route
            exact
            path="/scheduler/details"
            element={<SchecularView title="Popprobe | Scheduler" />}
          />
          <Route
            path="/make-payment/:paymentToken"
            element={
              // profileMeGetRecords?.role?.key === "directors" ? (
              <Payment title="Popprobe | Payment" />
              // ) : (
              //   <Navigate to={"/dashboard"} />
              // )
            }
          />
          <Route
            path="/account-details"
            element={
              profileMeGetRecords?.role?.key === "directors" ? (
                <AccountDetails title="Popprobe | Account-Details" />
              ) : (
                <Navigate to={"/dashboard"} />
              )
            }
          />
          <Route
            path="/edit-account-details"
            element={
              profileMeGetRecords?.role?.key === "directors" ? (
                <EditAccountDetails title="Popprobe | Edit-Account-Details" />
              ) : (
                <Navigate to={"/dashboard"} />
              )
            }
          />
          <Route
            path="/payment_history"
            element={
              profileMeGetRecords?.role?.key === "directors" ? (
                <PaymentHistory title="Popprobe | Payment Histyory" />
              ) : (
                <Navigate to={"/dashboard"} />
              )
            }
          />
          {/* <Route
            exact
            path="/attendance-log"
            element={<AttendanceLog title="Popprobe | Attendance Log" />}
          /> */}
          <Route
            exact
            path="/attendance-log-config"
            element={<Config title="Popprobe | Attendance Log" />}
          />
          <Route
            exact
            path="/attendance-log-config/edit-config"
            element={<EditConfig title="Popprobe | Attendance Log" />}
          />
          <Route
            exact
            path="/attendance-log-submission"
            element={<AttendanceSubmission title="Popprobe | Attendance Log" />}
          />
          <Route
            exact
            path="/attendance-log-config/new-log"
            element={<NewAttendance title="Popprobe | Attendance Log" />}
          />
          <Route
            path="/payment_history/payment-details"
            element={
              profileMeGetRecords?.role?.key === "directors" ? (
                <PaymentDetails title="Popprobe | Payment Histyory" />
              ) : (
                <Navigate to={"/dashboard"} />
              )
            }
          />
        </Routes>
      </Box>
    </div>
  );
}

export default App;
