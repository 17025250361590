/** @format */

import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Card, CardContent } from "@mui/material";
import { ViewHeader } from "./Header";
import { Typography, Grid } from "@mui/material";
import Map, { Main } from "./Map";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import {
  ChecklistStoreApi,
  DetailsStoreApi,
  PendingChecklistApi,
} from "../../redux/actions/Store";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Geocode from "react-geocode";
import { Avatar } from "@mui/material";
import Store from "../../../assets/icons/svg-icons/Store.svg";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import moment from "moment";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Progressbar from "../../GlobalProgress/Progressbar";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import CircleIcon from "@mui/icons-material/Circle";

const StoreDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location?.state?.id;
  const [detailedData, setDetailedData] = React.useState();
  const [checklistByStoreData, setChecklistByStoreData] = React.useState();
  const [storeLocation, setStoreLocation] = useState("");
  const [pclData, setPendingCheckListData] = useState([]);
  const classes = useStyles();
  const [loader, setLoader] = useState(true);
  const [storeDetailsEdit, setStoreDetailsEdit] = useState([]);

  const getStoreDetails = useSelector(
    (state) =>
      state?.store &&
      state?.store?.storeDetails &&
      state?.store?.storeDetails?.data &&
      state?.store?.storeDetails?.data?.data
  );

  const getChecklistByStore = useSelector(
    (state) =>
      state.store &&
      state.store.checklistByStoreReducer &&
      state.store.checklistByStoreReducer.data &&
      state.store.checklistByStoreReducer.data.data
  );

  const pendingChecklistSelector = useSelector(
    (state) =>
      state?.store &&
      state?.store?.pendingChecklist &&
      state?.store?.pendingChecklist?.data &&
      state?.store?.pendingChecklist?.data?.data
  );
  
  useEffect(() => {
    if (getChecklistByStore) {
      setChecklistByStoreData(getChecklistByStore);
    }
  }, [getChecklistByStore]);

  useEffect(() => {
    if (pendingChecklistSelector) {
      setPendingCheckListData(pendingChecklistSelector);
    }
  }, [pendingChecklistSelector]);

  useEffect(() => {
    if (getStoreDetails) {
      // setLoader(true);
      setDetailedData(getStoreDetails);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [getStoreDetails]);

  useEffect(() => {
    setStoreDetailsEdit(getStoreDetails);
  }, [getStoreDetails]);

  const storeDetails = () => {
    const _id = localStorage.getItem("companyId");
    const storeId = id;
    const data = {
      url: BASE_URL + `stores/${storeId}?company_id=${_id}`,
    };
    dispatch(DetailsStoreApi(data));
  };

  useEffect(() => {
    storeDetails();
    checklistByStoreId();
    checklistPendingStore();
  }, []);

  const checklistByStoreId = () => {
    const _id = localStorage.getItem("companyId");
    const storeId = id;
    const data = {
      url: BASE_URL + `Checklistbystore?_id=${storeId}&company_id=${_id}`,
    };
    dispatch(ChecklistStoreApi(data));
  };

  const checklistPendingStore = () => {
    const _id = localStorage.getItem("companyId");
    const storeId = id;
    const data = {
      url: BASE_URL + `Checklistpendingstore?_id=${storeId}&company_id=${_id}`,
    };
    dispatch(PendingChecklistApi(data));
  };

  // Get address from latitude & longitude.
  Geocode.setApiKey("AIzaSyBffT7GX3BtDg1Y1DbWDuOWZtZlpozVNFA");
  Geocode.setLanguage("en");
  Geocode.setRegion("es");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();
  Geocode.fromLatLng(
    detailedData?.gps?.latitude,
    detailedData?.gps?.longitude
  ).then(
    (response) => {
      const address = response.results[0].formatted_address;
      setStoreLocation(address);
    },
    (error) => {
      console.error(error);
    }
  );
  return (
    <Box>
      <ViewHeader storeDetails={storeDetailsEdit} />
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "hidden",
          overflowY: "scroll",
          borderRadius: "8px",
          px: 1,
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        <Typography
          variant="h5"
          component="h4"
          className="storedetail-headings"
        >
          Store Information
        </Typography>
        <Card className="user-information">
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={2}
              sx={{ paddingBottom: "15px", display: "flex" }}
            >
              <Avatar
                sx={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "#F6F8FC",
                  border: "2px solid #CBD4E1",
                  margin: "auto",
                }}
              >
                <img src={Store} alt="avatar" width="100" height="100px" />
              </Avatar>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Table aria-label="simple table" className="details-table">
                <TableBody>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Store Name:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {detailedData?.name || "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Store Unique ID:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {detailedData?.uniqueId || "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Store Radius:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {detailedData?.radius || "N/A"}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Channel:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {detailedData?.channel?.title || "N/A"}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Sub Channel:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {detailedData?.sub_channel?.title || "N/A"}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Region:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {detailedData?.region?.title || "N/A"}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Sub-Region:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {detailedData?.sub_region?.title || "N/A"}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Latitude:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      <Typography variant="p" component="b">
                        {detailedData?.gps?.latitude || "N/A"}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Longitude:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      <Typography variant="p" component="b">
                        {detailedData?.gps?.longitude || "N/A"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Box className="storedetail-map">
                <Main
                  storeLocation={storeLocation}
                  detailedData={detailedData}
                />
              </Box>
            </Grid>
          </Grid>
        </Card>

        <Typography
          variant="h5"
          component="h4"
          className="storedetail-headings"
        >
          Today Checklist Submission Activity
        </Typography>
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : (
          <TableContainer component={Paper} sx={{ borderRadius: "8px" }}>
            <Table
              size="large"
              aria-label="simple table"
              className="grid-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" className="tableHead">
                    S. No.
                  </TableCell>
                  <TableCell align="center" className="tableHead">
                    Category
                  </TableCell>
                  <TableCell align="center" className="tableHead">
                    User
                  </TableCell>
                  <TableCell align="center" className="tableHead">
                    Store
                  </TableCell>
                  <TableCell align="center" className="tableHead">
                    Role
                  </TableCell>
                  <TableCell align="center" className="tableHead">
                    Created At
                  </TableCell>
                  <TableCell align="center" className="tableHead">
                    Updated At
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {checklistByStoreData && checklistByStoreData.length > 0 ? (
                  checklistByStoreData &&
                  checklistByStoreData.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell align="center">{i + 1}</TableCell>{" "}
                      <TableCell align="center">
                        <Typography variant="p" component="b">
                          {row?.category?.categoryTitle}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="p" component="p">
                          {row?.userName}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="p" component="p">
                          {row?.store?.title}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{row?.role}</TableCell>
                      <TableCell align="center">
                        {moment(row.createdAt).format("lll")}
                      </TableCell>
                      <TableCell align="center">
                        {moment(row.updatedAt).format("lll")}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={50}>
                      <SentimentVeryDissatisfiedIcon fontSize="large" />
                      <Typography variant="p" component="h2">
                        No Data
                      </Typography>
                      <p>No Today Checklist Submission Activity</p>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Typography
          variant="h5"
          component="h4"
          className="storedetail-headings"
        >
          Pending Checklist Activity
        </Typography>
        <TableContainer component={Paper} sx={{ borderRadius: "8px" }}>
          <Table size="large" aria-label="simple table" className="grid-table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S. No.</TableCell>{" "}
                <TableCell align="center">Category</TableCell>
                <TableCell align="center">User</TableCell>
                <TableCell align="center">Sub-Role</TableCell>
                <TableCell align="center">Forms Completed</TableCell>
                {/* <TableCell align="center">Progress</TableCell> */}
                {/* <TableCell align="center">User Created On</TableCell> */}
                <TableCell align="center">Checklist Created On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pclData && pclData.length > 0 ? (
                pclData &&
                pclData.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="center">{i + 1}</TableCell>{" "}
                    {/* Serial number starts from 1 */}
                    <TableCell align="center">
                      <Typography variant="p" component="p">
                        {row?.title || "N/A"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <ul>
                        {row?.Pendinguser?.map((userName, i) => 
                          <li
                            className="my-list"
                            key={i}
                            style={{
                              textAlign: "start",
                            }}
                          >
                            <CircleIcon
                              // sx={{ fontSize: 10 }}
                              className="font"
                              style={{ paddingRight: 4 }}
                            />
                            {userName?.name}
                          </li>
                        )}
                      </ul>
                    </TableCell>
                    <TableCell align="center">
                      <ul>
                        {row?.Pendinguser?.map((subRoleTitle, i) => 
                          <li
                            className="my-list"
                            key={i}
                            style={{
                              textAlign: "start",
                            }}
                          >
                            <CircleIcon
                              // sx={{ fontSize: 10 }}
                              className="font"
                              style={{ paddingRight: 4 }}
                            />
                            {subRoleTitle?.subRole}
                          </li>
                        )}
                      </ul>
                    </TableCell>
                    <TableCell align="center">
                      <ul>
                        {row?.forms?.map((formTitle, i) => 
                          <li
                            className="my-list"
                            key={i}
                            style={{
                              textAlign: "start",
                            }}
                          >
                            <CircleIcon
                              // sx={{ fontSize: 10 }}
                              className="font"
                              style={{ paddingRight: 4 }}
                            />
                            {formTitle?.title}
                          </li>
                        )}
                      </ul>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="p" component="p">
                        {moment(row?.createdAt).format("lll") ||
                          "N/A"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={50}>
                    <SentimentVeryDissatisfiedIcon fontSize="large" />
                    <Typography variant="p" component="h2">
                      No Data
                    </Typography>
                    <p>No Pending Checklist Activity</p>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default StoreDetails;
