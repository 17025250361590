import { Autocomplete, Box, Button, Card, FormControl, Grid, IconButton, InputBase, Modal, OutlinedInput, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { UsersListApi } from "../../redux/actions/AnalyticDashboard";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import PropTypes from "prop-types";
import SingleGlobalTable from "../../GlobalComponent/SingleGlobalTable";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import UpdateIcon from "@mui/icons-material/Update";
import SearchAppBar from "../../user/userList/pages/SearchButton";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const StyledInputBase = styled(InputBase)(({ theme, width }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 1),
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        background: '#ffffff',
        border: '2px solid #cbd4e1 !important',
        borderRadius: '8px',
        [theme.breakpoints.up('sm')]: {
            width: width || '12ch',
            // '&:focus': {
            //   width: '20ch',
            // },
        },
    },
}));

const downloadAllstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 500,
    height: "90vh",
    maxHeight: 600,
    overflowY: "auto",
    overflowX: "auto",
    bgcolor: "background.paper",
    borderRadius: "6px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    '@media (max-width: 600px)': {
        width: "90%",
        height: "80vh",
    }
};

const usersStatusWiseData = [
    {
        id: 1,
        title: 'Active Users',
        value: 'totalActiveUsers'
    },
    {
        id: 2,
        title: 'InActive Users',
        value: 'totalInActiveUsers'
    },
    {
        id: 3,
        title: 'Active Admins',
        value: 'activeAdmins'
    },
    {
        id: 4,
        title: 'InActive Users',
        value: 'inActiveAdmins'
    },
    {
        id: 5,
        title: 'Active Auditors',
        value: 'activeAuditors'
    },
    {
        id: 6,
        title: 'InActive Auditors',
        value: 'inActiveAuditors'
    },
    {
        id: 7,
        title: 'Active Viewers',
        value: 'activeViewers'
    },
    {
        id: 8,
        title: 'InActive Viewers',
        value: 'inActiveViewers'
    },
    {
        id: 9,
        title: 'Active Super Admins',
        value: 'activeSuperAdmins'
    },
    {
        id: 10,
        title: 'InActive Super Admins',
        value: 'inActiveSuperAdmins'
    },
    {
        id: 11,
        title: 'Active Director',
        value: 'activeDirector'
    },
    {
        id: 12,
        title: 'InActive Director',
        value: 'inActiveDirector'
    },
]

const AnalyticalUserList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    let Loader = useSelector(
        (state) =>
            state.analyticDashboard &&
            state.analyticDashboard.loading
    );

    let usersDetails = useSelector(
        (state) =>
            state.analyticDashboard &&
            state.analyticDashboard.userList
    );

    console.log("usersDetails", usersDetails);

    var pageno = localStorage.getItem("analyticalUserPageno");
    var rowPerPage = localStorage.getItem("analyticalUserRowPerPage");

    const userListFilter = localStorage.getItem("analyticUserListFilterData") || "{}";
    const analyticUserListFilterData = JSON.parse(userListFilter);

    const [page, setPage] = useState((pageno && parseInt(pageno)) || 1);
    const [rowsPerPage, setRowsPerPage] = useState(
        (rowPerPage && parseInt(rowPerPage)) || 10
    );
    const [data, setData] = useState([]);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [searchValue, setSearchValue] = useState(analyticUserListFilterData.searchValue || '');
    const [nameValue, setNameValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [companyNameValue, setCompanyNameValue] = useState('');
    const [userStatusValue, setUserStatusValue] = useState('');

    const columns = [
        {
            field: "S.No.",
            headerName: "S.No.",
            renderHeader: (params) => <strong>{"S.No"}</strong>,
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
            renderCell: (params) => {
                const index =
                    data.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
                return (
                    <div
                        style={{
                            display: "flex",
                            width: "133px",
                        }}
                    >
                        <p
                            className="text-short"
                            style={{ marginLeft: "10px", fontWeight: "normal" }} // Update fontWeight to "normal"
                        >
                            {index}
                        </p>
                    </div>
                );
            },
        },
        {
            field: "User",
            headerName: "User",
            renderHeader: () => <strong>{"User"}</strong>,
            flex: 2,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            display: "flex",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                            }}
                        >
                            <LightTooltip
                                title={`${params?.row?.first_name} ${params?.row?.last_name}`}
                            >
                                <p
                                    className="text-short"
                                    style={{
                                        color: "black",
                                        fontSize: "14px",
                                    }}
                                >
                                    {params?.row?.first_name} {params?.row?.last_name}
                                </p>
                            </LightTooltip>
                            <LightTooltip title={params?.row?.email}>
                                <p
                                    className="text-short"
                                    style={{
                                        color: "black",
                                        fontSize: "small",
                                        marginTop: "-10px",
                                    }}
                                >
                                    {params?.row?.email}
                                </p>
                            </LightTooltip>
                        </div>
                    </div>
                );
            },
        },
        {
            field: "Role",
            headerName: "Role",
            renderHeader: () => <strong>{"Role"}</strong>,
            align: "center",
            headerAlign: "center",
            flex: 1,
            valueGetter: (params) => {
                let result = [];
                if (params?.row?.role || params?.row?.role?.title) {
                    result.push(
                        params?.row?.role?.title
                            ? params?.row?.role?.title
                            : params?.row?.role
                    );
                } else {
                    result = ["N/A"];
                }
                return result.join(", ");
            },
        },
        {
            field: "Sub Role",
            headerName: "Sub Role",
            renderHeader: () => <strong>{"Sub Role"}</strong>,
            align: "center",
            headerAlign: "center",
            flex: 1,
            valueGetter: (params) => {
                let result = [];
                if (params?.row?.subRole || params?.row?.subRole?.title) {
                    result.push(
                        params?.row?.subRole?.title
                            ? params?.row?.subRole?.title
                            : params?.row?.subRole
                    );
                } else {
                    result = ["N/A"];
                }
                return result.join(", ");
            },
        },
        {
            field: "Store Name",
            headerName: "Store Name",
            renderHeader: () => <strong>{"Store Name"}</strong>,
            align: "start",
            headerAlign: "center",
            flex: 2,
            renderCell: (params) => {
                const stores = Array.isArray(params?.row?.store) ? params?.row?.store : [params?.row?.store];
                if (!stores) {
                    return null;
                }
                return (
                    <div style={{ maxHeight: 150, overflowY: "auto" }}>
                        <ul style={{ paddingInlineStart: "0px" }}>
                            {stores.map((store, index) => (
                                <LightTooltip title={store.title} key={index}>
                                    <li
                                        className="my-list-w !important"
                                        style={{ textAlign: "start" }}
                                    >
                                        <CircleIcon className="font" style={{ paddingRight: 4 }} />
                                        {store.title}
                                    </li>
                                </LightTooltip>
                            ))}
                        </ul>
                    </div>
                );
            },
        },
        {
            field: "Aadhaar",
            headerName: "Aadhaar Card",
            renderHeader: () => <strong>{"Aadhaar Card"}</strong>,
            align: "center",
            headerAlign: "center",
            flex: 1,
            maxWidth: 160,
            renderCell: (params) => {
                const identity_proof = params?.row?.identity_proof;
                if (!identity_proof?.length) {
                    return null;
                }
                return (
                    <div style={{ maxHeight: 150, overflowY: "auto" }}>
                        {identity_proof[0].maskedAadharNumber || '-'}
                    </div>
                );
            },
        },
        {
            field: "Approved",
            headerName: "Approved",
            renderHeader: () => <strong>{"Approved"}</strong>,
            align: "center",
            headerAlign: "center",
            flex: 1,
            maxWidth: 140,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            width: "133px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {params?.row.approved === true ? (
                            <DoneIcon color="success" />
                        ) : (
                            <CloseIcon color="error" />
                        )}
                    </div>
                );
            },
        },
        {
            field: "Created",
            headerName: "Created",
            align: "center",
            renderHeader: () => <strong>{"Created"}</strong>,

            headerAlign: "center",
            flex: 1,
            valueGetter: (params) => {
                let timeFormate = moment(params?.row?.createdAt).format("LTS");
                let dateFormate = moment(params?.row?.updatedAt).format("MMMM D, YYYY");
                let result = [];
                if (timeFormate && dateFormate) {
                    result.push(dateFormate, timeFormate);
                } else {
                    result = ["N/A"];
                }
                return result.join(", ");
            },
        },
    ];

    const payloadRequestAll = {
        pagination: {
            page: page,
            per_page: rowsPerPage,
        },
        status: location.state,
        search: "",
        name: "",
        email: "",
        companyName: "",
        storeId: "",
        subRole: "",
    };

    const fetchUsersListData = (status) => {
        console.log("payloadRequestAll", payloadRequestAll);
        const data = {
            url: BASE_URL + '/user/list',
            body: payloadRequestAll
        }
        console.log("data", data);

        dispatch(UsersListApi(data));
    }

    useEffect(() => {
        fetchUsersListData(location.state);
    }, [])

    useEffect(() => {
        setData(usersDetails.users);
    }, [usersDetails]);

    function TablePaginationActions(props) {
        const classes = useStyles1();
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange, showFirstButton } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className={classes.root}>
                {showFirstButton && (
                    <IconButton
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 0}
                        aria-label="first page"
                    >
                        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                )}
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };

    const handleChangePage = (event, newPage) => {
        localStorage.setItem("pageno", newPage + 1);
        setPage(newPage + 1);
        payloadRequestAll.pagination.page = newPage + 1;

        payloadRequestAll.pagination.search = '';
        fetchUsersListData();
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);

        localStorage.removeItem("pageno");
        localStorage.setItem("pageno", 1);
        localStorage.setItem("rowPerPage", parseInt(event.target.value, 10));
        payloadRequestAll.pagination.per_page = parseInt(event.target.value, 10);
        payloadRequestAll.pagination.page = 1;
        fetchUsersListData();
    };

    const handleSearch = (value) => {
        setSearchValue(value);
    };

    const handleName = (value) => {
        setNameValue(value);
    };

    const handleEmail = (value) => {
        setEmailValue(value);
    };

    const handleCompanyName = (value) => {
        setCompanyNameValue(value);
    };
    const handleUserStatusChange = (event, newValue) => {
        if (newValue) {
            console.log("newValue this ", newValue);
            setUserStatusValue(newValue);
        }
    };

    const handleApplyFilter = () => {
        payloadRequestAll.search = searchValue;
        payloadRequestAll.name = nameValue;
        payloadRequestAll.email = emailValue;
        payloadRequestAll.companyName = companyNameValue;
        payloadRequestAll.status = userStatusValue?.value || '';
        fetchUsersListData();
    };

    return (
        <Box sx={{
            height: "100%"
        }}>
            <Card className="header-card top-margin">
                <Grid
                    container
                    spacing={1}
                    className="userlist-head-maingrid"
                    display={"flex"}
                    justifyContent={"end"}
                >
                    <Stack direction="row" className="Assign-Checklist">
                        <Button
                            className="header-add cancle-hover"
                            style={{
                                height: "40px",
                                borderRadius: "8px",
                                marginTop: "3px",
                                padding: "12px 16px",
                                float: "right",
                            }}
                            onClick={(e) => setOpenFilterModal(true)}
                        >
                            <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                            Filter
                        </Button>
                    </Stack>
                </Grid>
            </Card>
            <Modal
                open={openFilterModal}
                onClose={() => setOpenFilterModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEscapeKeyDown={false}
            >
                <Box sx={{ ...downloadAllstyle, p: 0 }}>
                    <Box
                        sx={{
                            position: "sticky",
                            right: "15px",
                            top: "15px",
                            cursor: "pointer",
                            textAlign: "right",
                            padding: "0px 10px",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Box onClick={() => setOpenFilterModal(false)}>
                            <CloseIcon />
                        </Box>
                    </Box>
                    <Box sx={{ pt: 2, px: 4 }}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <label>Search</label>
                            <SearchAppBar
                                searchValue={searchValue}
                                searchOnChange={handleSearch}
                                sx={{ height: "42px", borderRadius: "8px" }}
                                width="100%"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <label>Name</label>
                            <StyledInputBase
                                name="name"
                                value={nameValue}
                                onChange={(e) => handleName(e.target.value)}
                                size="small"
                                sx={{
                                    marginLeft: "8px",
                                }}
                                width="100%"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <label>Email</label>
                            <StyledInputBase
                                name="name"
                                value={emailValue}
                                onChange={(e) => handleEmail(e.target.value)}
                                size="small"
                                sx={{
                                    marginLeft: "8px",
                                }}
                                width="100%"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <label>Company Name</label>
                            <StyledInputBase
                                name="name"
                                value={companyNameValue}
                                onChange={(e) => handleCompanyName(e.target.value)}
                                size="small"
                                sx={{
                                    marginLeft: "8px",
                                }}
                                width="100%"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <label>
                                User Status
                            </label>
                            <Autocomplete
                                // options={
                                //   checkList && checkList.length > 0 ? checkList : []
                                // }
                                options={usersStatusWiseData}
                                getOptionLabel={(option) => option?.title || ""}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label=""
                                        size="small"
                                        placeholder="Select Checklist"
                                        sx={{ borderRadius: "8px", marginLeft: "8px", }}
                                        onBlur={() => {
                                            if (!params.inputProps.value) {
                                                handleClearSelection();
                                            }
                                        }}
                                    />
                                )}
                                value={userStatusValue}
                                onChange={handleUserStatusChange}
                                getOptionSelected = {(option, value) => option === value}
                                renderOption={(props, option) => (
                                    <li
                                        {...props}
                                        style={{
                                            color: option.expired ? "#eb4f4f" : "",
                                        }}
                                        key={option.id}
                                    >
                                        {option.title}
                                    </li>
                                )}
                                clearable
                                // onClear={handleClearSelection}
                            />
                        </FormControl>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            position: "fixed",
                            bottom: 0,
                            width: "100%",
                            gap: "30px",
                            px: 3,
                            pt: 1,
                            pb: 2,
                            background: "#fff",
                        }}
                    >
                        <Button
                            className="cancle-button cancle-hover"
                            style={{
                                height: "46px",
                                padding: "12px 16px",
                                width: "47%",
                                background: "#fff",
                            }}
                            onClick={() => {
                                setSearchValue("")
                                setNameValue("")
                                setEmailValue("")
                                setCompanyNameValue("")
                                setUserStatusValue("")
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            className="header-add cancle-hover"
                            style={{
                                height: "46px",
                                padding: "12px 16px",
                                width: "47%",
                            }}
                            onClick={() => handleApplyFilter()}
                        >
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <SingleGlobalTable
                data={data}
                columns={columns}
                totalCount={usersDetails?.paginate?.total_item || 0}
                loading={Loader}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </Box>
    )
}

export default AnalyticalUserList;
// fname
// lnma
// email
// role
// company name
