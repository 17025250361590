import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch } from 'react-redux';
import { BASE_URL } from '../../../redux/apiConfig/BaseUrl';
import { ListUserApi } from '../../../redux/actions/User';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  zIndex:1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme,width }) => ({
  color: 'inherit',
  width: "100%",
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    background:'#ffffff',
    border:'2px solid #cbd4e1 !important',
    borderRadius:'8px',
    [theme.breakpoints.up('sm')]: {
      width: width ||'12ch',
      // '&:focus': {
      //   width: '20ch',
      // },
    },
  },
}));

export default function SearchAppBar({ searchOnChange, searchValue, handleKeyDown,width }) {

  console.log("width",width)
//   const dispatch = useDispatch();
// const [searchData, setSearchData] = useState("");

  // const data = {
  //   body: {
  //     "q": searchData,
  //   }
  // }

// const data = {
//   body: {
//     "q": searchData,
//   }
// }
// const listUser = () => {
//   const id = localStorage.getItem('companyId')
//   data.url = BASE_URL + `users/index?company_id=${id}`
//   dispatch(ListUserApi(data))
// }

// const handleChangeSearch = (event) => {
//   setSearchData(event.target.value);
//   listUser();
// };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Search className='search-bar'>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search"
          inputProps={{ 'aria-label': 'search' }}
          value={searchValue}
          onChange={(e) => searchOnChange(e.target.value)}
          onKeyDown={handleKeyDown}
          width={width}
        />
      </Search>
    </Box>
  );
}