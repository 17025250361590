/** @format */

import React, { useContext, useEffect, useState } from "react";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { AuditorContext } from "../../../../context/auditorContext";
import InfoIcon from "@mui/icons-material/Info";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import UploadIcon from "@mui/icons-material/Upload";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import VideocamIcon from "@mui/icons-material/Videocam";
import ImageViewer from "react-simple-image-viewer";

import { useRef } from "react";
import * as moment from "moment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ReplayIcon from "@mui/icons-material/Replay";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";
import CustomDialog from "./CustomDialog";
import ReviewDialog from "./ReviewDialog";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AddCommentIcon from "@mui/icons-material/AddComment";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  AuditorChecklistSubmissionDataSuccess,
  GenerateSignedUrlApi,
  QueueChecklistAction,
  SubmitChecklistAction,
  UploadMediaApi,
} from "../../../redux/actions/Checklist";
import { useSelector } from "react-redux";
import AWS from "aws-sdk";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import * as buffer from "buffer";
import FormHelperText from "@mui/material/FormHelperText";
import ImagePreviewModal from "./ImagePreviewModal";
import axios from "axios";

window.Buffer = buffer.Buffer;

const getPresignedUrl = async (fileName, fileType, fileSize) => {
  try {
    const companyId = localStorage.getItem("companyId");
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("No token found in local storage.");
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.post(
      `${BASE_URL}generate/signed/url?company_id=${companyId}`,
      {
        fileName: fileName,
        contentType: fileType,
        fileSize: fileSize,
      },
      { headers }
    );

    console.log("Presigned URL response:", response.data.data.presignedUrl);

    return response.data.data.presignedUrl;
  } catch (error) {
    console.error("Failed to generate presigned URL:", error);
    throw error;
  }
};

const uploadFile = async (file) => {
  const S3_BUCKET = "popprobe-saas";
  const REGION = "us-west-2";

  AWS.config.update({
    // accessKeyId: "AKIAIAWD4CEY36LXX6AQ",
    // secretAccessKey: "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",
    accessKeyId: "AKIA2FJQRJLHQ6JQCNN5",
    secretAccessKey: "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",
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  var buf = buffer.Buffer.from(
    file.file.replace(/^data:image\/\w+;base64,/, ""),
    "base64"
  );
  var data = {
    Key: `${file.company}/${file.name}`,
    // Key: file.name,
    Body: buf,
    ContentEncoding: "base64",
    ContentType: "image/jpeg",
  };

  const params = {
    Bucket: S3_BUCKET,
    Key: file.name,
    Body: file.file,
  };

  var upload = s3
    .putObject(data)
    .on("httpUploadProgress", (evt) => {})
    .promise();

  await upload.then((err, data) => {
    console.log(err);
  });
};

const uploadMedia = async (file) => {
  const S3_BUCKET = "popprobe-saas";
  const REGION = "us-west-2";

  AWS.config.update({
    // accessKeyId: "AKIAIAWD4CEY36LXX6AQ",
    // secretAccessKey: "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",
    accessKeyId: "AKIA2FJQRJLHQ6JQCNN5",
    secretAccessKey: "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",
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const data = {
    Bucket: S3_BUCKET,
    Key: `${file.company}/${file.name}`,
    // Key: file.name,
    Body: file.file,
  };

  var upload = s3
    .putObject(data)
    .on("httpUploadProgress", (evt) => {})
    .promise();

  await upload.then((err, data) => {
    console.log(err);
  });
};

const uploadVideo = async (file) => {
  const S3_BUCKET = "popprobe-saas";
  const REGION = "us-west-2";

  AWS.config.update({
    // accessKeyId: "AKIAIAWD4CEY36LXX6AQ",
    // secretAccessKey: "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",
    accessKeyId: "AKIA2FJQRJLHQ6JQCNN5",
    secretAccessKey: "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",
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const fileBlob = await fetch(file.file).then((r) => r.blob());

  const data = {
    Bucket: S3_BUCKET,
    Key: `${file.company}/${file.name}`,
    // Key: file.name,
    Body: fileBlob,
  };

  var upload = s3
    .putObject(data)
    .on("httpUploadProgress", (evt) => {})
    .promise();

  await upload.then((err, data) => {
    console.log(err);
  });
};

const handleFileUpload = async (event) => {
  const file = event.target.files[0];
  debugger;

  await fetch(file)
    .then((r) => r.blob())
    .then((blob) => console.log("blob", blob));
};

const SubmitChecklist = () => {
  let { storeTitle } = useContext(AuditorContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [startTime, setStartTime] = useState("");
  const [selectedRemainingData, setSelectedRemainingData] = useState(null);
  const [formData, setFormData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileTitles, setFileTitles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const datePickerRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateAsDateTime, setStartDateAsDateTime] = useState(null);
  const [endDateAsDateTime, setEndDateAsDateTime] = useState(null);
  const [selectedTimeForTime, setSelectedTimeForTime] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [timerValue, setTimerValue] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);
  const [timerInterval, setTimerInterval] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [isReviewMode, setIsReviewMode] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [isSelfieDialogOpen, setSelfieDialogOpen] = useState(false);
  const [isVideoIconClicked, setVideoIconClicked] = useState(false);
  const [captureImage, setCaptureImage] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [isViewImageDialogOpen, setViewImageDialogOpen] = useState(false);
  const [captureImageIndex, setCaptureImageIndex] = useState("");
  const fileInputRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [isSubmissionInitiated, setIsSubmissionInitiated] = useState(false);
  const [receivedImageStatus, setreceivedImageStatus] = useState("");
  const [receivedSubmissionID, setreceivedSubmissionID] = useState("");
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isImages, setIsImages] = useState([]);

  // const uploadFileToS3 = async (file) => {
  //   try {
  //     console.log("Uploading file:", file);

  //     // Retrieve presigned URL
  //     const presignedUrl = await getPresignedUrl(
  //       file.name,
  //       file.type,
  //       file.size
  //     );

  //     if (!presignedUrl) {
  //       throw new Error("Presigned URL is undefined.");
  //     }

  //     // Upload file to S3
  //     const response = await axios.put(presignedUrl, file, {
  //       headers: {
  //         "Content-Type": file.type, // Set only the content type
  //       },
  //       onUploadProgress: (progressEvent) => {
  //         const progress = Math.round(
  //           (progressEvent.loaded * 100) / progressEvent.total
  //         );
  //         console.log(`Upload Progress: ${progress}%`);
  //       },
  //     });

  //     console.log("Upload response:", response);

  //     // Show success toast
  //     toast.success("File uploaded successfully!", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   } catch (error) {
  //     if (error.response) {
  //       console.error(
  //         "Server error:",
  //         error.response.status,
  //         error.response.data
  //       );
  //       toast.error(`Server Error: ${error.response.status}`);
  //     } else if (error.request) {
  //       console.error("No response from server:", error.request);
  //       toast.error("No response from server. Please try again.");
  //     } else {
  //       console.error("Error:", error.message);
  //       toast.error("An unexpected error occurred.");
  //     }
  //   }
  // };

  const getMimeTypeFromExtension = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    const mimeTypes = {
      png: "image/png",
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      gif: "image/gif",
      bmp: "image/bmp",
      tiff: "image/tiff",
      webp: "image/webp",
    };
    return mimeTypes[extension] || "application/octet-stream"; // Default if unknown
  };

  const uploadFileToS3 = async (file) => {
    console.log("File object received in uploadFileToS3:", file);
    
    const fileName = file.name || `file-${Date.now()}`; // Fallback name
    const fileType = file.type || "application/octet-stream"; // Fallback type
    const fileSize = file.size || 0;
  
    console.log("Uploading with:", { fileName, fileType, fileSize });
  
    try {
      const presignedUrl = await getPresignedUrl(fileName, fileType, fileSize);
      console.log("Presigned URL:", presignedUrl);
  
      if (!presignedUrl) {
        throw new Error("Presigned URL is undefined.");
      }
  
      const response = await axios.put(presignedUrl, file, {
        headers: {
          "Content-Type": fileType,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(`Upload Progress: ${progress}%`);
        },
      });
  
      console.log("Upload response:", response);
      toast.success("File uploaded successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      if (error.response) {
        console.error("Server error:", error.response.status, error.response.data);
        toast.error(`Server Error: ${error.response.status}`);
      } else if (error.request) {
        console.error("No response from server:", error.request);
        toast.error("No response from server. Please try again.");
      } else {
        console.error("Error:", error.message);
        toast.error("An unexpected error occurred.");
      }
    }
  };
  

  const supportedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "video/mp4",
    "video/webm",
    "video/ogg",
    "audio/mpeg",
    "audio/wav",
    "audio/ogg",
    "application/octet-stream",
  ];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (supportedFileTypes.includes(file.type)) {
        uploadFileToS3(file);
      } else {
        toast.error(
          "Unsupported file type. Please upload a valid image, video, or audio file.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
    setIsImages([]);
  };

  const ProfileDetailData = useSelector(
    (state) =>
      state.ProfileDetailData &&
      state.ProfileDetailData.ProfileData &&
      state.ProfileDetailData.ProfileData.data &&
      state.ProfileDetailData.ProfileData.data
  );

  const auditorChecklistSubmissionData = useSelector(
    (state) =>
      state.checklist && state.checklist?.auditorChecklistSubmissionData
  );
  console.log("auditorChecklistSubmissionData", auditorChecklistSubmissionData);

  const uploadSubmissionMedia = useSelector(
    (state) => state.checklist && state.checklist.uploadSubmissionMedia
  );

  const profileData = useSelector(
    (state) => state.ProfileDetailData && state.ProfileDetailData.ProfileData
  );

  const indivisualFormData = localStorage.getItem("IndivisualFormData");
  const formInfo = JSON.parse(indivisualFormData);
  const checklistData = localStorage.getItem("selectedRemainingData");
  const assignmentData = JSON.parse(checklistData);
  const companyId = localStorage.getItem("companyId");
  const companyName = localStorage.getItem("companyName");
  const auditorSelectedStore = localStorage.getItem("auditorSelectedStore");
  const storeDetails = JSON.parse(auditorSelectedStore);
  const [submissionDyData, setSubmissionDyData] = useState([]);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const openSelfiePopup = () => {
    setSelfieDialogOpen(true);
    setVideoIconClicked(false);
  };

  const openQuestionImgCapturePopup = (id) => {
    setSelfieDialogOpen(true);
    setCaptureImage(true);
    setCaptureImageIndex(id);
    setVideoIconClicked(false);
  };
  console.log("storeDetails", storeDetails);
  const openCapturedImgViewPopup = (id) => {
    setViewImageDialogOpen(true);
    setCaptureImageIndex(id);
  };

  const closeSelfieDialog = () => {
    setSelfieDialogOpen(false);
    setCapturedImage(null);
  };

  const closeViewImageDialog = () => {
    setViewImageDialogOpen(false);
    setCapturedImage(null);
  };

  const handleAttachSelfieImage = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageSrc = e.target.result;
        setCapturedImage(imageSrc);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOkButtonClick = () => {};

  useEffect(() => {
    if (auditorChecklistSubmissionData?.data) {
      setSubmissionDyData(auditorChecklistSubmissionData?.data?.data);
    }
  }, [auditorChecklistSubmissionData?.data]);
  console.log("submissionDyData", submissionDyData);

  useEffect(() => {
    const storedData = localStorage.getItem("IndivisualFormData");
    const parsedData = JSON.parse(storedData);
    setSelectedRemainingData(parsedData);
  }, []);

  useEffect(() => {
    const localStart = localStorage.getItem(
      "startTime_" + selectedRemainingData?._id
    );
    if (localStart) {
      setStartTime(localStart);
    } else {
      localStorage.setItem(
        "startTime_" + selectedRemainingData?._id,
        moment().format()
      );
      setStartTime(moment().format());
    }
  }, [selectedRemainingData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowLoader(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const storedAnswers = localStorage.getItem(
      "answeredQuestions_" + selectedRemainingData?._id
    );
    const parsedAnswers = JSON.parse(storedAnswers);
    if (parsedAnswers) {
      setQuestionData(parsedAnswers);
      dispatch(AuditorChecklistSubmissionDataSuccess(parsedAnswers));
    } else {
      setQuestionData(selectedRemainingData?.questions || []);
    }
  }, [selectedRemainingData]);

  useEffect(() => {
    if (submissionDyData) {
      setreceivedSubmissionID(submissionDyData?._id);
      setreceivedImageStatus(submissionDyData?.imageStatus);
    }
  }, [submissionDyData]);

  const [showImageIcon, setShowImageIcon] = useState({});
  const handleFormChange = (e, questionIndex) => {
    const fieldName = e.target.name;
    const updatedFormData = {
      ...formData,
      [fieldName]: e.target.value,
    };
    const modifiedQuestionData = [...questionData];
    if (modifiedQuestionData[questionIndex]?.type?.key === "single_select") {
      const answer = modifiedQuestionData[
        questionIndex
      ]?.attached_list?.items.filter((ans) => ans.title === e.target.value);

      modifiedQuestionData[questionIndex] = {
        ...modifiedQuestionData[questionIndex],
        currentAnswer: {
          type: modifiedQuestionData[questionIndex]?.type?.key,
          answer: {
            title: e.target.value,
            id: answer[0] && answer[0].id,
            option_score: modifiedQuestionData[questionIndex]?.question_score,
          },
          startTime: startTime,
          endTime: moment().format(),
          gps: null,
        },
      };
    } else if (modifiedQuestionData[questionIndex]?.type?.key === "datetime") {
      modifiedQuestionData[questionIndex] = {
        ...modifiedQuestionData[questionIndex],
        value: e.target.value,
        currentAnswer: {
          type: modifiedQuestionData[questionIndex]?.type?.key,
          answer: {
            date: moment(e.target.value?.$d).format("YYYY-MM-DD"),
            time: moment(e.target.value?.$d).format("HH:mm:ss"),
            option_score: modifiedQuestionData[questionIndex]?.question_score,
          },
          startTime: startTime,
          endTime: moment().format(),
          gps: null,
        },
      };
    } else if (modifiedQuestionData[questionIndex]?.type?.key === "time") {
      modifiedQuestionData[questionIndex] = {
        ...modifiedQuestionData[questionIndex],
        value: e.target.value,
        currentAnswer: {
          type: modifiedQuestionData[questionIndex]?.type?.key,
          answer: {
            title: modifiedQuestionData[questionIndex]?.type?.title,
            value: moment(e.target.value?.$d).format("HH:mm:ss"),
            option_score: modifiedQuestionData[questionIndex]?.question_score,
          },
          startTime: startTime,
          endTime: moment().format(),
          gps: null,
        },
      };
    } else if (modifiedQuestionData[questionIndex]?.type?.key === "file") {
      var filename = e.target.value.name.substring(
        0,
        e.target.value.name.lastIndexOf(".")
      );

      const pos = e.target.value.name.lastIndexOf(".");

      var modifiedFilename = `${filename}-${Date.now()}.${e.target.value.name.slice(
        pos + 1
      )}`;

      const file = e.target.value;
      const medias = [];

      if (
        auditorChecklistSubmissionData[questionIndex]?.media?.[0]?.path?.name
      ) {
        medias.push(...auditorChecklistSubmissionData[questionIndex]?.media);
      }

      medias.push({
        path: e.target.value,
        fileName: modifiedFilename,
        originalname: modifiedFilename,
        key: modifiedFilename,
        name: modifiedFilename,
        fileType: e.target.value.name.slice(pos + 1),
        contentType: file.type,
        addedBy: ProfileDetailData._id,
        company: companyId,
        size: file.size,
      });

      if (
        auditorChecklistSubmissionData[questionIndex]?.media?.[0]?.path?.name
      ) {
        modifiedQuestionData[questionIndex] = {
          ...modifiedQuestionData[questionIndex],
          media: medias,
          value: [auditorChecklistSubmissionData.value, file],
          currentAnswer: {
            type: modifiedQuestionData[questionIndex]?.type?.key,
            answer: {
              answer: [
                ...auditorChecklistSubmissionData[questionIndex]?.currentAnswer
                  ?.answer?.answer,
                modifiedFilename,
              ],
              option_score: modifiedQuestionData[questionIndex]?.question_score,
            },
            startTime: startTime,
            endTime: moment().format(),
            gps: null,
          },
        };
      } else {
        modifiedQuestionData[questionIndex] = {
          ...modifiedQuestionData[questionIndex],
          value: [file],
          media: medias,
          currentAnswer: {
            type: modifiedQuestionData[questionIndex]?.type?.key,
            answer: {
              answer: [modifiedFilename],
              option_score: modifiedQuestionData[questionIndex]?.question_score,
            },
            startTime: startTime,
            endTime: moment().format(),
            gps: null,
          },
        };
      }
    } else {
      // This else condition is for boolean and few others
      const selectedAnswer = e.target.value;
      const optionScore =
        selectedAnswer === "Yes" || selectedAnswer === "Acceptable" ? 1 : 0;

      modifiedQuestionData[questionIndex] = {
        ...modifiedQuestionData[questionIndex],
        currentAnswer: {
          type: modifiedQuestionData[questionIndex]?.type?.key,
          answer: {
            title: modifiedQuestionData[questionIndex]?.type?.title,
            value: e.target.value,
            // option_score: modifiedQuestionData[questionIndex]?.question_score,
            option_score: optionScore,
          },
          startTime: startTime,
          endTime: moment().format(),
          gps: null,
        },
      };
    }
    // console.log("updatedFormData", updatedFormData);
    if (selectedRemainingData && selectedRemainingData?._id) {
      localStorage.setItem(
        "answeredQuestions_" + selectedRemainingData?._id,
        JSON.stringify(modifiedQuestionData)
      );
      dispatch(AuditorChecklistSubmissionDataSuccess(modifiedQuestionData));
    }
    setFormData(updatedFormData);
    updateAnsweredQuestions(fieldName, e.target.value, questionIndex);

    setQuestionData(modifiedQuestionData);
  };

  const handleFormCommentChange = (e, questionIndex) => {
    const fieldName = e.target.name;
    const updatedFormData = {
      ...formData,
      [fieldName]: e.target.value,
    };
    const modifiedQuestionData = [...questionData];
    modifiedQuestionData[questionIndex] = {
      ...modifiedQuestionData[questionIndex],
      comment: e.target.value,
    };
    if (selectedRemainingData && selectedRemainingData?._id) {
      localStorage.setItem(
        "answeredQuestions_" + selectedRemainingData?._id,
        JSON.stringify(modifiedQuestionData)
      );
      dispatch(AuditorChecklistSubmissionDataSuccess(modifiedQuestionData));
    }
    setFormData(updatedFormData);
    updateAnsweredQuestions(fieldName, e.target.value, questionIndex);

    setQuestionData(modifiedQuestionData);
  };

  const handleFormVideo = (e) => {
    const modifiedQuestionData = [...questionData];
    const videos = [];
    if (modifiedQuestionData[captureImageIndex]?.video) {
      videos.push(...modifiedQuestionData[captureImageIndex]?.video);
    }

    const fileName = `VIDPOPPROBE_${selectedRemainingData.title
      .substring(0, 3)
      .trim()}_${profileData?.data?.first_name?.substring(0, 3).trim()}_${
      captureImageIndex + 1
    }_${modifiedQuestionData[captureImageIndex].title
      .substring(0, 3)
      .trim()}_${moment().format("YYYY-MM-DD-HH-mm-ss")}-${Date.now()}`;

    videos.push({
      path: e.path,
      fileName: fileName,
      originalname: fileName,
      key: fileName,
      name: fileName,
      fileType: "VIDEO",
      contentType: "video/webm",
      addedBy: ProfileDetailData._id,
      company: companyId,
      size: 10020,
    });
    modifiedQuestionData[captureImageIndex] = {
      ...modifiedQuestionData[captureImageIndex],
      video: videos,
    };
    if (selectedRemainingData && selectedRemainingData?._id) {
      localStorage.setItem(
        "answeredQuestions_" + selectedRemainingData?._id,
        JSON.stringify(modifiedQuestionData)
      );
      dispatch(AuditorChecklistSubmissionDataSuccess(modifiedQuestionData));
    }
    console.log("modifiedQuestionData", modifiedQuestionData);
    setQuestionData(modifiedQuestionData);
  };

  const handleFormImage = (e) => {
    const modifiedQuestionData = [...questionData];
    const images = [];
    if (modifiedQuestionData[captureImageIndex]?.image) {
      images.push(...modifiedQuestionData[captureImageIndex]?.image);
    }

    var base64str = e.substring(e.indexOf(",") + 1);
    var decoded = atob(base64str);

    const fileName = `IMGPOPPROBE_${selectedRemainingData.title
      .substring(0, 3)
      .trim()}_${profileData?.data?.first_name?.substring(0, 3).trim()}_${
      captureImageIndex + 1
    }_${modifiedQuestionData[captureImageIndex].title
      .substring(0, 3)
      .trim()}_${moment().format("YYYY-MM-DD-HH-mm-ss")}-${Date.now()}`;

    images.push({
      path: e,
      fileName: fileName,
      originalname: fileName,
      key: fileName,
      name: fileName,
      fileType: "IMAGE",
      contentType: "image/jpeg",
      addedBy: ProfileDetailData._id,
      company: companyId,
      size: decoded.length,
    });
    modifiedQuestionData[captureImageIndex] = {
      ...modifiedQuestionData[captureImageIndex],
      image: images,
    };
    if (selectedRemainingData && selectedRemainingData?._id) {
      localStorage.setItem(
        "answeredQuestions_" + selectedRemainingData?._id,
        JSON.stringify(modifiedQuestionData)
      );
      dispatch(AuditorChecklistSubmissionDataSuccess(modifiedQuestionData));
    }
    setQuestionData(modifiedQuestionData);
  };

  const removeFormImage = (e, isImageDelete) => {
    const modifiedQuestionData = [...auditorChecklistSubmissionData];
    let images = [];
    let videos = [];
    if (isImageDelete) {
      images = modifiedQuestionData[captureImageIndex]?.image.filter(
        (uploadedFile) => uploadedFile.fileName !== e
      );
      modifiedQuestionData[captureImageIndex] = {
        ...modifiedQuestionData[captureImageIndex],
        image: images,
      };
    } else {
      videos = modifiedQuestionData[captureImageIndex]?.video.filter(
        (uploadedFile) => uploadedFile.fileName !== e
      );
      modifiedQuestionData[captureImageIndex] = {
        ...modifiedQuestionData[captureImageIndex],
        video: videos,
      };
    }

    if (selectedRemainingData && selectedRemainingData?._id) {
      localStorage.setItem(
        "answeredQuestions_" + selectedRemainingData?._id,
        JSON.stringify(modifiedQuestionData)
      );
      setQuestionData(modifiedQuestionData);
      dispatch(AuditorChecklistSubmissionDataSuccess(modifiedQuestionData));
    }
  };

  const handleReview = () => {
    localStorage.setItem("answeredQuestions", JSON.stringify(questionData));

    if (!isReviewMode) {
      setIsReviewMode(true);
    }
  };

  const validateQuestionsAnswered = () => {
    for (let i = 0; i < questionData.length; i++) {
      const question = questionData[i];
      if (question.type.validations && question.type.validations.length > 0) {
        const cameraRule = question.type.validations.find(
          (validation) => validation.key === "image"
        );
        const videoRule = question.type.validations.find(
          (validation) => validation.key === "video"
        );
        const cameraOptionalRule = question.type.validations.find(
          (validation) => validation.key === "image_optional"
        );
        const videoOptionalRule = question.type.validations.find(
          (validation) => validation.key === "video_optional"
        );

        if (cameraRule && cameraRule.answer[0].value) {
          if (
            cameraOptionalRule.answer[1].title === "No" &&
            cameraOptionalRule.answer[1].value
          ) {
            if (!question.image || question.image.length === 0) {
              toast.error(`Capture a photo for question ${i + 1} first!`);
              return false;
            }
          }
        }
        if (videoRule && videoRule.answer[0].value) {
          if (
            videoOptionalRule.answer[1].title === "No" &&
            videoOptionalRule.answer[1].value
          ) {
            if (!question.video || question.video.length === 0) {
              toast.error(`Capture a video for question ${i + 1} first!`);
              return false;
            }
          }
        }
      }

      switch (question.type.key) {
        case "boolean":
          if (
            question.currentAnswer === undefined ||
            question.currentAnswer === null
          ) {
            toast.error(`Select an answer for question ${i + 1} first!`);
            return false;
          }
          break;

        case "text":
          if (
            typeof question.currentAnswer === "undefined" ||
            question.currentAnswer === null ||
            (typeof question.currentAnswer === "string" &&
              question.currentAnswer.trim() === "")
          ) {
            toast.error(`Answer question ${i + 1} first!`);
            return false;
          }
          if (question?.currentAnswer?.answer?.value === "") {
            toast.error(`Answer question ${i + 1} first!`);
            return false;
          }
          break;

        case "number":
          if (
            question.currentAnswer === undefined ||
            (typeof question.currentAnswer === "string" &&
              question.currentAnswer.trim() === "")
          ) {
            toast.error(`Answer question ${i + 1} first!`);
            return false;
          }
          break;

        case "single_select":
          if (
            question.currentAnswer === undefined ||
            question.currentAnswer === null
          ) {
            toast.error(`Select an answer for question ${i + 1} first!`);
            return false;
          }
          break;

        case "multi_select":
          if (
            question.currentAnswer === undefined ||
            question.currentAnswer === null ||
            (Array.isArray(question.currentAnswer) &&
              question.currentAnswer.length === 0)
          ) {
            toast.error(`Select an answer for question ${i + 1} first!`);
            return false;
          }
          break;

        case "file":
          if (
            auditorChecklistSubmissionData[i]?.currentAnswer === null ||
            auditorChecklistSubmissionData[i]?.currentAnswer === undefined ||
            auditorChecklistSubmissionData?.[i]?.value[0]?.name === undefined
          ) {
            toast.error(`Attach a file for question ${i + 1} first!`);
            return false;
          }
          break;

        case "date":
          if (
            question.currentAnswer === undefined ||
            question.currentAnswer === null
          ) {
            toast.error(`Provide a valid date for question ${i + 1} first!`);
            return false;
          }
          break;

        case "datetime":
          if (
            question.currentAnswer === undefined ||
            question.currentAnswer === undefined
          ) {
            toast.error(
              `Provide a valid date and time for question ${i + 1} first!`
            );
            return false;
          }
          break;

        case "time":
          if (
            question.currentAnswer === undefined ||
            question.currentAnswer === undefined
          ) {
            toast.error(`Provide a valid time for question ${i + 1} first!`);
            return false;
          }
          break;

        case "timer":
          if (
            question.currentAnswer === undefined ||
            question.currentAnswer === undefined
          ) {
            toast.error(`Start the timer for question ${i + 1} first!`);
            return false;
          }
          break;

        case "image":
          if (!question.image) {
            toast.error(`Capture a photo for question ${i + 1} first!`);
            return false;
          }
          break;

        default:
          break;
      }

      for (let i = 0; i < question?.rules?.length; i++) {
        if (question?.rules?.[i].data.condition.key === "=") {
          if (
            question?.rules?.[i]?.subType.title === "QuestionImage" &&
            question?.currentAnswer?.answer?.value ===
              question?.rules?.[i]?.data.answer.title
          ) {
            if (!question.image) {
              toast.error(`Capture a photo for question ${i + 1} first!`);
              return false;
            }
          }
          if (
            question?.rules?.[i]?.subType.title === "QuestionComment" &&
            question?.currentAnswer?.answer?.value ===
              question?.rules?.[i]?.data.answer.title
          ) {
            if (!question.comment) {
              toast.error(`Add comment for question ${i + 1} first!`);
              return false;
            }
          }
        }
      }
    }
    return true;
  };

  console.log("question", questionData);

  const handleVideoRecording = (questionIndex) => {
    setVideoIconClicked(true);
    setSelfieDialogOpen(true);
    setCaptureImageIndex(questionIndex);
  };

  const takeSelfieSubmit = () => {
    setIsSubmissionInitiated(true);
    updateAnsweredQuestions(formData);
    openSelfiePopup();
    setCaptureImage(false);
  };

  useEffect(() => {}, [isSubmissionInitiated]);

  const updateAnsweredQuestions = (fieldName, answer, questionIndex) => {
    const updatedAnswers = answeredQuestions.map((item, index) => {
      if (index === questionIndex) {
        return {
          ...item,
          [fieldName]: answer,
        };
      }
      return item;
    });
    setAnsweredQuestions(updatedAnswers);
  };

  const booleanOptions = [
    "Yes",
    "No",
    "Acceptable",
    "Not Acceptable",
    "Discarded",
    "Returned",
    "Not Applicable",
  ];

  const startTimer = () => {
    if (!timerRunning) {
      setTimerRunning(true);
      setTimerInterval(
        setInterval(() => {
          setTimerValue((prevValue) => prevValue + 1);
        }, 1000)
      );
    }
  };

  const pauseTimer = (questionIndex) => {
    setTimerRunning(false);
    clearInterval(timerInterval);
    handleFormChange(
      {
        target: {
          name: `question_${questionIndex + 1}_timer_answer`,
          value: timerValue,
        },
      },
      questionIndex
    );
  };

  const resetTimer = () => {
    setTimerValue(0);
    setTimerRunning(false);
    clearInterval(timerInterval);
    setTimerInterval(null);
  };

  const renderBooleanField = (question, questionIndex) => {
    const fieldName = `question_${questionIndex + 1}_answer`;
    const selectedAnswer = question?.currentAnswer?.answer?.value || "";
    const optionScore =
      selectedAnswer === "Yes" || selectedAnswer === "Acceptable" ? 1 : 0;
    const isAnswerGivenBoolean =
      typeof selectedAnswer === "string" && selectedAnswer !== "";

    return (
      <FormControl component="fieldset">
        <RadioGroup
          style={{ textAlign: "left", marginLeft: 0 }}
          row
          aria-label={`Answer for Question ${questionIndex + 1}`}
          name={fieldName}
          value={selectedAnswer}
          onChange={(e) => handleFormChange(e, questionIndex)}
        >
          {booleanOptions?.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
        {isSubmissionInitiated && !isAnswerGivenBoolean && (
          <FormHelperText error>
            This field is required, Please select a answer first.
          </FormHelperText>
        )}
        <Typography variant="body2" style={{ marginTop: "8px" }}>
          <strong>Option Score:</strong> {optionScore}
        </Typography>
      </FormControl>
    );
  };

  const renderAdditionalFields = (
    question,
    questionIndex,
    handleFormChange
  ) => {
    const typeKey = question.type?.key;

    const qAnswer =
      question?.currentAnswer?.answer?.value ||
      question?.currentAnswer?.answer?.title ||
      question?.currentAnswer?.answer ||
      "";

    switch (typeKey) {
      case "number":
        const numericQAnswer = parseFloat(qAnswer);
        const isAnswerGiven = !isNaN(numericQAnswer) && numericQAnswer >= 0;
        return (
          <div style={{ marginBottom: 20, width: "100%" }}>
            <TextField
              label={`Numeric Answer for Question ${questionIndex + 1}`}
              name={`question_${questionIndex + 1}_numeric_answer`}
              variant="outlined"
              fullWidth
              value={question?.currentAnswer?.answer?.value}
              onChange={(e) => handleFormChange(e, questionIndex)}
              margin="normal"
              type="number"
              required
              error={isSubmissionInitiated && !isAnswerGiven}
              helperText={
                isSubmissionInitiated && !isAnswerGiven
                  ? "This field is required, Please provide number as an answer first."
                  : ""
              }
            />
          </div>
        );

      case "text":
        const isAnswerGivenText =
          typeof qAnswer === "string" && qAnswer.trim() !== "";
        return (
          <div style={{ marginBottom: 20, width: "100%" }}>
            <TextField
              label={`Text Answer for Question ${questionIndex + 1}`}
              name={`question_${questionIndex + 1}_text_answer`}
              variant="outlined"
              fullWidth
              value={question?.currentAnswer?.answer?.value}
              onChange={(e) => handleFormChange(e, questionIndex)}
              margin="normal"
              required
              error={isSubmissionInitiated && !isAnswerGivenText}
              helperText={
                isSubmissionInitiated && !isAnswerGivenText
                  ? "This field is required, Please provide text as an answer first."
                  : ""
              }
            />
          </div>
        );

      case "single_select":
        const singleSelectOptions = question?.attached_list?.items;
        const isAnswerGivenSingleSelect =
          typeof qAnswer === "string" && qAnswer.trim() !== "";
        const isInvalidSingleSelect =
          isSubmissionInitiated && !isAnswerGivenSingleSelect;

        return (
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label={`Answer for Question ${questionIndex + 1}`}
              name={`question_${questionIndex + 1}_answer`}
              value={question?.currentAnswer?.answer?.title}
              onChange={(e) => handleFormChange(e, questionIndex)}
            >
              {singleSelectOptions?.map((option) => {
                return (
                  <FormControlLabel
                    key={option.title}
                    value={option.title}
                    control={<Radio />}
                    label={`${option.title}`}
                  />
                );
              })}
            </RadioGroup>
            {isInvalidSingleSelect && (
              <FormHelperText error>
                This field is required, Please select a answer first.
              </FormHelperText>
            )}
          </FormControl>
        );

      case "multi_select":
        const isAnswerGivenMultiSelect =
          Array.isArray(qAnswer) && qAnswer.length > 0;

        const multiSelectOptions = Object.entries(
          question.type?.option_score || {}
        );
        const selectedOptions = question?.currentAnswer?.answer || [];
        const handleMultiSelectChange = (e) => {
          const selectedOption = e.target.value;
          const modifiedQuestionData = [...questionData];

          const answer = modifiedQuestionData[
            questionIndex
          ]?.attached_list?.items.filter((ans) => ans.title === e.target.value);

          let updatedOptions = [...selectedOptions];

          if (e.target.checked) {
            updatedOptions.push({
              id: answer[0] && answer[0].id,
              title: selectedOption,
              option_score:
                modifiedQuestionData[questionIndex]?.type?.option_score[
                  selectedOption
                ],
            });
          } else {
            updatedOptions = updatedOptions.filter(
              (upOp) => upOp.title !== selectedOption
            );
          }

          const fieldName = `question_${questionIndex + 1}_answer`;
          const updatedFormData = {
            ...formData,
            [fieldName]: updatedOptions,
          };
          modifiedQuestionData[questionIndex] = {
            ...modifiedQuestionData[questionIndex],
            currentAnswer: {
              type: modifiedQuestionData[questionIndex]?.type?.key,
              answer: updatedOptions,
              startTime: startTime,
              endTime: moment().format(),
            },
          };

          if (selectedRemainingData && selectedRemainingData?._id) {
            localStorage.setItem(
              "answeredQuestions_" + selectedRemainingData?._id,
              JSON.stringify(modifiedQuestionData)
            );
            dispatch(
              AuditorChecklistSubmissionDataSuccess(modifiedQuestionData)
            );
          }
          setQuestionData(modifiedQuestionData);
          setFormData(updatedFormData);
          updateAnsweredQuestions(fieldName, updatedOptions, questionIndex);
        };

        const optionsAnswer = selectedOptions.map((option) => option.title);
        const isInvalidMultiSelect =
          isSubmissionInitiated && !isAnswerGivenMultiSelect;

        return (
          <div>
            {multiSelectOptions?.map(([option, score]) => (
              <FormControlLabel
                key={option}
                control={<Checkbox />}
                label={`${option}`}
                checked={optionsAnswer.includes(option)}
                onChange={handleMultiSelectChange}
                value={option}
              />
            ))}
            {isInvalidMultiSelect && (
              <FormHelperText error>
                This field is required, Please select one or more answer first.
              </FormHelperText>
            )}
          </div>
        );

      case "boolean":
        return renderBooleanField(question, questionIndex, handleFormChange);

      case "file":
        const fileInputId = `fileInput-${questionIndex}`;

        const handleRemoveFile = (file) => {
          const modifiedQuestionData = [...auditorChecklistSubmissionData];
          const medias = modifiedQuestionData[questionIndex]?.media.filter(
            (uploadedFile) => uploadedFile.fileName !== file
          );
          const mediaAnswer = modifiedQuestionData[
            questionIndex
          ]?.currentAnswer?.answer?.answer.filter(
            (uploadedFile) => uploadedFile !== file
          );

          modifiedQuestionData[questionIndex] = {
            ...modifiedQuestionData[questionIndex],
            media: medias,
            currentAnswer: {
              ...modifiedQuestionData[questionIndex].currentAnswer,
              answer: {
                ...modifiedQuestionData[questionIndex].currentAnswer.answer,
                answer: mediaAnswer,
              },
            },
          };

          if (selectedRemainingData && selectedRemainingData?._id) {
            localStorage.setItem(
              "answeredQuestions_" + selectedRemainingData?._id,
              JSON.stringify(modifiedQuestionData)
            );
            dispatch(
              AuditorChecklistSubmissionDataSuccess(modifiedQuestionData)
            );
          }
        };

        const storedFile =
          (auditorChecklistSubmissionData?.[questionIndex]?.media?.[0]?.path
            ?.name &&
            auditorChecklistSubmissionData?.[questionIndex]?.currentAnswer
              ?.answer?.answer) ||
          [];

        const isAnswerGivenFile =
          storedFile && storedFile.length > 0 && isSubmissionInitiated;

        return (
          <Card>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <input
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      e.target.value = null;
                      if (file) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          const imageSrc = e.target.result;
                          setCapturedImage(imageSrc);
                        };
                        reader.readAsDataURL(file);
                        const fieldName = `question_${
                          questionIndex + 1
                        }_file_answer`;
                        handleFormChange(
                          {
                            target: {
                              name: fieldName,
                              value: file,
                            },
                          },
                          questionIndex
                        );
                      }
                    }}
                    style={{ display: "none" }}
                    id={`fileInput-${questionIndex}`}
                    multiple
                  />
                  <label htmlFor={`fileInput-${questionIndex}`}>
                    <Button
                      variant="outlined"
                      color="primary"
                      component="span"
                      startIcon={<AttachFileIcon />}
                    >
                      Select File
                    </Button>
                  </label>
                </div>
              </div>
              {storedFile?.length
                ? storedFile.map((file) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <InsertDriveFileIcon
                        color="primary"
                        style={{ marginRight: "8px" }}
                      />
                      <Typography
                        variant="body2"
                        gutterBottom
                        style={{ marginRight: "8px" }}
                      >
                        {file}
                      </Typography>
                      <IconButton
                        color="secondary"
                        onClick={() => handleRemoveFile(file)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))
                : ""}
              {isSubmissionInitiated && !isAnswerGivenFile && (
                <FormHelperText error>
                  This field is required, Please attach a file as an answer
                  first.
                </FormHelperText>
              )}
            </CardContent>
          </Card>
        );

      case "date":
        const isAnswerGivenDate =
          question?.currentAnswer?.answer?.value !== null &&
          question?.currentAnswer?.answer?.value !== undefined &&
          question?.currentAnswer?.answer?.value !== "";

        return (
          <div style={{ marginBottom: "1rem" }}>
            <FormControl component="fieldset">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  size="small"
                  value={startDate || question?.currentAnswer?.answer?.value}
                  onChange={(newValue) => {
                    if (newValue && newValue.$d) {
                      let dateSort = moment(newValue.$d).format("YYYY-MM-DD");
                      setStartDate(dateSort);
                      setEndDate(dateSort);
                      handleFormChange(
                        {
                          target: {
                            name: `question_${questionIndex + 1}_date_answer`,
                            value: dateSort,
                          },
                        },
                        questionIndex
                      );
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  PopperProps={{
                    popperOptions: {
                      modifiers: [
                        {
                          name: "preventOverflow",
                          options: {
                            padding: 8,
                          },
                        },
                        {
                          name: "flip",
                          options: {
                            padding: 8,
                          },
                        },
                      ],
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
            {isSubmissionInitiated && !isAnswerGivenDate && (
              <FormHelperText error>
                This field is required, Please select a date as an answer.
              </FormHelperText>
            )}
          </div>
        );

      case "datetime":
        const isAnswerGivenDatetime =
          question?.value !== null && question?.value !== undefined;

        const handleDateTimeChange = (newDateTime) => {
          if (newDateTime) {
            const formattedDateTime = moment(newDateTime).format(
              "YYYY-MM-DDThh:mm:ss"
            );
            handleFormChange(
              {
                target: {
                  name: `question_${questionIndex + 1}_datetime_answer`,
                  value: newDateTime,
                },
              },
              questionIndex
            );
          }
        };

        return (
          <div>
            <div style={{ marginBottom: "1rem" }}>
              <FormControl component="fieldset">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={
                      startDateAsDateTime ||
                      (question?.value ? question?.value : null)
                    }
                    onChange={(newValue) => {
                      if (newValue && newValue.$d) {
                        let dateSort = moment(newValue.$d).format("YYYY-MM-DD");
                        setStartDateAsDateTime(dateSort);
                        setEndDateAsDateTime(dateSort);
                        handleDateTimeChange(newValue);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <FormControl component="fieldset">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Time"
                    value={selectedTimeForTime || question?.value || null}
                    onChange={(newValue) => {
                      setSelectedTimeForTime(newValue);
                      handleDateTimeChange(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    views={["hours", "minutes", "seconds"]}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
            {isSubmissionInitiated && !isAnswerGivenDatetime && (
              <FormHelperText error>This field is required</FormHelperText>
            )}
          </div>
        );

      case "time":
        const isAnswerGivenTime =
          question?.value !== null && question?.value !== undefined;

        const handleTimeChange = (newTime) => {
          if (newTime) {
            setSelectedTime(newTime);
            const formattedTime = newTime
              ? moment(newTime?.$d).format("hh:mm:ss A")
              : "";
            handleFormChange(
              {
                target: {
                  name: `question_${questionIndex + 1}_time_answer`,
                  value: newTime,
                },
              },
              questionIndex
            );
          }
        };

        return (
          <div style={{ marginBottom: "1rem" }}>
            <FormControl component="fieldset">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Time"
                  value={selectedTime || question?.value || null}
                  onChange={(newValue) => {
                    setSelectedTime(newValue);
                    handleTimeChange(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  views={["hours", "minutes", "seconds"]}
                />
              </LocalizationProvider>
            </FormControl>
            {isSubmissionInitiated && !isAnswerGivenTime && (
              <FormHelperText error>Time answer is required</FormHelperText>
            )}
          </div>
        );

      case "timer":
        const isTimerStarted = timerValue !== null && timerValue !== undefined;

        return (
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton color="primary" onClick={startTimer}>
                <PlayArrowIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => pauseTimer(questionIndex)}
              >
                <PauseIcon fontSize="small" />
              </IconButton>
              <IconButton color="secondary" onClick={resetTimer}>
                <ReplayIcon fontSize="small" />
              </IconButton>
            </div>
            {isSubmissionInitiated && !isTimerStarted && (
              <FormHelperText error>
                This field is required, Please start timer and stop it.
              </FormHelperText>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "8px",
                  }}
                >
                  <Typography variant="body2" style={{ marginRight: "8px" }}>
                    Days:{" "}
                    {Math.floor(
                      (timerValue ||
                        question?.currentAnswer?.answer?.value ||
                        0) /
                        (24 * 60 * 60)
                    )}
                  </Typography>
                  <Typography variant="body2" style={{ marginRight: "8px" }}>
                    Hrs:{" "}
                    {Math.floor(
                      ((timerValue ||
                        question?.currentAnswer?.answer?.value ||
                        0) %
                        (24 * 60 * 60)) /
                        (60 * 60)
                    )}
                  </Typography>
                  <Typography variant="body2" style={{ marginRight: "8px" }}>
                    Mins:{" "}
                    {Math.floor(
                      (timerValue ||
                        question?.currentAnswer?.answer?.value ||
                        0) / 60
                    )}
                  </Typography>
                  <Typography variant="body2" style={{ marginRight: "8px" }}>
                    Sec:{" "}
                    {(timerValue ||
                      question?.currentAnswer?.answer?.value ||
                      0) %
                      60 ===
                    0
                      ? 0
                      : (timerValue ||
                          question?.currentAnswer?.answer?.value ||
                          0) % 60}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  // const handleReviewSubmit = async () => {
  //   setLoader(true);
  //   setIsSubmissionInitiated(true);
  //   const storedAnswers = localStorage.getItem(
  //     "answeredQuestions_" + selectedRemainingData?._id
  //   );
  //   const parsedAnswers = JSON.parse(storedAnswers);

  //   const formSelfieData = localStorage.getItem("formSelfie");
  //   const formSelfie = JSON.parse(formSelfieData);
  //   const formVideos = [];
  //   const formImages = [];
  //   const formMedias = [];

  //   const body = {
  //     formId: formInfo._id,
  //     assignmentId: assignmentData._id,
  //     questions: parsedAnswers.map((item, i) => {
  //       item?.image && formImages.push(...item.image);
  //       auditorChecklistSubmissionData[i]?.video &&
  //         formVideos.push(...item.video);
  //       auditorChecklistSubmissionData[i]?.media &&
  //         formMedias.push(...auditorChecklistSubmissionData[i].media);

  //       return {
  //         id: item.id,
  //         title: item.title,
  //         answer: item.currentAnswer,
  //         rules: item.rules,
  //         question_score: item.question_score,
  //         comment: item?.comment || "",
  //         images: item.image ? item.image.map((img) => img.key) : [],
  //         videos: auditorChecklistSubmissionData[i]?.video
  //           ? auditorChecklistSubmissionData[i]?.video.map((vid) => vid.key)
  //           : [],
  //       };
  //     }),
  //     kpis: [],
  //     rules: formInfo.rules,
  //     startDateTime: startTime,
  //     endDateTime: moment().format(),
  //     submittedTime: moment().format(),
  //     store_location: {
  //       lat: storeDetails?.gps?.latitude,
  //       lng: storeDetails?.gps?.longitude,
  //     },
  //     user_location: {
  //       lat: storeDetails?.gps?.latitude,
  //       lng: storeDetails?.gps?.longitude,
  //     },
  //     store: {
  //       _id: storeDetails._id,
  //       title: storeDetails.title,
  //     },
  //     otherInfo: {},
  //     formImages: [...formImages, ...formMedias, ...formVideos].map((img) => {
  //       return {
  //         addedBy: img.addedBy,
  //         company: img.company,
  //         contentType: img.contentType,
  //         fileName: img.fileName,
  //         fileType: img.fileType,
  //         key: img.key,
  //         name: img.name,
  //         originalname: img.originalname,
  //         size: img.size,
  //       };
  //     }),
  //     userImages:
  //       formSelfie?.key || "PP-IMAGE-g2j1d2n2q1.pngwing_com-1691490223981.png",
  //     title: formInfo.title,
  //   };

  //   if (formSelfie?.path) {
  //     formImages.push(formSelfie);
  //     body.formImages.push({
  //       addedBy: formSelfie.addedBy,
  //       company: formSelfie.company,
  //       contentType: formSelfie.contentType,
  //       fileName: formSelfie.fileName,
  //       fileType: formSelfie.fileType,
  //       key: formSelfie.key,
  //       name: formSelfie.name,
  //       originalname: formSelfie.originalname,
  //       size: formSelfie.size,
  //     });
  //   }

  //   const id = localStorage.getItem("companyId");
  //   const submissionData = {
  //     body: body,
  //   };
  //   const getFormId = formInfo?._id;

  //   const payloadRequest = {
  //     url: BASE_URL + `generatePdfMobile?company_id=${id}&formId=${getFormId}`,
  //   };
  //   submissionData.url =
  //     BASE_URL + `submitFormImage?timezone=india&company_id=${companyId}`;
  //   await dispatch(SubmitChecklistAction(submissionData, payloadRequest));
  //   if (formImages.length) {
  //     formImages.map((img) => {
  //       // uploadFile({ file: img.path, name: img.name });
  //       uploadFile({ file: img.path, name: img.name, company: companyName });
  //     });
  //   }
  //   if (formVideos.length) {
  //     formVideos.map((vid) => {
  //       // uploadVideo({ file: img.path, name: img.name });
  //       uploadVideo({ name: vid.name, file: vid.path, company: companyName });
  //     });
  //   }
  //   if (formMedias.length) {
  //     formMedias.map((img) => {
  //       // uploadMedia({ file: img.path, name: img.name });
  //       uploadMedia({ file: img.path, name: img.name, company: companyName });
  //     });
  //   }

  //   setTimeout(() => {
  //     toast.success("Checklist Submitted successfully!", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     setLoader(false);
  //     navigate(`/checklist-home/checklist-types/checklist-forms`);
  //   }, 5000);
  // };

  const handleReviewSubmit = async () => {
    setLoader(true);
    setIsSubmissionInitiated(true);
    try {
      const storedAnswers = localStorage.getItem(
        "answeredQuestions_" + selectedRemainingData?._id
      );
      const parsedAnswers = JSON.parse(storedAnswers);
      const formSelfieData = localStorage.getItem("formSelfie");
      const formSelfie = JSON.parse(formSelfieData);
      const formVideos = [];
      const formImages = [];
      const formMedias = [];

      // Populate media arrays from answers
      parsedAnswers.forEach((item, i) => {
        item?.image && formImages.push(...item.image);
        auditorChecklistSubmissionData[i]?.video &&
          formVideos.push(...auditorChecklistSubmissionData[i].video);
        auditorChecklistSubmissionData[i]?.media &&
          formMedias.push(...auditorChecklistSubmissionData[i].media);
      });

      if (formSelfie?.path) formImages.push(formSelfie);

      // Utility to upload file using presigned URL
const uploadFileWithPresignedUrl = async (file) => {
  try {
    let fileName = file.name || file.fileName || `file-${Date.now()}.${file.type?.split("/")[1] || "unknown"}`;
    let fileType = "";

    if (file.contentType.includes("image")) {
      fileType = "image/png";
      fileName = fileName + `.png`;
    } else {
      fileType = file.contentType;
      fileName = fileName + `.${file.contentType.split("/")[1]}`;
    }

    if (!fileName || !fileType) {
      throw new Error("File name or type is missing or invalid.");
    }

    console.log("Preparing to upload file:", { fileName, fileType, size: file.size });

    // Get presigned URL
    const presignedUrl = await getPresignedUrl(fileName, fileType, file.size);

    if (!presignedUrl) {
      throw new Error("Failed to retrieve the presigned URL.");
    }

    console.log("Presigned URL retrieved:", presignedUrl);

    let blob = "";

    if (file.contentType.includes("image")) {
      const cleanBase64 = file.path.split(",")[1].replace(/[\r\n]/g, "");
      const byteCharacters = atob(cleanBase64);
      const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);
      blob = new Blob([byteArray], { type: fileType });
    } else {
      const res = await fetch(file.path);
      blob = await res.blob();
    }

    // Upload file to S3
    const response = await axios.put(presignedUrl, blob, {
      headers: {
        "Content-Type": fileType,
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log(`Upload Progress: ${progress}%`);
      },
    });

    if (response.status !== 200) {
      throw new Error(`Failed to upload file. Status: ${response.status}`);
    }

    console.log("File uploaded successfully:", { fileName, url: presignedUrl.split("?")[0] });

    // Return only the dynamic part (file name)
    const fileKey = presignedUrl.split("/").pop().split("?")[0];
    return fileKey;  // This will return just the file name, e.g., IMGPOPPROBE_Del_Pri_1_Hav_2024-12-06-14-15-10-1733474710124.png
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error(`File upload failed: ${error.message}`);
  }
};

      

      // Upload all images, videos, and other media
      await Promise.all(
        [...formImages, ...formVideos, ...formMedias].map(async (media) => {
          media.key = await uploadFileWithPresignedUrl(media);
        })
      );

      const body = {
        formId: formInfo._id,
        assignmentId: assignmentData._id,
        questions: parsedAnswers.map((item, i) => ({
          id: item.id,
          title: item.title,
          answer: item.currentAnswer,
          rules: item.rules,
          question_score: item.question_score,
          comment: item?.comment || "",
          images: item.image ? item.image.map((img) => img.key) : [],
          videos: auditorChecklistSubmissionData[i]?.video
            ? auditorChecklistSubmissionData[i]?.video.map((vid) => vid.key)
            : [],
        })),
        kpis: [],
        rules: formInfo.rules,
        startDateTime: startTime,
        endDateTime: moment().format(),
        submittedTime: moment().format(),
        store_location: {
          lat: storeDetails?.gps?.latitude,
          lng: storeDetails?.gps?.longitude,
        },
        user_location: {
          lat: storeDetails?.gps?.latitude,
          lng: storeDetails?.gps?.longitude,
        },
        store: {
          _id: storeDetails._id,
          title: storeDetails.title,
        },
        otherInfo: {},
        formImages: [...formImages, ...formMedias, ...formVideos].map(
          (img) => ({
            addedBy: img.addedBy,
            company: img.company,
            contentType: img.contentType,
            fileName: img.key,
            fileType: img.fileType,
            key: img.key,
            name: img.key,
            originalname: img.key,
            size: img.size,
          })
        ),
        userImages:
          formSelfie?.key ||
          "PP-IMAGE-g2j1d2n2q1.pngwing_com-1691490223981.png",
        title: formInfo.title,
      };

      const companyId = localStorage.getItem("companyId");
      const payloadRequest = {
        url: `${BASE_URL}generatePdfMobile?company_id=${companyId}&formId=${formInfo._id}`,
      };
      const submissionData = {
        url: `${BASE_URL}submitFormImage?timezone=india&company_id=${companyId}`,
        body,
      };

      await dispatch(SubmitChecklistAction(submissionData, payloadRequest));

      toast.success("Checklist Submitted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      navigate(`/checklist-home/checklist-types/checklist-forms`);
    } catch (error) {
      console.error("Error during submission:", error);
      toast.error("Failed to submit checklist. Please try again.");
    } finally {
      setLoader(false);
    }
  };

  return (
    <Box>
      {/* <input
        type="file"
        onChange={handleFileChange}
        accept="image/*,video/*,audio/*"
      /> */}
      <Card className="header-card">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography
              variant="h6"
              component="h6"
              align="left"
              style={{
                padding: "4px",
                marginLeft: "10px",
                marginBottom: "0",
                fontSize: "1.2rem",
              }}
            >
              {selectedRemainingData?.title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography
              variant="h6"
              component="h6"
              align="right"
              style={{
                textTransform: "uppercase",
                padding: "4px",
                marginRight: "10px",
                marginBottom: "0",
                fontSize: "1.2rem",
              }}
            >
              {storeTitle}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <ToastContainer />
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        ""
      )}

      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          borderRadius: 1,
          overflowX: "auto",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: 0,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: 8,
          },
        }}
      >
        {showLoader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : (
          <form>
            {questionData?.map((question, questionIndex) => (
              <Card
                key={questionIndex}
                sx={{
                  p: 2,
                  mt: 2,
                  textAlign: "left",
                }}
              >
                <Typography
                  variant="body1"
                  gutterBottom
                  align="left"
                  style={{ whiteSpace: "pre-line" }}
                >
                  <Box
                    style={{
                      background: "#f26539",
                      padding: "10px",
                      marginBottom: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box style={{ color: "white", fontWeight: "bold" }}>
                        Question. {questionIndex + 1}
                      </Box>
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {question?.image?.length || question?.video?.length ? (
                        <Box>
                          <Box
                            style={{
                              cursor: "pointer",
                              marginRight: "10px",
                              display: "flex",
                            }}
                            onClick={() =>
                              openCapturedImgViewPopup(questionIndex)
                            }
                          >
                            <VisibilityIcon fontSize="small" />
                          </Box>
                        </Box>
                      ) : (
                        ""
                      )}

                      {question.type.validations[2].key === "image" ? (
                        question.type.validations[2].answer[0].title ===
                        "Yes" ? (
                          question.type.validations[2].answer[0].value ? (
                            <>
                              {question.type.validations[5].key ===
                                "image_optional" &&
                              question.type.validations[5].answer[1].title ===
                                "No" &&
                              question.type.validations[5].answer[1].value ? (
                                <span style={{ color: "red" }}>*</span>
                              ) : (
                                ""
                              )}
                              <Box
                                style={{
                                  cursor:
                                    question.length >= 5
                                      ? "not-allowed"
                                      : "pointer",
                                  marginRight: "10px",
                                  display: "flex",
                                  opacity: question.length >= 5 ? "0.7" : "1",
                                }}
                                onClick={() =>
                                  openQuestionImgCapturePopup(questionIndex)
                                }
                                disabled={question.length >= 5}
                              >
                                <AddAPhotoIcon fontSize="small" />
                              </Box>
                            </>
                          ) : (
                            question.rules &&
                            question.rules.map(
                              (rule, ruleIndex) =>
                                rule.data.condition.key === "=" &&
                                rule.subType.title === "QuestionImage" &&
                                (question?.currentAnswer?.answer?.value
                                  ? question?.currentAnswer?.answer?.value
                                  : question?.currentAnswer?.answer?.title) ===
                                  rule.data.answer.title && (
                                  <>
                                    <span style={{ color: "red" }}>*</span>
                                    <Box
                                      style={{
                                        cursor:
                                          question.length >= 5
                                            ? "not-allowed"
                                            : "pointer",
                                        marginRight: "10px",
                                        display: "flex",
                                        opacity:
                                          question.length >= 5 ? "0.7" : "1",
                                      }}
                                      onClick={() =>
                                        openQuestionImgCapturePopup(
                                          questionIndex
                                        )
                                      }
                                      disabled={question.length >= 5}
                                    >
                                      <AddAPhotoIcon fontSize="small" />
                                    </Box>
                                  </>
                                )
                            )
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}

                      {question.type.validations[3].key === "video" ? (
                        question.type.validations[3].answer[0].title ===
                        "Yes" ? (
                          question.type.validations[3].answer[0].value ? (
                            <>
                              {question.type.validations[6].key ===
                                "video_optional" &&
                              question.type.validations[6].answer[1].title ===
                                "No" &&
                              question.type.validations[6].answer[1].value ? (
                                <span style={{ color: "red" }}>*</span>
                              ) : (
                                ""
                              )}
                              <Box
                                style={{
                                  cursor: "pointer",
                                  marginTop: "2px",
                                  display: "flex",
                                }}
                                onClick={() =>
                                  handleVideoRecording(questionIndex)
                                }
                              >
                                <VideocamIcon fontSize="medium" />
                              </Box>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                  <span>
                    <span style={{ color: "red" }}>*</span>
                    <strong>{question.title}</strong>{" "}
                  </span>
                  <Tooltip
                    title={question.desc}
                    arrow
                    placement="right"
                    style={{
                      backgroundColor: "#f26539",
                      marginLeft: "0.5em",
                    }}
                  >
                    <span
                      style={{
                        cursor: "help",
                        color: "#f26539",
                      }}
                    >
                      <InfoIcon fontSize="small" />
                    </span>
                  </Tooltip>
                  {question?.rules?.length ? (
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#858383",
                        fontSize: "12px",
                      }}
                    >
                      <Box>Hint:</Box>
                      {question.rules &&
                        question.rules.map((rule, ruleIndex) => (
                          <Box
                            style={{
                              display: "flex",
                              marginLeft: "2px",
                              alignItems: "center",
                            }}
                          >
                            {rule.data.condition.key === "=" ? (
                              rule.subType.title === "QuestionComment" ? (
                                <Box
                                  style={{
                                    display: "flex",
                                    fontSize: "12px",
                                    alignItems: "center",
                                  }}
                                >
                                  ( <Box>{rule.data.answer.title}</Box> -
                                  <AddCommentIcon style={{ fontSize: 12 }} /> )
                                </Box>
                              ) : rule.subType.title === "QuestionImage" ? (
                                <Box
                                  style={{
                                    display: "flex",
                                    fontSize: "12px",
                                    alignItems: "center",
                                  }}
                                >
                                  ( <Box>{rule.data.answer.title}</Box> -
                                  <AddAPhotoIcon style={{ fontSize: 12 }} /> )
                                </Box>
                              ) : rule.subType.title === "QuestionAutoIssue" ? (
                                <Box
                                  style={{
                                    display: "flex",
                                    fontSize: "12px",
                                    alignItems: "center",
                                  }}
                                >
                                  ( <Box>{rule.data.answer.title}</Box> -
                                  <ReportProblemIcon
                                    style={{ fontSize: 14 }}
                                  />{" "}
                                  )
                                </Box>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </Box>
                        ))}
                    </Box>
                  ) : (
                    ""
                  )}
                </Typography>
                {question.type?.key === "boolean"
                  ? renderBooleanField(question, questionIndex)
                  : renderAdditionalFields(
                      question,
                      questionIndex,
                      handleFormChange
                    )}
                {question.type.validations[4].key === "comment" &&
                question.type.validations[4].answer[0].title === "Yes" &&
                question.type.validations[4].answer[0].value ? (
                  <>
                    <div style={{ marginBottom: "2em" }}></div>
                    <Box>
                      <span>
                        <span style={{ color: "red" }}>*</span>
                        <strong>Comment</strong>{" "}
                      </span>
                      <div style={{ marginBottom: 20, width: "100%" }}>
                        <TextField
                          label={`Comment for Question ${questionIndex + 1}`}
                          name={`question_${questionIndex + 1}_text_comment`}
                          variant="outlined"
                          fullWidth
                          value={question?.comment || ""}
                          onChange={(e) =>
                            handleFormCommentChange(e, questionIndex)
                          }
                          margin="normal"
                          required
                        />
                      </div>
                    </Box>
                  </>
                ) : (
                  question.rules &&
                  question.rules.map(
                    (rule, ruleIndex) =>
                      rule.data.condition.key === "=" &&
                      rule.subType.title === "QuestionComment" &&
                      (question?.currentAnswer?.answer?.value
                        ? question?.currentAnswer?.answer?.value
                        : question?.currentAnswer?.answer?.title) ===
                        rule.data.answer.title && (
                        <>
                          <div style={{ marginBottom: "2em" }}></div>
                          <Box>
                            <span>
                              <span style={{ color: "red" }}>*</span>
                              <strong>Comment</strong>{" "}
                            </span>
                            <div style={{ marginBottom: 20, width: "100%" }}>
                              <TextField
                                label={`Comment for Question ${
                                  questionIndex + 1
                                }`}
                                name={`question_${
                                  questionIndex + 1
                                }_text_comment`}
                                variant="outlined"
                                fullWidth
                                value={question?.comment || ""}
                                onChange={(e) =>
                                  handleFormCommentChange(e, questionIndex)
                                }
                                margin="normal"
                                required
                              />
                            </div>
                          </Box>
                        </>
                      )
                  )
                )}
                {/* {question.rules &&
                  question.rules.map(
                    (rule, ruleIndex) =>
                      rule.data.condition.key === "=" &&
                      rule.subType.title === "QuestionComment" &&
                      question?.currentAnswer?.answer?.value ===
                        rule.data.answer.title && (
                        <>
                          <div style={{ marginBottom: "2em" }}></div>
                          <Box>
                            <span>
                              <span style={{ color: "red" }}>*</span>
                              <strong>Comment</strong>{" "}
                            </span>
                            <div style={{ marginBottom: 20, width: "100%" }}>
                              <TextField
                                label={`Comment for Question ${
                                  questionIndex + 1
                                }`}
                                name={`question_${
                                  questionIndex + 1
                                }_text_comment`}
                                variant="outlined"
                                fullWidth
                                value={question?.comment || ""}
                                onChange={(e) =>
                                  handleFormCommentChange(e, questionIndex)
                                }
                                margin="normal"
                                required
                              />
                            </div>
                          </Box>
                        </>
                      )
                  )} */}
              </Card>
            ))}
            {selectedRemainingData?.isSelfieRequired !== false ? (
              <Button
                className="cancle-hover"
                variant="contained"
                sx={{ mt: 2, backgroundColor: "#196773" }}
                onClick={() => {
                  setIsSubmissionInitiated(true);
                  if (validateQuestionsAnswered()) {
                    takeSelfieSubmit();
                  }
                }}
              >
                Take Selfie & Submit
              </Button>
            ) : (
              <Button
                className="cancle-hover"
                variant="contained"
                sx={{ mt: 2, backgroundColor: "#196773" }}
                onClick={() => {
                  setIsSubmissionInitiated(true);
                  if (validateQuestionsAnswered()) {
                    handleReview();
                    setReviewModalOpen(true);
                  }
                }}
              >
                <PreviewIcon />
                Review
              </Button>
            )}

            <CustomDialog
              isSelfieDialogOpen={isSelfieDialogOpen}
              closeSelfieDialog={closeSelfieDialog}
              handleAttachSelfieImage={handleAttachSelfieImage}
              handleOkButtonClick={handleOkButtonClick}
              fileInputRef={fileInputRef}
              setReviewModalOpen={() => setReviewModalOpen(true)}
              setSelfieDialogOpen={() => setSelfieDialogOpen(false)}
              selectedChecklistId={selectedRemainingData?._id}
              captureImage={captureImage}
              handleFormImage={handleFormImage}
              handleFormVideo={handleFormVideo}
              isVideoIconClicked={isVideoIconClicked}
              isSubmissionInitiated={isSubmissionInitiated}
            />
            {isViewImageDialogOpen && (
              <ImagePreviewModal
                isViewImageDialogOpen={isViewImageDialogOpen}
                closeViewImageDialog={closeViewImageDialog}
                captureImageIndex={captureImageIndex}
                removeFormImage={removeFormImage}
              />
            )}
            <Dialog
              open={reviewModalOpen}
              onClose={() => setReviewModalOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableEscapeKeyDown={false}
              maxWidth="md"
              fullWidth
              PaperProps={{
                style: {
                  height: "85%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <DialogTitle>
                <Grid
                  container
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    <strong>Checklist Review</strong>
                  </Typography>
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AttachFileIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const formSelfieData =
                          localStorage.getItem("formSelfie");
                        const formSelfie = JSON.parse(formSelfieData);

                        setIsImages([formSelfie.path]);
                        setIsViewerOpen(true);
                      }}
                    />
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setReviewModalOpen(false)}
                    />
                  </Typography>
                </Grid>
              </DialogTitle>
              <DialogContent
                style={{
                  paddingBottom: "0px",
                }}
              >
                <ReviewDialog />
              </DialogContent>
              <DialogActions>
                <Button
                  className="cancle-hover"
                  variant="contained"
                  sx={{ backgroundColor: "#196773" }}
                  onClick={handleReviewSubmit}
                >
                  <DoneIcon />
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            {isViewerOpen && (
              <ImageViewer
                src={isImages}
                currentIndex={0}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{
                  backgroundColor: "rgb(0 0 0 / 65%)",
                }}
                closeOnClickOutside={true}
              />
            )}
          </form>
        )}
      </Box>
    </Box>
  );
};

export default SubmitChecklist;
