import React from 'react'
import { useDispatch } from "react-redux";
import { CompanyListApi } from "../../redux/actions/AnalyticDashboard";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { Box, Card, CardContent, Grid, Pagination, Typography } from "@mui/material";
import Edit from "../../../assets/icons/svg-icons/Edit.svg";
import LogoImage from "../../../assets/images/logo-image.svg";
import moment from "moment";
import { useSelector } from "react-redux";
import ProgressbarTransparent from '../../GlobalProgress/ProgressbarTransparent';

const AnalyticalCompanyList = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [companyListData, setCompanyListData] = useState([]);
    const [page, setPage] = React.useState(1);

    let companyDetails = useSelector(
        (state) =>
            state.analyticDashboard &&
            state.analyticDashboard.companyList
    );

    let Loader = useSelector(
        (state) =>
            state.analyticDashboard &&
            state.analyticDashboard.loading
    );

    const payloadData = {
        pagination: {
            page: page,
            per_page: 12
        },
        status: location.state,
        search: ""
    }

    console.log("companyDetails", companyDetails);
    const fetchCompanyList = () => {
        const data = {
            url: BASE_URL + 'company/list',
            body: { ...payloadData }
        };

        dispatch(CompanyListApi(data));
    }

    useEffect(() => {
        fetchCompanyList(location.state);
    }, []);

    useEffect(() => {
        setCompanyListData(companyDetails?.companies);
    }, [companyDetails?.companies]);

    const handleClickOpen = (row) => {
        navigate(`/company-edit`, { state: row });
    };

    const handlePageChange = (event, value) => {
        payloadData.pagination.page = value;
        setPage(value);

        fetchCompanyList();
    };

    return <>
        {Loader && (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "auto",
                    width: "100%",
                }}
            >
                <ProgressbarTransparent play />
            </div>
        )}
        <Grid container spacing={2}>
            {companyListData &&
                companyListData.length > 0 &&
                companyListData.map((item, i) => {
                    return (
                        <Grid item xs={12} sm={12} md={4} lg={4} key={i}>
                            <Card
                                sx={{ height: "100%" }}
                                className="card card-list"
                                key={i}
                            >
                                <CardContent className="zero-space">
                                    <img
                                        onClick={() => handleClickOpen(item)}
                                        src={Edit}
                                        className="company-edit"
                                        alt="edit"
                                    />
                                    <>
                                        <Grid container spacing={0} className="max-width">
                                            <Grid item xs={4}>
                                                <div className="company-image-div">
                                                    <img
                                                        src={
                                                            item?.logo?.key
                                                                ? 'https://popprobe-saas.s3.us-west-2.amazonaws.com/' + item?.logo?.key
                                                                : LogoImage
                                                        }
                                                        alt="logo"
                                                        className="company-image-logo"
                                                        style={{ marginTop: "10px" }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={8} className="text-content">
                                                <Typography
                                                    variant="p"
                                                    component="div"
                                                    sx={{
                                                        pt: 1,
                                                        textAlign: "start",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {item.name}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ pt: 8, textAlign: "start" }}
                                                >
                                                    {moment(item.createdAt).format("lll")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
        </Grid>
        <Box sx={{
            width: '100%',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#fff",
            marginTop: '10px',
            marginBottom: '10px',
            borderRadius: "6px",
            padding: "12px"
        }}>
            <Pagination count={10} color="standard" onChange={handlePageChange} />
        </Box>
    </>
}

export default AnalyticalCompanyList;