/** @format */

import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  IconButton,
  Stack,
  Modal,
  Autocomplete,
  FormControl,
  TextField,
  Divider,
  Checkbox,
  InputAdornment,
  Chip,
  Paper,
  FormControlLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  ListAttendanceSubmissionApi,
  ListAttendanceSubmissionExportApi,
} from "../../redux/actions/AttendanceAction";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import * as moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import * as XLSX from "xlsx";
import SingleGlobalTable from "../../GlobalComponent/SingleGlobalTable";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { date } from "yup";
import { ListUserChecklistApi } from "../../redux/actions/User";
import { ListStoreApi } from "../../redux/actions/Store";
import { ListUserApi } from "../../redux/actions/User";
import { useRef } from "react";
import dayjs from "dayjs";
import { ListSubroleApi } from "../../redux/actions/Roles";
const downloadAllstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: "calc(100vh - 100px)",
  overflowY: "auto", 
  bgcolor: "background.paper",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};
const MenuProps = {
  disableScrollLock: true,
};

const AttendanceSubmission = () => {
  const checkInStatusOptions = [
    { label: "Early Check-In", id: 1 },
    { label: "On-Time Check-In", id: 2 },
    { label: "Late Check-In", id: 3 },
    // { label: "No Check-In", id: 4 },
  ];
  const checkOutStatusOptions = [
    { label: "Early Check-Out", id: 5 },
    { label: "On-Time Check-Out", id: 6 },
    { label: "Late Check-Out", id: 7 },
    // { label: "No Check-Out", id: 8 },
    { label: "Auto Check-Out", id: 9 },
  ];
  const dispatch = useDispatch();
  const [configData, setConfigData] = useState([]);
  const [exportData, setexportData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [localPageNo, setLocalPageNo] = useState(1);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const storedSearchString = localStorage.getItem("searchedResult");
  const [searchValue, setSearchValue] = useState("");
  const [filterUser, setFilterUser] = React.useState([]);
  const [filterStore, setFilterStore] = React.useState([]);
  const [selectedCheckInStatus, setSelectedCheckInStatus] = useState(null);
  const [selectedCheckOutStatus, setSelectedCheckOutStatus] = useState(null);
  const [availableStores, setAvailableStores] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [shouldExport, setShouldExport] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);

  const [searchQuery, setSearchQuery] = React.useState(
    storedSearchString || ""
  );
  let storedDateObject;
  const searchInputRef = useRef(null);
  try {
    storedDateObject = storedDateString
      ? JSON.parse(storedDateString)
      : { startDate: null, endDate: null };
  } catch (error) {
    storedDateObject = { startDate: null, endDate: null };
  }
  const checkInStatus = selectedCheckInStatus
    ? selectedCheckInStatus.label
    : "";
  const checkOutStatus = selectedCheckOutStatus
    ? selectedCheckOutStatus.label
    : "";
  const [subRole, setSubRole] = useState([]);
  const filterUserIds = filterUser.map((user) => user._id);
  const filterStoreIds = filterStore.map((store) => store._id);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  // const handleStartDateChange = (newValue) => {
  //   if (newValue) {
  //     const formattedDate = moment(newValue).format("YYYY-MM-DD");
  //     setStartDate(formattedDate);
  //   } else {
  //     setStartDate(null);
  //   }
  // };
  // const handleEndDateChange = (newValue) => {
  //   if (newValue) {
  //     const formattedDate = moment(newValue).format("YYYY-MM-DD");
  //     setEndDate(formattedDate);
  //   } else {
  //     setEndDate(null);
  //   }
  // };

  const handleStartDateChange = (newValue) => {
    if (newValue) {
      const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
      setStartDate(formattedDate);
    } else {
      setStartDate(null);
    }
  };

  const handleEndDateChange = (newValue) => {
    if (newValue) {
      const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
      setEndDate(formattedDate);
    } else {
      setEndDate(null);
    }
  };

  const [newDate, setNewDate] = React.useState(
    storedDateObject.startDate && storedDateObject.endDate
      ? {}
      : { startDate: new Date(), endDate: new Date() }
  );
  const modalopenFilterhandleClose = () => {
    setOpenFilterModal(false);
  };

  
  const filterObject = {
    userId: filterUserIds,
    storeId: filterStoreIds,
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD"),
    checkInStatus: checkInStatus,
    checkOutStatus: checkOutStatus,
  };
  const handleFilter = () => {
    listConfig(payloadRequest);
    listConfigForExportExcel(payloadRequestFilter);
    setOpenFilterModal(false);
  };

  useEffect(() => {
    setSearchQuery(searchValue || "");
  }, [searchValue]);


  useEffect(() => {
    const searchInput = searchInputRef.current;
    if (searchInput) {
      searchInput.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (searchInput) {
        searchInput.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [searchQuery]);

  const searchOnChange = (value) => {
    setSearchValue(value);
  };

  const listUser = (data, key) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `report/dropdown?company_id=${id}`;
    dispatch(ListUserChecklistApi(data, key));
  };

  useEffect(() => {
    listUser(
      {
        body: {
          storeIds: filterStore.map((item) => item._id),
          userIds: [],
          Role: subRole,
          startDate: startDate,
          endDate: endDate,
        },
      },
      "user"
    );
  }, [subRole]);

  const handleStoreClear = () => {
    listUser(
      {
        body: {
          storeId: [],
          userId: [],
          startDate: startDate,
          endDate: endDate,
        },
      },
      "user"
    );
  };

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );

  const SubRoleListApi = () => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(ListSubroleApi(data));
  };

  useEffect(() => {
    SubRoleListApi();
  }, []);

  useEffect(() => {
    if (selectedSubRole) {
      setSubRole(selectedSubRole);
    }
  }, [selectedSubRole]);

  const handleSubrolesSelect = (values) => {
    setSubRole(values);
  };

  // useEffect(() => {
  //   if (subRole) {
  //     payloadRequest.body.Role = subRole;
  //         listConfig(payloadRequest);
  //   }
  // }, [subRole]);

  const storeList = useSelector(
    (state) =>
      state.store &&
      state.store.data &&
      state.store.data.data &&
      state.store.data.data.data &&
      state.store.data.data.data.stores
  );

  const storeListBasedOnRole = useSelector(
    (state) =>
      state?.user &&
      state?.user?.userChecklistData &&
      state?.user?.userChecklistData?.data &&
      state?.user?.userChecklistData?.data?.data &&
      state?.user?.userChecklistData?.data?.data?.storeData
  );

  const storeListBasedLoader = useSelector(
    (state) => state?.user && state?.user?.loading
  );
  
  const listStore = (data) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `stores/index?company_id=${id}`;
    dispatch(ListStoreApi(data));
  };

  useEffect(() => {
    setLoader(true);
    listStore(payloadRequestForStoreAndUser);
    listUsers(payloadRequestForStoreAndUser);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [dispatch]);

  const handleStoreClose = () => {
    if (filterStore && filterStore.length) {
      listUser(
        {
          body: {
            storeId: filterStore.map((item) => item._id),
            userId: [],
            startDate: startDate,
            endDate: endDate,
          },
        },
        "user"
      );
    }
  };

  const handleStoreChange = (event, newValue) => {
    if (newValue) {
      setFilterStore(newValue);
      setFilterUser([]);
    } else {
      setFilterStore([]);
    }

    if (!newValue.length) {
      listUser(
        {
          body: {
            storeId: [],
            userId: [],
            startDate: startDate,
            endDate: endDate,
          },
        },
        "user"
      );
    }
  };
  const userList = useSelector(
    (state) =>
      state.user &&
      state.user.data &&
      state.user.data.data &&
      state.user.data.data.data &&
      state.user.data.data.data.users
  );

  const userListBasedOnStore = useSelector(
    (state) =>
      state?.user &&
      state?.user?.userChecklistData &&
      state?.user?.userChecklistData?.data &&
      state?.user?.userChecklistData?.data?.data &&
      state?.user?.userChecklistData?.data?.data?.userIds
  );
  // console.log("userListBasedOnStore", userListBasedOnStore);

  const listUsers = (payloadRequestForStoreAndUser) => {
    const id = localStorage.getItem("companyId");
    payloadRequestForStoreAndUser.url =
      BASE_URL + `users/index?company_id=${id}`;
    dispatch(ListUserApi(payloadRequestForStoreAndUser));
  };

  // const listUsers = () => {
  //   const companyId = localStorage.getItem("companyId");
  //   const data = { url: BASE_URL + `users/index?company_id=${companyId}` };
  //   dispatch(ListUserApi(data));
  // };

  const handleUserChange = (event, newValue) => {
    if (newValue) {
      setFilterUser(newValue);
    } else {
      setFilterUser([]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      const searchedResult = JSON.stringify(searchQuery);
      localStorage.setItem("searchedResult", searchedData);
    } else if (event.keyCode === 8 && searchQuery === "") {
      localStorage.removeItem("searchedResult");
    }
  };
  const payloadRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      search: searchQuery,
      start_date: filterObject.startDate,
      end_date: filterObject.endDate,
      storeId: filterObject.storeId,
      userId: filterObject.userId,
      Role: subRole,
      checkInStatus: filterObject.checkInStatus,
      checkOutStatus: filterObject.checkOutStatus,
    },
  };
  
  const payloadRequestForStoreAndUser = {
    body: {
      page: page,
      per_page: 500,
      // pagination: {
      // },
      search: searchQuery,
      start_date: filterObject.startDate,
      end_date: filterObject.endDate,
      storeId: filterObject.storeId,
      userId: filterObject.userId,
      Role: subRole,
      checkInStatus: filterObject.checkInStatus,
      checkOutStatus: filterObject.checkOutStatus,
    },
  };
  const attendanceLogList = useSelector(
    (state) =>
      state.attendanceLog &&
      state.attendanceLog.submissionList &&
      state.attendanceLog.submissionList.data &&
      state.attendanceLog.submissionList.data.data &&
      state.attendanceLog.submissionList.data.data.attendanceListing
  );

  const attendanceLogListForExport = useSelector(
    (state) =>
      state.attendanceLog &&
      state.attendanceLog.submissionListExport &&
      state.attendanceLog.submissionListExport.data &&
      state.attendanceLog.submissionListExport.data.data &&
      state.attendanceLog.submissionListExport.data.data.attendanceListing
  );

  const totalitems = useSelector(
    (state) =>
      state.attendanceLog &&
      state.attendanceLog.submissionList &&
      state.attendanceLog.submissionList.data &&
      state.attendanceLog.submissionList.data.data &&
      state.attendanceLog.submissionList.data.data.paginate
  );

  useEffect(() => {
    if (attendanceLogList) {
      setConfigData(attendanceLogList);
      setTotalCount(totalitems);
    } else {
      setConfigData([]);
      setTotalCount(0);
    }
  }, [attendanceLogList, totalitems]);

  useEffect(() => {
    setLoader(true);
    listConfig(payloadRequest);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [dispatch, subRole]);

  const listConfig = (payloadRequest) => {
    setLoader(true);
    const id = localStorage.getItem("companyId");
    payloadRequest.url = BASE_URL + `attendanceLog/getAll?company_id=${id}`;
    dispatch(ListAttendanceSubmissionApi(payloadRequest));
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  var pageno = localStorage.getItem("pageno");

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
    }
  }, [pageno]);

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    payloadRequest.body.pagination.page = newPage + 1;
    listConfig(payloadRequest);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    // payloadRequest.body.pagination.page = 1;
    localStorage.removeItem("pageno");
    localStorage.setItem("pageno", 1);
    payloadRequest.body.pagination.per_page = parseInt(event.target.value, 10);
    payloadRequest.body.pagination.page = 1;
    listConfig(payloadRequest);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const columns = [
    {
      field: "S.No",
      headerName: "S.No",
      renderHeader: () => <strong>{"S.No"}</strong>,
      width: 80,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        const index =
          configData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
        return index;
      },
    },
    {
      field: "User ",
      headerName: "User ",
      renderHeader: () => <strong>{"User"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.user?.name) {
          result.push(params?.row?.user?.name);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Store ",
      headerName: "Store ",
      renderHeader: () => <strong>{"Store"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.store?.title) {
          result.push(params?.row?.store?.title);
        }
        if (params?.row?.store?.name) {
          result.push(params?.row?.store?.name);
        }
        if (result.length === 0) {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    // {
    //   field: "Start Time",
    //   headerName: "Start Time",
    //   renderHeader: () => <strong>{"Start Time"}</strong>,
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    //   valueGetter: (params) => {
    //     let result = [];
    //     if (params?.row?.checkInTime) {
    //       result.push(
    //         moment(params.row.checkInTime).format("h:mm A MMM D, YY")
    //       );
    //     } else {
    //       result = ["N/A"];
    //     }
    //     return result.join(", ");
    //   },
    // },
    {
      field: "CheckIn Date Time",
      headerName: "CheckIn Date Time",
      renderHeader: () => <strong>{"CheckIn Date Time"}</strong>,
      width: 200,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        if (params?.row?.checkInTime) {
          return moment(params.row.checkInTime).format("DD/MM/YYYY h:mm A");
        } else {
          return "N/A";
        }
      },
    },

    {
      field: "CheckIn Status ",
      headerName: "CheckIn Status ",
      renderHeader: () => <strong>{"CheckIn Status"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.checkInStatus) {
          result.push(params?.row?.checkInStatus);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "CheckOut Date Time ",
      headerName: "CheckOut Date Time",
      renderHeader: () => <strong>{"CheckOut Date Time"}</strong>,
      width: 200,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        if (params?.row?.checkOutTime) {
          return moment(params.row.checkOutTime).format("DD/MM/YYYY h:mm A");
        } else {
          return "N/A";
        }
      },
    },

    {
      field: "CheckOut Status ",
      headerName: "CheckOut Status ",
      renderHeader: () => <strong>{"CheckOut Status"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.checkOutStatus) {
          result.push(params?.row?.checkOutStatus);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Logged In Hours",
      headerName: "Logged In Hours",
      renderHeader: () => <strong>{"Logged In Hours"}</strong>,
      width: 160,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        const checkInTime = new Date(params?.row?.checkInTime);
        const checkOutTime = new Date(params?.row?.checkOutTime);

        if (!checkInTime || isNaN(checkInTime)) {
          return "0";
        }
        if (checkInTime && checkOutTime && !isNaN(checkOutTime)) {
          const diffInMs = checkOutTime - checkInTime;

          if (diffInMs <= 0) {
            return "0";
          }
          const totalSeconds = Math.floor(diffInMs / 1000);
          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          const seconds = totalSeconds % 60;
          let timeString = "";
          if (hours > 0) {
            timeString += `${hours} hr${hours > 1 ? "s" : ""} `;
          }
          if (minutes > 0) {
            timeString += `${minutes} min `;
          }
          if (seconds > 0 || timeString === "") {
            timeString += `${seconds} sec`;
          }
          return timeString.trim();
        }
        return "0";
      },
    },

    // {
    //   field: "Created At",
    //   headerName: "Created At",
    //   renderHeader: () => <strong>{"Created At"}</strong>,
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    //   valueGetter: (params) => {
    //     let result = [];
    //     if (params?.row?.createdAt) {
    //       result.push(moment(params.row.createdAt).format("h:mm A MMM D, YY"));
    //     } else {
    //       result = ["N/A"];
    //     }
    //     return result.join(", ");
    //   },
    // },

    // {
    //   field: "Updated At",
    //   headerName: "Updated At",
    //   renderHeader: () => <strong>{"Updated At"}</strong>,
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    //   valueGetter: (params) => {
    //     let result = [];
    //     if (params?.row?.updatedAt) {
    //       result.push(moment(params.row.updatedAt).format("h:mm A MMM D, YY"));
    //     } else {
    //       result = ["N/A"];
    //     }
    //     return result.join(", ");
    //   },
    // },

    {
      field: "CheckIn Selfie",
      headerName: "CheckIn Selfie",
      renderHeader: () => <strong>{"CheckIn Selfie"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const selfie = params?.row?.checkInSelfie;
        const hasSelfie = selfie && selfie.url;

        const openImageWithZoom = (url) => {
          const newWindow = window.open("", "_blank");
          if (newWindow) {
            newWindow.document.write(`
              <html>
                <head>
                  <title>CheckIn Selfie Preview</title>
                  <style>
                    body {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100vh;
                      margin: 0;
                      background-color: #f0f0f0;
                    }
                    img {
                      max-width: 100%;
                      max-height: 100%;
                      cursor: zoom-in;
                    }
                  </style>
                </head>
                <body>
                  <img src="${url}" alt="Selfie Preview" id="image" />
                  <script>
                    const img = document.getElementById('image');
                    let zoomLevel = 1;
    
                    img.addEventListener('click', () => {
                      zoomLevel = zoomLevel === 1 ? 2 : 1; // Toggle between zoom levels
                      img.style.transform = 'scale(' + zoomLevel + ')';
                      img.style.cursor = zoomLevel === 1 ? 'zoom-in' : 'zoom-out';
                    });
                  </script>
                </body>
              </html>
            `);
            newWindow.document.close();
          }
        };

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {hasSelfie ? (
              <IconButton
                onClick={() => openImageWithZoom(selfie.url)}
                style={{ padding: 0 }}
              >
                <img
                  src={selfie.url}
                  alt="CheckIn Selfie Preview"
                  style={{
                    width: 40,
                    height: 40,
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                />
              </IconButton>
            ) : (
              <span>N/A</span>
            )}
          </div>
        );
      },
    },

    {
      field: "CheckOut Selfie",
      headerName: "CheckOut Selfie",
      renderHeader: () => <strong>{"CheckOut Selfie"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const selfie1 = params?.row?.checkOutSelfie;
        const hasSelfie1 = selfie1 && selfie1.url;

        const openImageWithZoom1 = (url) => {
          const newWindow = window.open("", "_blank");
          if (newWindow) {
            newWindow.document.write(`
              <html>
                <head>
                  <title>CheckOut Selfie Preview</title>
                  <style>
                    body {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100vh;
                      margin: 0;
                      background-color: #f0f0f0;
                    }
                    img {
                      max-width: 100%;
                      max-height: 100%;
                      cursor: zoom-in;
                    }
                  </style>
                </head>
                <body>
                  <img src="${url}" alt="Selfie Preview" id="image" />
                  <script>
                    const img = document.getElementById('image');
                    let zoomLevel = 1;
    
                    img.addEventListener('click', () => {
                      zoomLevel = zoomLevel === 1 ? 2 : 1; // Toggle between zoom levels
                      img.style.transform = 'scale(' + zoomLevel + ')';
                      img.style.cursor = zoomLevel === 1 ? 'zoom-in' : 'zoom-out';
                    });
                  </script>
                </body>
              </html>
            `);
            newWindow.document.close();
          }
        };

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {hasSelfie1 ? (
              <IconButton
                onClick={() => openImageWithZoom1(selfie1.url)}
                style={{ padding: 0 }}
              >
                <img
                  src={selfie1.url}
                  alt="CheckOut Selfie Preview"
                  style={{
                    width: 40,
                    height: 40,
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                />
              </IconButton>
            ) : (
              <span style={{ color: "#999" }}>N/A</span>
            )}
          </div>
        );
      },
    },
  ];

  // useEffect(() => {
  //   console.log("userListBasedOnStore", userListBasedOnStore);
  //   console.log("storeListBasedOnRole", storeListBasedOnRole);
  //   console.log("userList", userList);
  //   console.log("filterUser", filterUser);
  //   console.log("storeList", storeList);
  // }, [userListBasedOnStore, userList, filterUser, storeList, storeListBasedOnRole]);

  const payloadRequestFilter = {
    body: {
      pagination: {
        page: page,
        per_page: totalCount?.total_item,
      },
      search: searchQuery,
      start_date: filterObject.startDate,
      end_date: filterObject.endDate,
      storeId: filterObject.storeId,
      userId: filterObject.userId,
      Role: subRole,
      checkInStatus: filterObject.checkInStatus,
      checkOutStatus: filterObject.checkOutStatus,
    },
  };

  const payloadRequestAll = {
    body: {
      pagination: {
        page: page,
        per_page: totalCount?.total_item || 10,
      },
      search: searchQuery,
      start_date: filterObject.startDate,
      end_date: filterObject.endDate,
      storeId: filterObject.storeId,
      userId: filterObject.userId,
      Role: subRole,
      checkInStatus: filterObject.checkInStatus,
      checkOutStatus: filterObject.checkOutStatus,
      isExport: true
    },
  };

  const listConfigForExportExcel = async (payloadRequestFilter) => {
    setLoader(true);
    setIsDataReady(false);
    try {
      const id = localStorage.getItem("companyId");
      payloadRequestFilter.url =
        BASE_URL + `attendanceLog/getAll?company_id=${id}`;
      await dispatch(ListAttendanceSubmissionExportApi(payloadRequestFilter));
      setLoader(false);
    } catch (error) {
      console.error("Error during export:", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (attendanceLogListForExport && attendanceLogListForExport.length > 0) {
      setexportData(attendanceLogListForExport);
      setIsDataReady(true);
      setLoader(false);
    } else if(attendanceLogListForExport?.length === 0) {
      setLoader(false);
    }
  }, [attendanceLogListForExport]);

  useEffect(() => {
    if (isDataReady && shouldExport && exportData.length > 0) {
      generateExcelFile();
      setShouldExport(false);
    }
  }, [exportData, shouldExport, isDataReady]);

  const generateExcelFile = () => {
    const headers = [
      { header: "S.No", key: "serialNumber" },
      { header: "User", key: "user.name" },
      { header: "Store", key: "store.title" },
      { header: "CheckIn Date Time", key: "checkInTime" },
      { header: "CheckIn Status", key: "checkInStatus" },
      { header: "CheckOut Date Time", key: "checkOutTime" },
      { header: "CheckOut Status", key: "checkOutStatus" },
      { header: "Logged In Hours", key: "loggedInHours" },
      { header: "CheckIn Selfie", key: "checkInSelfie.url" },
      { header: "CheckOut Selfie", key: "checkOutSelfie.url" },
    ];
  
    // Flattening function
    const flattenObject = (obj, parent = '', res = {}) => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const prop = obj[key];
          const newKey = parent ? `${parent}.${key}` : key;
          
          if (typeof prop === 'object' && prop !== null) {
            flattenObject(prop, newKey, res);
          } else {
            res[newKey] = prop;
          }
        }
      }
      return res;
    };
  
    // Date format function
    const formatDateTime = (date) => {
      if (!date) return "N/A";
      const formattedDate = new Date(date);
      return formattedDate.toLocaleString(); // Example format: "12/9/2024, 4:20:00 PM"
    };
  
    const formattedData = exportData?.map((item, headIndex) => {
      const flatItem = flattenObject(item);
  
      const row = {};
      headers.forEach(({ header, key }) => {
        let value = flatItem[key];
  
        if (key === "serialNumber") {
          value = headIndex + 1;
        } else if (key === "checkInTime" || key === "checkOutTime") {
          value = formatDateTime(value); // Use the formatDateTime function to format the date-time
        } else if (key === "loggedInHours") {
          // Implement the logic for calculating logged in hours, similar to table column logic
          const checkInTime = new Date(flatItem["checkInTime"]);
          const checkOutTime = new Date(flatItem["checkOutTime"]);
  
          if (!checkInTime || isNaN(checkInTime)) {
            value = "0";
          } else if (checkInTime && checkOutTime && !isNaN(checkOutTime)) {
            const diffInMs = checkOutTime - checkInTime;
  
            if (diffInMs <= 0) {
              value = "0";
            } else {
              const totalSeconds = Math.floor(diffInMs / 1000);
              const hours = Math.floor(totalSeconds / 3600);
              const minutes = Math.floor((totalSeconds % 3600) / 60);
              const seconds = totalSeconds % 60;
              let timeString = "";
              if (hours > 0) {
                timeString += `${hours} hr${hours > 1 ? "s" : ""} `;
              }
              if (minutes > 0) {
                timeString += `${minutes} min `;
              }
              if (seconds > 0 || timeString === "") {
                timeString += `${seconds} sec`;
              }
              value = timeString.trim();
            }
          } else {
            value = "0";
          }
        } else if (
          key === "checkInSelfie.url" ||
          key === "checkOutSelfie.url"
        ) {
          value = value ? value : "N/A";
        } else if (value === undefined || value === null) {
          value = "N/A";
        }
  
        row[header] = value;
      });
  
      return row;
    });
  
    // Define the column widths based on the headers
    const getColumnWidths = () => {
      const columnWidths = [];
      headers.forEach(({ header }) => {
        let maxLength = header.length; // Start with header length
        formattedData.forEach(row => {
          const cellValue = row[header];
          if (cellValue && cellValue.toString().length > maxLength) {
            maxLength = cellValue.toString().length;
          }
        });
        columnWidths.push({ wch: maxLength + 2 }); // Add extra width for spacing
      });
      return columnWidths;
    };
  
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    
    // Set column widths
    worksheet["!cols"] = getColumnWidths();
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Attendance");
    XLSX.writeFile(workbook, "AttendanceData.xlsx");
  };
  
  

  const exportToExcel = async () => {
    setShouldExport(true);
    await listConfigForExportExcel(payloadRequestAll);
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <Card className="header-card">
        <Grid container spacing={1} className="header-card-container">
          <Grid item xs={12} sm={12} md={6} lg={6} className="padding-0">
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", textAlign: "left", marginLeft: "8px", textAlign : { xs: "center", sm: "left", md : "left", lg : "left" } }}
            >
              Attendance Log Submission
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="colomn-padding padding-0"
            sx={{
              display: "flex",
              justifyContent: {xs: "center", sm: "flex-end", md : "flex-end", lg: "flex-end"},
              alignItems: "center",
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                marginRight: "1px",
                marginLeft: "10px",
              }}
            >
              <Button
                className="header-add cancle-hover"
                sx={{
                  height: "40px",
                  borderRadius: "8px",
                  padding: "12px 16px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => setOpenFilterModal(true)}
              >
                <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                Filter
              </Button>

              <Button
                className="header-add cancle-hover"
                sx={{
                  height: "40px",
                  marginTop: "2px",
                  marginRight: "4px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={exportToExcel}
                startIcon={<CloudDownloadIcon />}
              >
                Export Excel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Card>

      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "auto",
          overflowY: "scroll",
          borderRadius: "8px",
          px: 1,
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        <SingleGlobalTable
          data={configData}
          columns={columns}
          totalCount={totalCount?.total_item}
          loading={loader}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
      <Modal
        open={openFilterModal}
        onClose={modalopenFilterhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={false}
      >
        <Box sx={{ ...downloadAllstyle, p: 0 }}>
          <Box
            sx={{
              position: "sticky",
              right: "15px",
              top: "15px",
              cursor: "pointer",
              textAlign: "right",
              padding: "0px 10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box onClick={() => setOpenFilterModal(false)}>
              <CloseIcon />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                pt: 2,
                px: 4,
              }}
            >
              {/* <FormControl fullWidth sx={{ mb: 3 }}>
                <label>Store</label>
                <Autocomplete
                  options={
                    storeListBasedOnRole && storeListBasedOnRole.length
                      ? storeListBasedOnRole
                      : storeList && storeList.length
                      ? storeList
                      : []
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      size="small"
                      placeholder="Select Store"
                      sx={{ borderRadius: "8px" }}
                      onBlur={() => {
                        if (!params.inputProps.value) {
                          // handleClearSelection();
                        }
                      }}
                    />
                  )}
                  value={filterStore}
                  onClose={handleStoreClose}
                  onChange={handleStoreChange}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <Checkbox
                        checked={
                          filterStore
                            ? filterStore
                                .map((fs) => fs._id)
                                .includes(option._id)
                            : false
                        }
                        sx={{ mr: 1 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          const newSelected = filterStore
                            ? [...filterStore]
                            : [];
                          const selectedIndex = newSelected
                            .map((fs) => fs._id)
                            .indexOf(option._id);

                          if (selectedIndex === -1) {
                            newSelected.push(option);
                          } else {
                            newSelected.splice(selectedIndex, 1);
                          }

                          setFilterUser([]);

                          setFilterStore(newSelected);
                        }}
                      />
                      {option.name}
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      if (index === 0) {
                        return (
                          <>
                            <Chip
                              key={option.name}
                              label={option.name}
                              {...getTagProps({ index })}
                              color="primary"
                              sx={{ mr: 1, mb: 1 }}
                            />
                            {value.length > 1 ? (
                              <Chip
                                key={`${value.length - 1} more selected`}
                                label={`${value.length - 1} more selected`}
                                color="primary"
                              />
                            ) : (
                              ""
                            )}
                          </>
                        );
                      }
                    })
                  }
                  clearable
                  onClear={handleStoreClear}
                  multiple
                  disableCloseOnSelect
                  PaperComponent={(paperProps) => {
                    const { children, ...restPaperProps } = paperProps;
                    return (
                      <Paper {...restPaperProps}>
                        <Box
                          onMouseDown={(e) => e.preventDefault()}
                          pl={3}
                          py={0.5}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                filterStore.length > 0 &&
                                filterStore.length ===
                                  (storeListBasedOnRole.length
                                    ? storeListBasedOnRole.length
                                    : storeList.length)
                              ) {
                                handleStoreChange("", []);
                              } else {
                                handleStoreChange(
                                  "",
                                  storeListBasedOnRole.length
                                    ? storeListBasedOnRole
                                    : storeList
                                );
                              }
                            }}
                            label="Select all"
                            control={
                              <Checkbox
                                sx={{ mr: 1 }}
                                id="select-all-checkbox"
                                checked={
                                  filterStore.length > 0 &&
                                  filterStore.length ===
                                    (storeListBasedOnRole.length
                                      ? storeListBasedOnRole.length
                                      : storeList.length)
                                }
                              />
                            }
                          />
                        </Box>
                        <Divider />
                        {children}
                      </Paper>
                    );
                  }}
                />
              </FormControl> */}
              {/* <FormControl fullWidth sx={{ mb: 3 }}>
                <label>User</label>
                <Autocomplete
                  options={
                    storeListBasedLoader
                      ? []
                      : userListBasedOnStore && userListBasedOnStore.length
                      ? userListBasedOnStore
                      : userList && userList.length
                      ? userList
                      : []
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      size="small"
                      placeholder="Select User"
                      sx={{ borderRadius: "8px" }}
                      onBlur={() => {
                        if (!params.inputProps.value) {
                          // handleClearSelection();
                        }
                      }}
                    />
                  )}
                  value={filterUser}
                  loading={storeListBasedLoader}
                  loadingText={"Users Loading..."}
                  // onClose={handleUserClose}
                  onChange={handleUserChange}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <Checkbox
                        checked={
                          filterUser
                            ? filterUser
                                .map((fs) => fs._id)
                                .includes(option._id)
                            : false
                        }
                        sx={{ mr: 1 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          const newSelected = filterUser ? [...filterUser] : [];
                          const selectedIndex = newSelected
                            .map((fs) => fs._id)
                            .indexOf(option._id);

                          if (selectedIndex === -1) {
                            newSelected.push(option);
                          } else {
                            newSelected.splice(selectedIndex, 1);
                          }

                          setFilterUser(newSelected);
                        }}
                      />
                      {option.name}
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      if (index === 0) {
                        return (
                          <>
                            <Chip
                              key={option.name}
                              label={option.name}
                              {...getTagProps({ index })}
                              color="primary"
                              sx={{ mr: 1, mb: 1 }}
                            />
                            {value.length > 1 ? (
                              <Chip
                                key={`${value.length - 1} more selected`}
                                label={`${value.length - 1} more selected`}
                                color="primary"
                              />
                            ) : (
                              ""
                            )}
                          </>
                        );
                      }
                    })
                  }
                  clearable
                  // onClear={handleUserClear}
                  multiple
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  PaperComponent={(paperProps) => {
                    const { children, ...restPaperProps } = paperProps;
                    return (
                      <Paper {...restPaperProps}>
                        <Box
                          onMouseDown={(e) => e.preventDefault()}
                          pl={3}
                          py={0.5}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                filterUser.length > 0 &&
                                filterUser.length ===
                                  (userListBasedOnStore &&
                                  userListBasedOnStore.length
                                    ? userListBasedOnStore.length
                                    : userList.length)
                              ) {
                                handleUserChange("", []);
                              } else {
                                handleUserChange(
                                  "",
                                  userListBasedOnStore &&
                                    userListBasedOnStore.length
                                    ? userListBasedOnStore
                                    : userList
                                );
                              }
                            }}
                            label="Select all"
                            control={
                              <Checkbox
                                sx={{ mr: 1 }}
                                id="select-all-checkbox"
                                checked={
                                  filterUser.length > 0 &&
                                  filterUser.length ===
                                    (userListBasedOnStore &&
                                    userListBasedOnStore.length
                                      ? userListBasedOnStore.length
                                      : userList.length)
                                }
                              />
                            }
                          />
                        </Box>
                        <Divider />
                        {children}
                      </Paper>
                    );
                  }}
                />
              </FormControl> */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Store</label>
                <Autocomplete
                  options={
                    storeList && storeList.length
                      ? storeList
                      : storeList && storeList.length
                      ? storeList
                      : []
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      size="small"
                      placeholder="Select Store"
                      sx={{ borderRadius: "8px" }}
                      onBlur={() => {
                        if (!params.inputProps.value) {
                          // handleClearSelection();
                        }
                      }}
                    />
                  )}
                  value={filterStore}
                  onClose={handleStoreClose}
                  onChange={handleStoreChange}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <Checkbox
                        checked={
                          filterStore
                            ? filterStore
                                .map((fs) => fs._id)
                                .includes(option._id)
                            : false
                        }
                        sx={{ mr: 1 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          const newSelected = filterStore
                            ? [...filterStore]
                            : [];
                          const selectedIndex = newSelected
                            .map((fs) => fs._id)
                            .indexOf(option._id);

                          if (selectedIndex === -1) {
                            newSelected.push(option);
                          } else {
                            newSelected.splice(selectedIndex, 1);
                          }

                          setFilterUser([]);

                          setFilterStore(newSelected);
                        }}
                      />
                      {option.name}
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      if (index === 0) {
                        return (
                          <>
                            <Chip
                              key={option.name}
                              label={option.name}
                              {...getTagProps({ index })}
                              color="primary"
                              sx={{ mr: 1, mb: 1 }}
                            />
                            {value.length > 1 ? (
                              <Chip
                                key={`${value.length - 1} more selected`}
                                label={`${value.length - 1} more selected`}
                                color="primary"
                              />
                            ) : (
                              ""
                            )}
                          </>
                        );
                      }
                    })
                  }
                  clearable
                  onClear={handleStoreClear}
                  multiple
                  disableCloseOnSelect
                  PaperComponent={(paperProps) => {
                    const { children, ...restPaperProps } = paperProps;
                    return (
                      <Paper {...restPaperProps}>
                        <Box
                          onMouseDown={(e) => e.preventDefault()}
                          pl={3}
                          py={0.5}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                filterStore.length > 0 &&
                                filterStore.length ===
                                  (storeList.length
                                    ? storeList.length
                                    : storeList.length)
                              ) {
                                handleStoreChange("", []);
                              } else {
                                handleStoreChange(
                                  "",
                                  storeList.length ? storeList : storeList
                                );
                              }
                            }}
                            label="Select all"
                            control={
                              <Checkbox
                                sx={{ mr: 1 }}
                                id="select-all-checkbox"
                                checked={
                                  filterStore.length > 0 &&
                                  filterStore.length ===
                                    (storeList.length
                                      ? storeList.length
                                      : storeList.length)
                                }
                              />
                            }
                          />
                        </Box>
                        <Divider />
                        {children}
                      </Paper>
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>User</label>
                <Autocomplete
                  options={
                    storeListBasedLoader
                      ? []
                      : userList && userList.length
                      ? userList
                      : userList && userList.length
                      ? userList
                      : []
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      size="small"
                      placeholder="Select User"
                      sx={{ borderRadius: "8px" }}
                      onBlur={() => {
                        if (!params.inputProps.value) {
                          // handleClearSelection();
                        }
                      }}
                    />
                  )}
                  value={filterUser}
                  loading={storeListBasedLoader}
                  loadingText={"Users Loading..."}
                  // onClose={handleUserClose}
                  onChange={handleUserChange}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <Checkbox
                        checked={
                          filterUser
                            ? filterUser
                                .map((fs) => fs._id)
                                .includes(option._id)
                            : false
                        }
                        sx={{ mr: 1 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          const newSelected = filterUser ? [...filterUser] : [];
                          const selectedIndex = newSelected
                            .map((fs) => fs._id)
                            .indexOf(option._id);

                          if (selectedIndex === -1) {
                            newSelected.push(option);
                          } else {
                            newSelected.splice(selectedIndex, 1);
                          }

                          setFilterUser(newSelected);
                        }}
                      />
                      {option.name}
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      if (index === 0) {
                        return (
                          <>
                            <Chip
                              key={option.name}
                              label={option.name}
                              {...getTagProps({ index })}
                              color="primary"
                              sx={{ mr: 1, mb: 1 }}
                            />
                            {value.length > 1 ? (
                              <Chip
                                key={`${value.length - 1} more selected`}
                                label={`${value.length - 1} more selected`}
                                color="primary"
                              />
                            ) : (
                              ""
                            )}
                          </>
                        );
                      }
                    })
                  }
                  clearable
                  // onClear={handleUserClear}
                  multiple
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  PaperComponent={(paperProps) => {
                    const { children, ...restPaperProps } = paperProps;
                    return (
                      <Paper {...restPaperProps}>
                        <Box
                          onMouseDown={(e) => e.preventDefault()}
                          pl={3}
                          py={0.5}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                filterUser.length > 0 &&
                                filterUser.length ===
                                  (userList && userList.length
                                    ? userList.length
                                    : userList.length)
                              ) {
                                handleUserChange("", []);
                              } else {
                                handleUserChange(
                                  "",
                                  userList && userList.length
                                    ? userList
                                    : userList
                                );
                              }
                            }}
                            label="Select all"
                            control={
                              <Checkbox
                                sx={{ mr: 1 }}
                                id="select-all-checkbox"
                                checked={
                                  filterUser.length > 0 &&
                                  filterUser.length ===
                                    (userList && userList.length
                                      ? userList.length
                                      : userList.length)
                                }
                              />
                            }
                          />
                        </Box>
                        <Divider />
                        {children}
                      </Paper>
                    );
                  }}
                />
              </FormControl>

              <Box sx={{ display: "flex", gap: "20px", mb: 2 }}>
  <FormControl fullWidth>
    <label>Check-In Status</label>
    <Autocomplete
      options={checkInStatusOptions}
      getOptionLabel={(option) => option.label}
      value={selectedCheckInStatus}
      onChange={(event, newValue) => setSelectedCheckInStatus(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select Check-In Status"
          size="small"
          sx={{
            borderRadius: "8px",
            "& .MuiOutlinedInput-root": {
              height: 40,
            },
            "& .MuiInputBase-input": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputLabelProps={{
            shrink: false,
          }}
        />
      )}
    />
  </FormControl>

  <FormControl fullWidth>
    <label>Check-Out Status</label>
    <Autocomplete
      options={checkOutStatusOptions}
      getOptionLabel={(option) => option.label}
      value={selectedCheckOutStatus}
      onChange={(event, newValue) => setSelectedCheckOutStatus(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select Check-Out Status"
          size="small"
          sx={{
            borderRadius: "8px",
            "& .MuiOutlinedInput-root": {
              height: 40,
            },
            "& .MuiInputBase-input": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputLabelProps={{
            shrink: false,
          }}
        />
      )}
    />
  </FormControl>
</Box>

              {/* <FormControl fullWidth sx={{ mb: 3 }}>
                <label>Check-In Status</label>
                <Autocomplete
                  options={checkInStatusOptions}
                  getOptionLabel={(option) => option.label}
                  value={selectedCheckInStatus}
                  onChange={(event, newValue) =>
                    setSelectedCheckInStatus(newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Check-In Status"
                      size="small"
                      sx={{
                        borderRadius: "8px",
                        "& .MuiOutlinedInput-root": {
                          height: 40,
                        },
                        "& .MuiInputBase-input": {
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                      InputLabelProps={{
                        shrink: false,
                      }}
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mb: 3 }}>
                <label>Check-Out Status</label>
                <Autocomplete
                  options={checkOutStatusOptions}
                  getOptionLabel={(option) => option.label}
                  value={selectedCheckOutStatus}
                  onChange={(event, newValue) =>
                    setSelectedCheckOutStatus(newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Check-Out Status"
                      size="small"
                      sx={{
                        borderRadius: "8px",
                        "& .MuiOutlinedInput-root": {
                          height: 40,
                        },
                        "& .MuiInputBase-input": {
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                      InputLabelProps={{
                        shrink: false,
                      }}
                    />
                  )}
                />
              </FormControl> */}

              <label>Date</label>
              <Box
                sx={{ mb: 2 }}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    value={startDate ? dayjs(startDate) : null}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    className="date-pick date-pick-submision"
                    maxDate={dayjs()}
                  />
                </LocalizationProvider>

                <Typography className="date-to">to</Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    value={endDate ? dayjs(endDate) : null}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    className="date-pick date-pick-submision"
                    maxDate={dayjs()}
                    minDate={startDate ? dayjs(startDate) : null}
                  />
                </LocalizationProvider>
              </Box>

              {/* <Box
                sx={{ mb: 2 }}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    value={startDate ? moment(startDate).toDate() : null}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    className="date-pick date-pick-submision"
                    maxDate={new Date()}
                  />
                </LocalizationProvider>

                <Typography className="date-to">to</Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    value={endDate ? moment(endDate).toDate() : null}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    className="date-pick date-pick-submision"
                    maxDate={new Date()}
                    minDate={startDate ? moment(startDate).toDate() : null}
                  />
                </LocalizationProvider>
              </Box> */}
              <Stack alignItems="left" sx={{ mb: 2 }}>
                <label>Search</label>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-start-adornment"
                  value={searchQuery}
                  placeholder="Search User, Store, CheckIn Time, Checkout Time, Date Range"
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    searchOnChange(e.target.value);
                    localStorage.setItem("searchedResult", e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              position: "sticky",
              bottom: 0,
              width: "100%",
              gap: "30px",
              px: 3,
              pt: 1,
              pb: 2,
              background: "#fff",
            }}
          >
            <Button
              className="cancle-button cancle-hover"
              style={{
                height: "46px",
                padding: "12px 16px",
                width: "47%",
                background: "#fff",
              }}
              onClick={() => {
                setFilterStore([]);
                setFilterUser([]);
                setSearchQuery("");
                searchOnChange("");
                localStorage.setItem("searchedResult", "");
                const dates = {
                  startDate: moment(new date()).format("YYYY-MM-DD"),
                  endDate: moment(new date()).format("YYYY-MM-DD"),
                };
                setStartDate(dates.startDate);
                setEndDate(dates.endDate);

                setNewDate(dates);
                handleStoreClear();
                setSelectedCheckInStatus(null);
                setSelectedCheckOutStatus(null);
                // localStorage.setItem("storedDateResult", JSON.stringify(dates));
              }}
            >
              Clear
            </Button>
            <Button
              className="header-add cancle-hover"
              style={{
                height: "46px",
                padding: "12px 16px",
                width: "47%",
              }}
              onClick={(e) => {
                handleFilter();
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </>
  );
};

export default AttendanceSubmission;
